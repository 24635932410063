import React, { useContext, useState, useEffect } from 'react'
import { ColorContext } from '../../AppContext'
import "./ToggleSwitch.css"


const ToggleSwitch = ({ onChange, title, value }) => {
    const [checked, setChecked] = useState(value)
    const [color] = useContext(ColorContext)


    useEffect(() => {
        onChange(checked);
    }, [checked])

    return (
        <div className="rule-section">
            <div>
                {title}
            </div>

            <label className="switch">
                <input type="checkbox" checked={checked} onChange={(e) => setChecked(e.currentTarget.checked)} />
                <span className="slider round" style={checked ? {
                    backgroundColor: color
                } : {}}></span>
            </label>
        </div>
    )
}

export default ToggleSwitch
