import firebase from "firebase/app";
import "firebase/storage";
import "firebase/auth";
import { v4 } from "uuid";

const firebaseConfig = {
  apiKey: "AIzaSyDjKlOhfoPeDmY6t_wTCzoq41-zxmErMrY",
  authDomain: "english-letters.firebaseapp.com",
  projectId: "english-letters",
  storageBucket: "english-letters.appspot.com",
  messagingSenderId: "300689706865",
  appId: "1:300689706865:web:a2b11c88db62dd0fa7f0d0",
};

firebase.initializeApp(firebaseConfig);
var secondary = firebase.initializeApp(firebaseConfig, "Secondary");
const storage = firebase.storage();

const auth = firebase.auth();

export { firebase, auth, storage as default };

export async function uploadSingleFileToStorage(path, file) {
  const uploadedFile = await storage
    .ref("/")
    .child(path)
    .put(await file);
  return await uploadedFile.ref.getDownloadURL();
}

export async function uploadFileToStorage(
  path,
  files,
  onProgress,
  onComplete,
  onFinallyCompleted
) {
  let completed = [];
  if (files.length === 0) {
    onFinallyCompleted();
    return;
  }
  for (let index = 0; index < files.length; index++) {
    const file = files[index];
    let uid = v4();
    var blob = new Blob([new Uint8Array(await file.arrayBuffer())], {
      type: file.type,
    });
    let uploadTask = storage.ref(`${path}/${uid}`).put(blob);

    // Register three observers:
    // 1. 'state_changed' observer, called any time the state changes
    // 2. Error observer, called on failure
    // 3. Completion observer, called on successful completion
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        var progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        onProgress(
          Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
        );
      },
      (error) => {
        console.log(error);
      },
      () => {
        // Handle successful uploads on complete
        // For instance, get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then((downloadURL) => {
          onComplete(downloadURL, uid, file);
          completed.push(uid);
          if (completed.length === files.length) onFinallyCompleted();
        });
      }
    );
  }
}

export async function deleteFileToStorage(path) {
  await storage.ref().child(path).delete();
}

export async function deleteDirectoryToStorage(path) {
  var storageRef = storage.ref();
  var ref = storageRef.child(path);
  ref
    .listAll()
    .then(function (result) {
      result.items.forEach(function (file) {
        file.delete();
      });
    })
    .catch(function (error) {
      // Handle any errors
    });
}

export async function signIn(email, password, onError, onComplete) {
  return await auth
    .signInWithEmailAndPassword(email, password)
    .then((user) => onComplete(user))
    .catch((err) => onError(err));
}
export async function signUp(email, password) {
  return await secondary.auth().createUserWithEmailAndPassword(email, password);
}

export async function signOut() {
  await auth.signOut();
}
