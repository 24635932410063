import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ColorContext } from "./AppContext";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import { Neo4jProvider, createDriver } from "use-neo4j";
import { BrowserRouter as Router, Route, Switch, useHistory, useLocation } from "react-router-dom";
import './services/firebaseServices'
import Login from "./components/Login";
import { auth } from "./services/firebaseServices";
import { SearchQueryContext, SortStateContext, HebrewSortStateContext, HebrewSearchQueryContext, ArticleSortStateContext, ArticleSearchQueryContext } from "./FieldStateContext";
import { get, set } from "local-storage";
import axios from "axios";





// const driver = createDriver("bolt", "35.172.129.11", 7687, "neo4j", "ignition-coxswains-area");
const driver = createDriver("neo4j+s", "neo4j.igros.app", 7687, "neo4j", "orlando-fiction-minimum-human-sonic-4047");

ReactDOM.render(
  <Neo4jProvider driver={driver}>
    <Router>
      <AppWrapper />
    </Router>
  </Neo4jProvider>,
  document.getElementById("root")
);

function AppWrapper() {
  const [color, setColor] = useState(get('color') ?? "#FF8900");
  const [query, setQuery] = useState("");
  const [hebrewQuery, setHebrewQuery] = useState("");
  const [articleQuery, setArticleQuery] = useState("");
  const [sortFilter, setSortFilter] = useState([
    { header: "Date", value: "null", hover: false },
    { header: "Number", value: "null", hover: false },
    { header: "Date on Letter", value: "null", hover: false },
    { header: "Name", value: "null", hover: false },
    { header: "Address", value: "null", hover: false },
    { header: "Source", value: "null", hover: false },
    { header: "Source Page", value: "null", hover: false },
    { header: "Editors Notes", value: "null", hover: false },
    { header: "Main Text", value: "null", hover: false },
    { header: "Center Text", value: "null", hover: false },
    { header: "Footnotes", value: "null", hover: false },
    { header: "Summary", value: "null", hover: false },
    { header: "Status", value: "null", hover: false },
  ]);
  const [hebrewSortFilter, setHebrewSortFilter] = useState([
    { header: "volume", value: "null", hover: false },
    { header: "Page Number", value: "null", hover: false },
    { header: "Igroys Bsd", value: "null", hover: false },
    { header: "Igroys City", value: "null", hover: false },
    { header: "Igroys Signature", value: "null", hover: false },
    { header: "Book Page", value: "null", hover: false },
    { header: "Igroys Address", value: "null", hover: false },
    { header: "Igroys Text", value: "null", hover: false },
    { header: "Igroys Comment", value: "null", hover: false },
    { header: "Summary", value: "null", hover: false },
  ]);
  const [articleSortFilter, setArticleSortFilter] = useState([
    { header: "Title", value: "null", hover: false },
    { header: "Subtitle", value: "null", hover: false },
    { header: "Status", value: "null", hover: false },
    { header: "Views", value: "null", hover: false },
    { header: "Date Published", value: "null", hover: false },
  ]);
  const [, setUser] = useState(null);

  let location = useLocation();
  let history = useHistory();
  useEffect(() => {
    // utils();
    
    var unsubscribe = auth.onAuthStateChanged(user => {
      setUser(() => user)
      if (user !== null) {
        if (location.pathname.match("login")) {
          history.replace("/")
        }
      }
      else {
        history.replace("/login")
      }
    });
    return () => unsubscribe();
  }, [])
  return (
    <SortStateContext.Provider value={[sortFilter, setSortFilter]}>
      <HebrewSortStateContext.Provider value={[hebrewSortFilter, setHebrewSortFilter]}>
        <ArticleSortStateContext.Provider value={[articleSortFilter, setArticleSortFilter]}>
          <SearchQueryContext.Provider value={[query, setQuery]}>
            <HebrewSearchQueryContext.Provider value={[hebrewQuery, setHebrewQuery]}>
              <ArticleSearchQueryContext.Provider value={[articleQuery, setArticleQuery]}>
                <ColorContext.Provider value={[color, setColor]}>
                  <Switch>
                    <Route path="/login" children={<Login />} />
                    <Route path="/" children={<App />} />
                  </Switch>
                </ColorContext.Provider>
              </ArticleSearchQueryContext.Provider>
            </HebrewSearchQueryContext.Provider>
          </SearchQueryContext.Provider>
        </ArticleSortStateContext.Provider>
      </HebrewSortStateContext.Provider>
    </SortStateContext.Provider>
  );
}
