// external imports
import React from "react";
import PropTypes from "prop-types";

class FileInput extends React.Component {
  constructor(props) {
    super(props);

    this._handleUpload = this._handleUpload.bind(this);
  }

  _handleUpload(evt) {
    const files = evt.target.files;
    this.props.onChange(files);

    // free up the fileInput again
    this.fileInput.value = null;
  }

  render() {
    return (
      <>
        <div style={this.props.style}>
          <input
            type="file"
            disabled={this.props.disabled}
            multiple={true}
            style={{ display: "none" }}
            accept={this.props.accept}
            onChange={this._handleUpload}
            ref={(ele) => (this.fileInput = ele)}
          />
          {React.cloneElement(this.props.children, {
            onClick: () => !this.props.disabled ?this.fileInput.click(): window.alert("This Account Doesnot Have Upload Access"),
          })}
        </div>
      </>
    );
  }
}

FileInput.propTypes = {
  style: PropTypes.object,
  accept: PropTypes.string,
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

export default FileInput;
