import React, { useContext } from "react";
import { ColorContext } from "../../AppContext";
import { FaPlus } from "react-icons/fa";
import { IoCloseCircleOutline } from "react-icons/io5";

function TagsInput({ onAdd, onRemove, canCreateTags = false, tags = [] }) {
  return (
    <fieldset
      style={{
        height: "auto",
        width: "100%",
        display: "flex",
        flexWrap: "wrap",
      }}
    >
      <legend>Tags</legend>
      <div
        style={{
          width: "100%",
          height: "20px",
        }}
      ></div>
      <_AddTag onClick={canCreateTags ? onAdd : null} canCreateTags={canCreateTags}/>
      {tags.map((tag, i) => (
        <_TagChip tag={tag} onRemove={onRemove} canCreateTags={canCreateTags}/>
      ))}
    </fieldset>
  );
}

export default TagsInput;

function _AddTag({ onClick, canCreateTags=false}) {
  const [color] = useContext(ColorContext);
  return (
    <div
      style={{
        cursor: "pointer",
        width: "40px",
        height: "40px",
        margin: "20px",
        marginTop: "0px",
        marginLeft: "0px",
        borderRadius: "12px",
        background: "transparent",
        border: `1px solid ${canCreateTags? color : "#c1c4c9"}`,
      }}
      onClick={onClick}
    >
      <FaPlus
        color={canCreateTags? color : "#c1c4c9"}
        style={{
          height: "100%",
          width: "100%",
          padding: "13px",
        }}
      />
    </div>
  );
}

function _TagChip({ tag, onRemove,  canCreateTags=false }) {
  return (
    <div
      style={{
        cursor: "pointer",
        height: "40px",
        margin: "20px",
        marginTop: "0px",
        marginLeft: "0px",
        borderRadius: "12px",
        background: "transparent",
        border: "1px solid #525252",
        lineHeight: "40px",
        paddingLeft: "12px",
        paddingRight: "5px",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        fontFamily: "Roboto",
        fontSize: "1.9vh",
        color: "#767676",
      }}
      onClick={() => canCreateTags?onRemove(tag.id):null}
    >
      {tag.name}
      {canCreateTags?(
      <IoCloseCircleOutline
        size={22}
        color={"#848484"}
        style={{
          marginLeft: "5px",
        }}/>
      ):(<div/>)}
      
    </div>
  );
}
