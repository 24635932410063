import { useContext, useState } from "react";
import { Modal, Toast } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { useHistory } from "react-router-dom";
import { useLazyWriteCypher } from "use-neo4j";
import { ColorContext } from "../../../AppContext";
import {
    uploadSingleFileToStorage,
} from "../../../services/firebaseServices";
import FilePicker from "../../dragdrop/FilePicker";
const EditUserDetails = ({ show, user, onHide, onCompleted }) => {
    const [color] = useContext(ColorContext);
    const history = useHistory();
    const [showToast, setShowToast] = useState(false);
    const [name, setName] = useState(user.name);
    const [email, setEmail] = useState(user.email);
    const [file, setFile] = useState(null);
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    const [editUser,] = useLazyWriteCypher(
        `Match (n:EUser {uid: "${user.uid}"}) Set n.name = $name, n.email = $email, n.imageUrl = $imageUrl return n`,
        "neo4j"
    );

    return (
        <>
            <Modal
                size="md"
                show={show}
                onHide={onHide}
                onEscapeKeyDown={onHide}
                onExit={onHide}
                centered
            >
                <Modal.Body style={{ padding: "40px" }}>
                    <FilePicker
                        onChange={(files) => {
                            setFile(files[0]);
                        }}
                        onError={(errMsg) => console.log(errMsg)}
                    >
                        <div className="image-back">
                            <img
                                src={
                                    file
                                        ? URL.createObjectURL(file)
                                        : user.imageUrl ?? 'https://www.w3schools.com/howto/img_avatar.png'
                                }
                                alt="profile"
                                className="image"
                            ></img>
                        </div>
                    </FilePicker>
                    <div className="wrapper">
                        <input
                            className="textfield"
                            value={name}
                            onChange={(e) => setName(e.currentTarget.value)}
                            placeholder="Name"
                        />
                        <input
                            className="textfield"
                            value={email}
                            onChange={(e) => setEmail(e.currentTarget.value)}
                            placeholder="Email"
                            type="email"
                            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
                        />
                        <input
                            className="textfield"
                            onChange={(e) => setPassword(e.currentTarget.value)}
                            placeholder="Reset Password?"
                            type="password"
                            minLength={6}
                        />
                        <div className="buttons">
                            <button
                                style={{
                                    backgroundColor: "rgba(0,0,0,0)",
                                    borderColor: "#95989A",
                                    border: "1px solid " + color,
                                    borderRadius: "3px",
                                    color: color,
                                    height: "48px",
                                    width: isMobile ? "40vw" : "144px",
                                    margin: "0px",
                                }}
                                onClick={onHide}
                            >
                                Cancel
                            </button>
                            <button
                                style={{
                                    borderRadius: "3px",
                                    backgroundColor: color,
                                    color: "#fff",
                                    border: "1px solid rgba(0,0,0,0)",
                                    height: "48px",
                                    width: isMobile ? "40vw" : "144px",
                                    margin: "0px",
                                }}
                                onClick={async () => {
                                    var imageUrl ;
                                    if (file)
                                        imageUrl = await uploadSingleFileToStorage("users/" + user.uid + ".png", file)
                                    var requestOptions = {
                                        method: 'POST',
                                        redirect: 'follow'
                                    };
                                    fetch(`https://us-central1-english-letters.cloudfunctions.net/updateUser?uid=${user.uid}&name=${name}&password=${password}&email=${email}`, requestOptions)
                                        .then(response => response.json())
                                        .then(result => {
                                            console.log(result);
                                            setShowToast(true);
                                            setError(() => result.message);
                                            editUser({
                                                name: name,
                                                email: email,
                                                imageUrl: imageUrl ?? user.imageUrl,
                                            }).then((res) => {
                                                onCompleted(name, email,imageUrl);
                                                onHide();

                                            });
                                        })
                                        .catch(error => {
                                            setShowToast(true);
                                            setError(() => error.message);
                                        });
                                }}
                            >
                                Save
                            </button>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>
            <Toast show={showToast} style={{
                position: 'absolute',
                bottom: 20,
                right: 20,
            }} delay={1000} onClose={() => setShowToast(false)} autohide>
                <Toast.Header>
                    <strong className="mr-auto">English Letters</strong>
                </Toast.Header>
                <Toast.Body>{error}</Toast.Body>
            </Toast>
        </>
    );
};

export default EditUserDetails;

