import React, { useContext } from 'react'
import { ColorContext } from '../../AppContext'
import FilePicker from '../dragdrop/FilePicker'
import './DailyInsightsImageUploader.css'
import { GiCancel } from 'react-icons/gi'

const DailyInsightsImageUploader = ({ onFileLoad, value }) => {
    const [color] = useContext(ColorContext)
    const [file, setFile] = React.useState(null)


    const onDrop = (e) => {
        e.preventDefault();
        onFileLoad(e.dataTransfer.files[0]);
        setFile(e.dataTransfer.files[0]);
    };
    return (
        <FilePicker
            onChange={(files) => {
                onFileLoad(files[0]);
                setFile(files[0]);
            }}
            accept="image/*"
            onError={(errMsg) => console.log(errMsg)}
        >
            {file ? <div className="image-uploader-container newimage" onDrop={onDrop}>
                <img src={URL.createObjectURL(file)} alt="preview" style={{ height: "50px", width: "50px" }} />
                <div className="image-uploader-header" style={{ flex: 1 }}>{file.name}</div>
                <GiCancel color={color} scale={30} style={{ cursor: "pointer" }} onClick={() => {
                    onFileLoad(null);
                    setFile(null)
                }
                } />
            </div> : value ? <div className="image-uploader-container newimage">
                <img src={value} alt="preview" style={{ height: "50px", width: "50px" }} />
                <div className="image-uploader-header" style={{ flex: 1 }}>{''}</div>
            </div> : <div className="image-uploader-container">
                <div className="image-uploader-header">Upload Image here</div>
                <div className="image-uploader-button browse" style={{ background: color }}>Browse File</div>
            </div>}</FilePicker >
    )
}

export default DailyInsightsImageUploader
