import React from "react";

function FolderAdd() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="18.2"
        viewBox="0 0 20 18.2"
      >
        <g
          id="Icon_feather-folder-plus"
          data-name="Icon feather-folder-plus"
          transform="translate(-2 -3.5)"
        >
          <path
            id="Path_80"
            data-name="Path 80"
            d="M21,18.9a1.8,1.8,0,0,1-1.8,1.8H4.8A1.8,1.8,0,0,1,3,18.9V6.3A1.8,1.8,0,0,1,4.8,4.5H9.3l1.8,2.7h8.1A1.8,1.8,0,0,1,21,9Z"
            transform="translate(0)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_81"
            data-name="Path 81"
            d="M18,16.5v6.121"
            transform="translate(-6 -5.281)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_82"
            data-name="Path 82"
            d="M13.5,21h6.121"
            transform="translate(-4.56 -6.6)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </svg>
    </div>
  );
}

export default FolderAdd;
