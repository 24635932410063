import React, { useEffect } from "react";
import { MdOutlineFolder } from "react-icons/md";
import { ImCheckboxUnchecked, ImCheckboxChecked } from "react-icons/im";
import { useContext } from "react";
import { ColorContext } from "../../../AppContext";

function PopupTile({
  icon = <MdOutlineFolder color="#707071" size={17} />,
  title,
  key,
  showTick = true,
  onClick,
  onChange,
  isChecked,
  levelData = 0,
  isFromLetter = false,
}) {
  const [color] = useContext(ColorContext);
  const [checked, setChecked] = React.useState(isChecked);
  const [level, setLevel] = React.useState(levelData ?? 0);

  const handleClick = (e) => {
    if (isFromLetter) {
      onChange(e);
      return;
    }
    e.stopPropagation();
    onChange(!checked);
    setChecked(!checked);
  };
  return (
    <div
      key={key}
      onClick={onClick}
      style={{
        cursor: "default !important",
      }}
      className="popup-tile tag"
    >
      {icon}
      <div className="popup-tile-text">{title}</div>
      {showTick &&
        (isFromLetter ? (
          <LevelComponent
            color={color}
            data={level}
            onChange={(e) => {
              setLevel(e);
              handleClick(e);
            }}
          />
        ) : (
          <div
            style={{
              padding: "5px",
            }}
            onClick={handleClick}
          >
            {checked ? (
              <ImCheckboxChecked color={color} size={13} />
            ) : (
              <ImCheckboxUnchecked
                style={{ textAlign: "right" }}
                color="#B7B7B7"
                size={13}
              />
            )}
          </div>
        ))}
    </div>
  );
}

function LevelComponent({ color, data, onChange }) {
  const [level, setLevel] = React.useState(data ?? 0);

  const handleChange = (e) => {
    setLevel(level !== e ? e : 0);
    onChange(level !== e ? e : 0);
  };
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        height: "100%",
        justifyContent: "end",
      }}
    >
      <div
        style={{
          cursor: "pointer",
          height: "33%",
          width: "5px",
          borderRadius: "20px",
          marginLeft: "5px",
          opacity: level > 0 ? 1 : 0.2,
          backgroundColor: color,
        }}
        onClick={() => handleChange(1)}
      />
      <div
        style={{
          cursor: "pointer",
          marginLeft: "5px",
          height: "33%",
          width: "5px",
          borderRadius: "20px",
          opacity: level > 1 ? 1 : 0.2,
          backgroundColor: color,
        }}
        onClick={() => handleChange(2)}
      />
      <div
        style={{
          cursor: "pointer",
          marginLeft: "5px",
          height: "33%",
          width: "5px",
          borderRadius: "20px",
          opacity: level > 2 ? 1 : 0.2,
          backgroundColor: color,
        }}
        onClick={() => handleChange(3)}
      />
    </div>
  );
}
export default PopupTile;
