import React from "react";

function TagLink() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.171"
        height="19.995"
        viewBox="0 0 20.171 19.995"
      >
        <g
          id="Icon_feather-tag"
          data-name="Icon feather-tag"
          transform="translate(-2 -2)"
        >
          <path
            id="Path_87"
            data-name="Path 87"
            d="M20.454,13.713l-6.732,6.732a1.878,1.878,0,0,1-2.657,0L3,12.389V3h9.389l8.065,8.065A1.878,1.878,0,0,1,20.454,13.713Z"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Icon_awesome-link"
            data-name="Icon awesome-link"
            d="M5.365,3.045A2.5,2.5,0,0,1,5.371,6.57l-.006.006-1.1,1.1A2.5,2.5,0,0,1,.73,4.149l.61-.61a.263.263,0,0,1,.448.174,3.027,3.027,0,0,0,.159.866.264.264,0,0,1-.062.273l-.215.215A1.183,1.183,0,1,0,3.332,6.751l1.1-1.1a1.182,1.182,0,0,0,0-1.673,1.231,1.231,0,0,0-.17-.141.263.263,0,0,1-.114-.207.654.654,0,0,1,.192-.49l.346-.346a.264.264,0,0,1,.338-.028,2.5,2.5,0,0,1,.337.282ZM7.68.73a2.5,2.5,0,0,0-3.531,0l-1.1,1.1-.006.006a2.5,2.5,0,0,0,.343,3.807.264.264,0,0,0,.338-.028l.346-.346a.654.654,0,0,0,.192-.49.263.263,0,0,0-.114-.207,1.23,1.23,0,0,1-.17-.141,1.182,1.182,0,0,1,0-1.673l1.1-1.1A1.183,1.183,0,1,1,6.74,3.343l-.215.215a.264.264,0,0,0-.062.273,3.027,3.027,0,0,1,.159.866.263.263,0,0,0,.448.174l.61-.61A2.5,2.5,0,0,0,7.68.73Z"
            transform="translate(7.795 7.792)"
          />
        </g>
      </svg>
    </div>
  );
}

export default TagLink;
