import React, { useContext } from "react";
import { ColorContext } from "../../../AppContext";

function PopupCancelButton({ onClick }) {
  return (
    <div
      style={{
        borderRadius: "5px",
        cursor: "pointer",
        border: "1px solid #707070",
        color: "#707070",
        padding: "2px 15px",
        textAlign: "center",
        fontSize: "13px",
      }}
      onClick={onClick}
    >
      Cancel
    </div>
  );
}
function PopupSaveButton({ onClick }) {
  const [color] = useContext(ColorContext);
  const [disabled, setDisabled] = React.useState(false);
  const handleClick = () => {
    if (!disabled) {
      setDisabled(true);
      onClick();
    }
  };
  return (
    <div
      style={{
        marginLeft: "10px",
        borderRadius: "5px",
        cursor: disabled ? "not-allowed" : "pointer",
        backgroundColor: disabled ? "grey" : color,
        color: "white",
        padding: "4px 15px",
        textAlign: "center",
        fontSize: "13px",
      }}
      onClick={handleClick}
    >
      Save
    </div>
  );
}

export { PopupCancelButton, PopupSaveButton };
