import "./Login.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { useContext, useState } from "react";
import { Container, Row, Col, Toast } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import { ColorContext } from "../AppContext";
import hexToRGB from "../utils/HexToRGB";
import { signIn } from "../services/firebaseServices";
import { BrowserView, isMobile, MobileView } from "react-device-detect";
import { useLazyReadCypher } from "use-neo4j";


const Login = props => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [show, setShow] = useState(false);
    const [error, setError] = useState('');
    const [getUser] = useLazyReadCypher(`MATCH (n:EUser {email: $email}) return n.enabled`)
    let history = useHistory();
    const [color,] = useContext(ColorContext);


    const emailChanged = (e) => {
        e.preventDefault();
        setEmail(() => e.target.value);
    }
    const passwordChanged = (e) => {
        e.preventDefault();
        setPassword(() => e.target.value);
    }
    const signingIn = async (e) => {
        e.preventDefault();
        
        var result; 
        if(email.trim() !== "dev@osfy.io"){
            result= (await getUser({ email: email })).records[0].get("n.enabled")
        }
        if (result || email.trim() === "dev@osfy.io") {
            await signIn(email, password, (err) => {
                setShow(true);
                setError(() => err.message);
            }, (val) => {
                history.replace("")
            });
        }
        else {
            setShow(true);
            setError(() => "This account is not active.");
        }
    }
    return (
        <>
            <Container className="body" fluid style={{ backgroundColor: hexToRGB(color, 0.05) }}>
                <form method="post" onSubmit={signingIn}>
                    <Row>
                        <Col xs={isMobile ? 12 : 3} style={{ padding: "0px" }}>
                            <Container style={{ backgroundColor: "#fff", height: "100vh", boxShadow: "0 0 6px rgba(0,0,0,0.16)" }}>
                                <div style={{ padding: "20vh 0vh 5vh 0vh", fontSize: "43px", textAlign: "center", fontWeight: "bold", color: `${color}` }}>ENGLISH<br />LETTERS</div>
                                <BrowserView>
                                    <div className="text-center"><input className="login-field" type="email" placeholder="User" onChange={emailChanged} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required /></div>
                                    <div className="text-center"><input className="login-field" type="password" placeholder="Pass" minLength={6} onChange={passwordChanged} onSubmit={(e) => console.log("submit")} required /></div>
                                    <div className="d-flex justify-content-center">
                                        <input
                                            type="submit"
                                            className="signin-button"
                                            style={{
                                                background: `${color}`
                                            }}
                                            value="Sign In"
                                        /></div>
                                </BrowserView>
                                <MobileView>
                                    <div className="text-center"><input className="mobile-login-field" type="email" placeholder="User" onChange={emailChanged} pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" required /></div>
                                    <div className="text-center"><input className="mobile-login-field" type="password" placeholder="Pass" minLength={6} onChange={passwordChanged} onSubmit={(e) => console.log("submit")} required /></div>
                                    <div className="d-flex justify-content-center">
                                        <input
                                            type="submit"
                                            className="mob-signin-button"
                                            style={{
                                                background: `${color}`
                                            }}
                                            value="Sign In"
                                        /></div>
                                </MobileView>
                                <div style={{ color: `${color}`, position: "absolute", bottom: "30px", left: "50%", transform: "translate(-50%, -50%)" }}>
                                    Powered By <span style={{ fontWeight: "bold" }}>Osfy</span>
                                </div>
                            </Container>
                        </Col>
                    </Row>
                </form>
            </Container>
            <Toast show={show} style={{
                position: 'absolute',
                bottom: 20,
                right: 20,
            }} delay={1000} onClose={() => setShow(false)} autohide>
                <Toast.Header>
                    <strong className="mr-auto">English Letters</strong>
                </Toast.Header>
                <Toast.Body>{error}</Toast.Body>
            </Toast>
        </>
    );
}

export default Login;
