import React, { useEffect, useState } from "react";
import SimpleEditor from "../../SimpleEditor";
import "./Card.css";
import MoreOptions from "./dropdowns/MoreOptions";
import DailyInsightsImageUploader from "../../daily_insights/DailyInsightsImageUploader";

function MetaCard({ onDelete, onMoveUp, onMoveDown, value, onChanged, uid }) {
  var imageUrl = value?.imageUrl;
  var title = value?.title;
  var subtitle = value?.subtitle;
  const [file, setFile] = useState();
  useEffect(() => {
    // const interval = setInterval(() => {
    console.log(file);
    // }, 3000);
    // return () => clearInterval(interval);
  }, [file]);

  return (
    <div className="article-card">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            textAlign: "left",
            padding: "24px",
            lineHeight: 1,
          }}
        >
          Metadata
        </div>
        <MoreOptions
          onDelete={onDelete}
          onMoveDown={onMoveDown}
          onMoveUp={onMoveUp}
        />
      </div>
      <div
        style={{
          padding: "0 24px 4px 24px",
        }}
      >
        <SimpleEditor
          onFocus={() => {}}
          onBlur={() => {}}
          disabled={false}
          label={"Line 1"}
          width="100%"
          height="45px"
          index={1}
          uid={uid}
          text={title}
          onChange={(html) => {
            title = html;
            setFile((_file) => {
              onChanged({
                imageUrl: imageUrl,
                file: _file,
                title: title,
                subtitle: subtitle,
              });
              return _file;
            });
          }}
          value={value?.title}
        />
        <SimpleEditor
          onFocus={() => {}}
          onBlur={() => {}}
          disabled={false}
          label={"Line 2"}
          width="100%"
          height="45px"
          index={2}
          uid={uid}
          text={subtitle}
          onChange={(html) => {
            subtitle = html;
            setFile((_file) => {
              onChanged({
                imageUrl: imageUrl,
                file: _file,
                title: title,
                subtitle: subtitle,
              });
              return _file;
            });
          }}
          value={value?.subtitle}
        />
        <DailyInsightsImageUploader
          onFileLoad={(_file) => {
            setFile(_file);
            return onChanged({
              imageUrl: imageUrl,
              file: _file,
              title: title,
              subtitle: subtitle,
            });
          }}
          value={value?.imageUrl}
        />
      </div>
    </div>
  );
}

export default MetaCard;
