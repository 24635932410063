import React, { useState } from "react";
import SimpleEditor from "../../SimpleEditor";
import "./Card.css";
import MoreOptions from "./dropdowns/MoreOptions";
function LinksCard({ onDelete, onMoveUp, onMoveDown, value, onChanged, uid }) {
  var link = value?.link;
  var title = value?.title;
  var subtitle = value?.subtitle;
  return (
    <div className="article-card">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            textAlign: "left",
            padding: "24px",
            lineHeight: 1,
          }}
        >
          Card
        </div>
        <MoreOptions
          onDelete={onDelete}
          onMoveDown={onMoveDown}
          onMoveUp={onMoveUp}
        />
      </div>
      <div
        style={{
          padding: "0 24px 4px 24px",
        }}
      >
        {Array(3)
          .fill()
          .map((_, i) => (
            <SimpleEditor
              onFocus={() => {}}
              onBlur={() => {}}
              disabled={false}
              label={i == 0 ? "Link" : i == 1 ? "Title" : "Subtitle"}
              width="100%"
              height="45px"
              index={i + 1}
              uid={uid}
              text={i == 0 ? link : i == 1 ? title : subtitle}
              onChange={(html) => {
                switch (i) {
                  case 0:
                    link = html;
                    break;
                  case 1:
                    title = html;
                    break;
                  case 2:
                    subtitle = html;
                    break;
                }
                return onChanged({
                  link: link,
                  title: title,
                  subtitle: subtitle,
                });
              }}
              value={
                i == 0 ? value?.link : i == 1 ? value?.title : value?.subtitle
              }
            />
          ))}
      </div>
    </div>
  );
}

export default LinksCard;
