import FilePicker from "./FilePicker";
import "./DragDrop.css";
import { useContext, useEffect, useState } from "react";
import { ColorContext } from "../../AppContext";
import hexToRGB from "../../utils/HexToRGB";
import FilePreview from "./FilePreview";
import { v4 } from "uuid";
const UploadComponent = ({
  accept,
  onFileLoad,
  onClick,
  value,
  onDeleted,
  onUpdated,
  disabled,
  showTitle
}) => {
  const [hover, setHover] = useState(null);
  const [file, setFile] = useState(null);

  useEffect(() => {
    if (!file) return;
    onUpdated(file);
  }, [file]);
  const dragOver = (e) => {
    e.preventDefault();
    setHover(true);
  };

  const [color] = useContext(ColorContext);

  const dragEnter = (e) => {
    e.preventDefault();
  };

  const onDrop = (e) => {
    e.preventDefault();
    onFileLoad(e.dataTransfer.files);
    setFile(e.dataTransfer.files[0]);
    setHover(false);
  };

  const dragLeave = (e) => {
    e.preventDefault();
    setHover(false);
  };
  return (
    <FilePicker
      disabled={disabled}
      onChange={(files) => {
        onFileLoad(files);
        setFile(files[0]);
      }}
      onError={(errMsg) => console.log(errMsg)}
    >
      <div
        style={{
          backgroundColor: `${hover ? hexToRGB(color, 0.2) : "#fff"}`,
          justifyContent: "center",
          cursor: "pointer",
        }}
        onClick={onClick}
        onDragOver={!disabled && dragOver}
        onDragEnter={!disabled && dragEnter}
        onDragLeave={!disabled && dragLeave}
        onDrop={!disabled && onDrop}
      >
        {file ? (
          <div>
            <img
              src={URL.createObjectURL(file)}
              style={{
                maxWidth: "calc(100% - 48px)",
                margin: `${showTitle? '24px':'0'} 24px 0 24px`,
                maxHeight: "100%",
                borderRadius: "10px",
              }}
            />
          </div>
        ) : value ? (
          <div>
            <img
              src={value}
              style={{
                maxWidth: "calc(100% - 48px)",
                margin: `${showTitle? '24px':'0'} 24px 0 24px`,
                maxHeight: "100%",
                borderRadius: "10px",
              }}
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <svg
              id="Group_76"
              data-name="Group 76"
              style={{ margin: "10px 0px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="70.873"
              height="57.435"
              viewBox="0 0 70.873 57.435"
            >
              <path
                id="Path_5"
                data-name="Path 5"
                d="M49.577,45.076c-3.083,0-6.194-.033-9.277,0a1.189,1.189,0,0,1-1.16-1.16c.033-1.913.39-1.16,2.319-1.16,5.491,0,10.744.016,16.235,0,6.035-.016,10.568-5.578,10.436-11.6a9.83,9.83,0,0,0-6.958-9.277,1.541,1.541,0,0,1-1.16-1.16c-.775-6.2-6.589-10.151-12.756-9.277-2.028.3-4.166-.077-5.8,1.16-.495.363-.962.495-1.16,0-2.539-6.2-8.364-9.359-15.075-9.277A15.612,15.612,0,0,0,10.15,17.245a10.709,10.709,0,0,0,0,3.479c.1.544.462,1.011,0,1.16-4.551,1.4-8.1,5.754-8.117,10.436A10.459,10.459,0,0,0,12.47,42.756c5.886.033,11.508.033,17.394,0a1.03,1.03,0,0,1,1.16,1.16c-.049,1.7.555,1.16-1.16,1.16H13.629c-7.848,0-14.443-6.1-13.915-13.915.33-4.913,2.588-8.128,6.958-10.436.478-.247,1.16-.6,1.16-1.16C7.815,10.116,13.64,2.692,22.906,1.01A18.445,18.445,0,0,1,41.46,9.128c.445.693.385.313,1.16,0,8.1-3.232,17.422,1.995,19.713,10.436.132.511.665-.247,1.16,0,5.078,2.424,7.634,8.59,6.958,13.915-.758,5.985-4.682,10.491-10.436,11.6a16.357,16.357,0,0,1-3.479,0C54.226,45.059,51.885,45.076,49.577,45.076Z"
                transform="translate(0.316 -0.734)"
                fill={color}
              />
              <path
                id="Path_6"
                data-name="Path 6"
                d="M156.53,150.014c-2.754,2.77-5.578,5.512-8.117,8.117-.511.511-.632.577-1.16,0-1.3-1.4-1.336-.984,0-2.319,3.314-3.314,5.963-7.089,9.277-10.437.412-.412.665-.495,1.16,0,3.594,3.644,7.969,8,11.6,11.6.4.4.462-.379,0,0-.528.429-1.726,2.319-2.319,2.319s-.681-1.841-1.16-2.319c-2.127-2.11-3.688-3.688-5.8-5.8l-1.16-1.16c-.231.313,0,.879,0,1.16,0,9-.033,18.828,0,27.831,0,.742-.4.033-1.16,0-1.715-.049-1.16.539-1.16-1.16V151.173C156.5,150.943,156.53,150.574,156.53,150.014Z"
                transform="translate(-121.712 -121.907)"
                fill={color}
              />
            </svg>

            <div
              style={{
                fontSize: "18px",
                fontWeight: "900",
                color: "#5A5A5A",
                margin: "10px 0px",
                verticalAlign: "text-bottom",
              }}
            >
              Drag and drop files here
            </div>
            <div
              style={{
                fontSize: "14px",
                marginBottom: "10px",
                fontWeight: "500",
                color: "#666666",
              }}
            >
              or
            </div>
            <button
              style={{
                backgroundColor: `${color}`,
              }}
              className="browse-file"
              onClick={onClick}
            >
              Browse Files
            </button>
          </div>
        )}
      </div>
    </FilePicker>
  );
};

export default UploadComponent;
