import React, { useContext, useEffect, useState } from 'react'
import { Nav, Navbar, NavbarBrand } from 'react-bootstrap';
import { ColorContext } from '../AppContext';
import { FiMenu, FiSearch } from 'react-icons/fi'
import "./NavBar.css"
import { signOut } from '../services/firebaseServices';
import { MdFilterList } from 'react-icons/md';
import { isMobile } from 'react-device-detect';
import { FieldStateContext, HebrewSearchQueryContext, SearchQueryContext } from '../FieldStateContext';
import { Menu } from '@material-ui/core';
import { useLocation } from 'react-router';
import { get, set } from 'local-storage'
import { ChromePicker } from 'react-color'
import { ContextMenu, ContextMenuTrigger } from 'react-contextmenu';


const NavigationBar = ({ collapsed, onCollapsedPress }) => {
    async function onSignOut() {
        await signOut();
    }

    const location = useLocation();
    const [query, setQuery] = useContext(SearchQueryContext)
    const [hebrewQuery, setHebrewQuery] = useContext(HebrewSearchQueryContext)
    const [searchQuery, setSearchQuery] = useState("")

    const tableList = [
        { header: "Date", value: 1 },
        { header: "Number", value: 1 },
        { header: "Date on Letter", value: 2 },
        { header: "Name", value: 2 },
        { header: "Address", value: 2 },
        { header: "Source", value: 2 },
        { header: "Source Page", value: 2 },
        { header: "Editors Notes", value: 2 },
        { header: "Main Text", value: 2 },
        { header: "Center Text", value: 2 },
        { header: "Footnotes", value: 2 },
        { header: "Summary", value: 2 },
        { header: "Status", value: 2 },
    ];

    const hebrewTableList = [
        { header: "volume", value: 1 },
        { header: "Page Number", value: 1.5 },
        { header: "Book Page", value: 2 },
        { header: "Igroys Bsd", value: 2 },
        { header: "Igroys City", value: 2 },
        { header: "Igroys Address", value: 2 },
        { header: "Summary", value: 2 },
        { header: "Igroys Text", value: 2 },
        { header: "Igroys Signature", value: 2 },
        { header: "Igroys Comment", value: 2 },
    ];


    const onCheckChanged = async (value, header) => {
        var hebrewDb = location.pathname.includes("hebrew-letter")
        if (hebrewDb) {
            setHebrewState((e) =>
                e.map((ele) => {
                    if (ele.header === header) {
                        return { header: header, value: value };
                    }
                    return ele;
                })
            );
        }
        else {
            setState((e) =>
                e.map((ele) => {
                    if (ele.header === header) {
                        return { header: header, value: value };
                    }
                    return ele;
                })
            );
        }
    };
    const [state, setState] = useState(get('filter') ?? [
        { header: "Date", value: true },
        { header: "Number", value: true },
        { header: "Date on Letter", value: true },
        { header: "Name", value: true },
        { header: "Address", value: true },
        { header: "Source", value: false },
        { header: "Source Page", value: false },
        { header: "Editors Notes", value: false },
        { header: "Main Text", value: false },
        { header: "Center Text", value: false },
        { header: "Footnotes", value: false },
        { header: "Summary", value: false },
        { header: "Status", value: false },
    ]);


    const [hebrewState, setHebrewState] = useState(get('hebrew-filter') ?? [
        { header: "volume", value: true },
        { header: "Page Number", value: true },
        { header: "Book Page", value: true },
        { header: "Igroys Bsd", value: true },
        { header: "Igroys City", value: true },
        { header: "Igroys Address", value: false },
        { header: "Igroys Text", value: false },
        { header: "Igroys Comment", value: false },
        { header: "Igroys Signature", value: false },
        { header: "Summary", value: false },
    ]);

    useEffect(() => {
        set('filter', state);
    }, [state])
    useEffect(() => {
        set('hebrew-filter', hebrewState);
    }, [hebrewState])

    function getQueryStatments(header, value) {
        switch (header) {
            case "Date":
                return `${location.pathname.split("/").length < 3 ? `toLower(n.year) contains "${value.toLowerCase()}" or` : ""} toLower(n.month) contains "${value.toLowerCase()}" or toLower(n.day) contains "${value.toLowerCase()}" or n.year+"-"+n.month+"-"+n.day contains "${value.toLowerCase()}"`;
            case `Number`:
                return `  toLower(n.number) contains "${value.toLowerCase()}" `;
            case `Date on Letter`:
                return `  toLower(n.dateText) contains "${value.toLowerCase()}" `;
            case `Name`:
                return `  toLower(n.recipientText) contains "${value.toLowerCase()}" `;
            case `Address`:
                return `  toLower(n.addressText) contains "${value.toLowerCase()}" `;
            case `Source`:
                return `  toLower(n.source) contains "${value.toLowerCase()}" `;
            case `Source Page`:
               return `  toLower(n.sourcePage) contains "${value.toLowerCase()}" `;    
            case `Editors Notes`:
                return `  toLower(n.editorsNotes) contains "${value.toLowerCase()}" `;
            case `Main Text`:
                return `  toLower(n.mainText) contains "${value.toLowerCase()}" `;
            case `Center Text`:
                return `  toLower(n.centerText) contains "${value.toLowerCase()}" `;
            case `Footnotes`:
                return `  toLower(n.footnotes) contains "${value.toLowerCase()}" `;
            case `Summary`:
                return `  toLower(n.summary) contains "${value.toLowerCase()}" `;
            case "volume":
                return `toLower(n.vol) contains "${value.toLowerCase()}"`;
            case "Page Number":
                return `toLower(n.Page_number) contains "${value.toLowerCase()}"`;
            case "Book Page":
                return `toLower(n.book_page) contains "${value.toLowerCase()}"`;
            case "Igroys Bsd":
                return `toLower(n.Igroys_bsd) contains "${value.toLowerCase()}"`;
            case "Igroys City":
                return `toLower(n.Igroys_city) contains "${value.toLowerCase()}"`;
            case "Igroys Address":
                return `toLower(n.Igroys_address) contains "${value.toLowerCase()}"`;
            case "Igroys Text":
                return `toLower(n.Igroys_text) contains "${value.toLowerCase()}"`;
            case "Igroys Signature":
                return `toLower(n.Igroys_signature) contains "${value.toLowerCase()}"`;
            case "Igroys Comment":
                return `toLower(n.Igroys_comment) contains "${value.toLowerCase()}"`;
            case `Status`:
                return `toLower(n.status) contains "${value.toLowerCase()}"`;
            default:
                break;
        }
    }

    function onColorChange(value) {
        setColor(val => value)
        set("color", value)
    }

    useEffect(() => {
        if (searchQuery.trim() !== "") {
            let query = "";
            state.filter(e => e.value).forEach((e, index) => {
                query += (getQueryStatments(e.header, searchQuery.trim()) + (index !== (state.filter(e => e.value).length - 1) ? "or " : ""))
            })
            setQuery(query);
        }
        else {
            setQuery("")
        }
    }, [state, searchQuery])


    useEffect(() => {
        if (searchQuery.trim() !== "") {
            let hebrewQuery = "";
            hebrewState.filter(e => e.value).forEach((e, index) => {
                hebrewQuery += (getQueryStatments(e.header, searchQuery.trim()) + (index !== (hebrewState.filter(e => e.value).length - 1) ? "or " : ""))
            })
            setHebrewQuery(hebrewQuery);
        }
        else {
            setHebrewQuery("")
        }
    }, [hebrewState, searchQuery])
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    const [color, setColor] = useContext(ColorContext);
    return (
        <>
            <Navbar fixed="top" style={isMobile ? { width: "100%", height: "8vh", background: `${color}`, overflow: "hidden" } : { width: "100%", height: "8vh", background: `${color}` }} className="text-center shadow-custom">
                <Nav className="mr-auto">
                    <ContextMenuTrigger id='color-picker'>
                        <NavbarBrand onClick={onCollapsedPress}><FiMenu color="white" size="30" /></NavbarBrand>
                    </ContextMenuTrigger>
                    <ContextMenu id='color-picker'>
                        <ChromePicker color={color} onChange={(e) => onColorChange(e.hex)} disableAlpha={true} />
                    </ContextMenu>
                    <NavbarBrand><div style={{ fontSize: "24px", color: "white" }}>English Letters</div></NavbarBrand>
                </Nav>
                {
                    isMobile ? <div /> :
                        <Nav>
                            <div className="d-flex" style={{ backgroundColor: "rgba(255,255,255,0.25)", border: "none", color: "white", height: "5vh", width: "21vw", margin: "0px 29px", borderRadius: "7px" }}>
                                <input type="text" value={searchQuery} onChange={(e) => setSearchQuery(e.target.value)} style={{ border: "none", padding: "0px 0px 0px 10px", backgroundColor: "transparent", width: "80%", color: "white", height: "5vh", outline: "none" }} />
                                <div style={{ width: "20%", display: "flex", alignItems: "center" }}>
                                    <FiSearch color="white" size={25} />
                                    <MdFilterList color="white" size={25} aria-label="more"
                                        aria-controls="long-menu"
                                        aria-haspopup="true"
                                        onClick={handleClick} />
                                    <Menu
                                        id="long-menu"
                                        anchorEl={anchorEl}
                                        keepMounted
                                        open={open}
                                        PaperProps={{
                                            style: {
                                                borderRadius: "4px",
                                                border: "1px solid #B7B7B7",
                                                fontSize: "1.6vh",
                                            },
                                        }}
                                        onClose={handleClose}
                                    >
                                        {Array.from(location.pathname.includes('hebrew-letter') ? hebrewTableList : tableList).map((e) => {
                                            return (
                                                <div
                                                    className="d-flex align-items-center"
                                                    style={{
                                                        padding: "1vh 2vh",
                                                        borderBottom: "1px solid #B7B7B7",
                                                        width: "12vw",
                                                    }}
                                                    key={e.header + "1"}
                                                >
                                                    {" "}
                                                    <input
                                                        type="checkbox"
                                                        onChange={(input) =>
                                                            onCheckChanged(input.target.checked, e.header)
                                                        }
                                                        checked={Array.from(location.pathname.includes('hebrew-letter') ? hebrewState : state).find((s) => s.header === e.header).value}
                                                    />
                                                    <div style={{ paddingLeft: "10px" }}>{e.header}</div>
                                                </div>
                                            );
                                        })}
                                    </Menu>
                                </div>
                            </div>
                            <button style={{ color: `${color}` }} className="signout-button" onClick={onSignOut}>Sign Out</button>
                        </Nav>}
            </Navbar>
        </>
    )
}

export default NavigationBar;
