/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "quill/dist/quill.bubble.css";
import Quill, { RangeStatic } from "quill";
import "./Editor.css";

let Inline = Quill.import("blots/inline");

class HiddenBlot extends Inline { }
HiddenBlot.blotName = "hidden";
HiddenBlot.tagName = "hidden";

Quill.register(HiddenBlot);
const Editor = ({
  width,
  theme,
  height,
  isRTL,
  label,
  text,
  icon,
  onChange,
  index,
  disabled,
  onFocus,
  onBlur,
}) => {
  const [oldText, setOldText] = useState(text);
  const ref = useRef();
  const linkRef = useRef();
  const italicData = ["to", "the", "World"];
  useEffect(() => {
    setOldText(text);
    // setTimeout(() => {
    var quill = new Quill(`#${label.toLowerCase().replaceAll(" ", "-")}`, {
      modules: {
        toolbar: `#tool${label.toLowerCase().replaceAll(" ", "-")}`,
      },
      theme: "bubble",
    });

    var delta = quill.clipboard.convert(oldText);
    quill.setContents(delta, "silent");
    if (isRTL) {
      quill.formatText(0, quill.getLength(), "direction", "rtl");
      quill.formatText(0, quill.getLength(), "align", "right");
    }

    quill.on("editor-change", function (e) {
      if (quill.hasFocus()) {
        if (onFocus) onFocus();
      } else {
        if (onBlur) onBlur();
      }
    });
    if (!disabled) {
      quill.on("selection-change", function (e) {
        if (e === null) {
          quill.disable();
        } else {
          quill.enable();
        }
      });
    } else quill.disable();
    quill.on("text-change", function (e) {
      if (onChange)
        onChange(document.querySelectorAll(".ql-editor")[index]?.innerHTML);
      var text = quill.getText(0, quill.getLength());
      if (
        text.includes("to") &&
        text.split(" ").length > 2 &&
        text.split(" ")[text.split(" ").length - 2] == "to"
      ) {
        var i = text
          .split(" ")
          .slice(0, text.split(" ").length - 2)
          .join(" ").length;
        if (!quill.getFormat(i, 2).italic) {
          quill.formatText(i, 2, "italic", true);
        }
      }
    });
    // setTimeout(() => {

    linkRef.current.addEventListener("click", function () {
      console.log("clicked");
      var range = quill.getSelection();
      console.log(quill.getSelection());
      if (range) {
        var href = prompt('Enter the URL');
        quill.formatText(range, 'link', href);
      }
    });
    ref.current.addEventListener("click", function () {
      var range = quill.getSelection();
      if (range) {
        if (quill.getFormat(range).hidden) {
          quill.formatText(range, "hidden", false);
          quill.removeFormat(range, "color");
          quill.removeFormat(range, "background");
        } else {
          quill.formatText(range, "hidden", true);
          quill.formatText(range, "color", "#FFF", true);
          quill.formatText(range, "background", "#F00", true);
        }
      }
    });

    // }, 500)
    // }, 30000);
  }, []);
  if (theme) {
    return (
      <div style={{ height: `${height}`, width: `${width}` }} className="field">
        <div
          id={`tool${label.toLowerCase().replaceAll(" ", "-")}`}
          style={{ zIndex: "100", padding: "10px" }}
        >
          <button className="ql-bold"></button>
          <button className="ql-italic"></button>
          <button className="ql-align"></button>
          <button className="custom-button" ref={linkRef}>🔗</button>
          <button className="ql-align" value="center"></button>
          <button className="ql-align" value="right"></button>
          <button className="ql-blockquote"></button>
          <button id="custom-button" ref={ref}>
            H
          </button>
          <button className="ql-script" value="super" />
        </div>
        <div id={label.toLowerCase().replaceAll(" ", "-")}></div>
      </div>
    );
  }
  return (
    <fieldset style={{ height: `${height}`, width: `${width}` }}>
      <legend>
        <span style={{}}>
          {icon}
          {label}
        </span>
      </legend>
      <div
        id={`tool${label.toLowerCase().replaceAll(" ", "-")}`}
        style={{ padding: "10px", zIndex: "100" }}
      >
        <button className="ql-bold"></button>
        <button className="ql-italic"></button>
        <button className="ql-align"></button>
        <button className="custom-button" ref={linkRef}>🔗</button>
        <button className="ql-align" value="center"></button>
        <button className="ql-align" value="right"></button>
        <button className="ql-blockquote"></button>
        <button id="custom-button" ref={ref}>
          H
        </button>
        <button className="ql-script" value="super" />
      </div>
      <div id={label.toLowerCase().replaceAll(" ", "-")}></div>
    </fieldset>
  );
};

export default Editor;
