import React, { useContext, useEffect, useState } from 'react'
import { useLazyReadCypher } from 'use-neo4j';
import { ColorContext } from '../../../AppContext';
import HexToRGB from '../../../utils/HexToRGB'

const DailyInsightsPicker = ({ onSave, onHide }) => {
    const [color] = useContext(ColorContext);

    const [dailyInsight, setDailyInsight] = useState(null);
    const [dailyInsights, setDailyInsights] = useState([]);

    const [getDailyInsights] = useLazyReadCypher(
        `MATCH (dailyInsights: DailyInsights) RETURN dailyInsights`,
    );

    useEffect(() => {
        getDailyInsights().then(res => {
            var dailyInsights = res.records.map(record => { return { ...record.get('dailyInsights').properties, id: record.get('dailyInsights').identity.toNumber() } });
            setDailyInsights(dailyInsights);
        })
    }, [])

    const save = () => {
        onSave(dailyInsight);
        onHide()
    }

    return (
        <div className='container-bg'>
            <div className='main-container'>
                <div className='header-section' style={{ backgroundColor: HexToRGB(color, 0.05), color: color }}>Trending Topic - Insights</div>
                <div className='body-section'>
                    <div className='body-section-content'>
                        {dailyInsights.map(val => {
                            return (
                                <img key={val.id} className='body-section-content-item' src={val.imageUrl} alt={'preview'} style={{ border: (dailyInsight?.id ?? 0) === val.id ? `3px solid ${color}` : null, }} onClick={() => { setDailyInsight(val) }} />)
                        })
                        }
                    </div>
                </div>
                <div className='footer-section' style={{ backgroundColor: HexToRGB(color, 0.05) }}>
                    <button className={`footer-button`} style={{ background: "transparent", borderColor: "#95989A", color: "#959595" }} onClick={onHide}>Cancel</button>
                    <button className={`footer-button`} style={{ background: `${color}`, color: "#fff" }} onClick={() => {
                        save()
                    }}>Save</button>
                </div>
            </div>
        </div >
    )
}

export default DailyInsightsPicker