import React, { useContext, useState } from 'react'
import { ColorContext, UserContext } from '../../AppContext'
import "./FilePreview.css";
import { MdDelete, MdPictureAsPdf } from "react-icons/md"
import { IoIosEye, IoIosEyeOff, IoIosPower, IoIosSettings, IoMdImage, IoMdMore } from "react-icons/io"
import { AiOutlineFilePdf } from "react-icons/ai"
import { FiImage } from "react-icons/fi"
import { Menu, MenuItem } from '@material-ui/core';
import { FaRegEdit } from 'react-icons/fa';
import DeleteConfirm from '../dialogs/DeleteConfirm';
import Visiblity from '../dialogs/Visibility';
import { isMobile } from 'react-device-detect';
import Tippy from '@tippyjs/react';
import "tippy.js/dist/tippy.css";

const FilePreview = ({ file, onDeleted, onUpdated }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const [deleteShow, setDeleteShow] = useState(false)
    const [editShow, setEditShow] = useState(false)

    function onDelete() {
        onDeleted(file)
    }

    const handleClose = () => {
        setAnchorEl(null);
    };
    const [color,] = useContext(ColorContext);
    const [userSettings,] = useContext(UserContext);
    return (
        <>
            <div className={`preview-card ${isMobile ? 'mobile' : ''}`}>
                <div className={`preview-image ${isMobile ? 'mobile' : ''}`}>
                    {
                        file.type.includes("pdf") ? <MdPictureAsPdf color="rgba(0,0,0,0.05)" size={77} /> : <IoMdImage color="rgba(0,0,0,0.05)" size={77} />
                    }
                    <IoIosSettings color="rgba(0,0,0,0.29)"
                        className="setting-icon"
                        size={18}
                        aria-label="more"
                        aria-controls="file-menu"
                        aria-haspopup="true"
                        onClick={handleClick} />
                        
                    <Menu
                        id="file-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={open}
                        onClose={handleClose}
                    >

                        <MenuItem style={{ color: "rgba(112, 112, 112, 0.8)" }} onClick={() => { setEditShow(true); handleClose() }}><FaRegEdit />Edit</MenuItem>
                        {userSettings.attachmentsPermission.delete && <MenuItem style={{ color: "rgba(112, 112, 112, 0.8)" }} onClick={() => { setDeleteShow(true); handleClose() }}><MdDelete />Delete</MenuItem>}
                    </Menu>
                    {file.visible=="private" && <IoIosEyeOff color="rgba(0,0,0,0.29)"
                        className="eye-icon"
                        size={18}
                        aria-label="more"
                        aria-controls="file-menu"
                        aria-haspopup="true" />}
                </div>
                <Tippy content={<span>{file.file}</span>}
                    maxWidth="none" >
                    <a href={file.downloadUrl} target="_blank">
                        <div>

                            <div className={`file-card ${isMobile ? 'mobile' : ''}`}>
                                {file.type.includes("pdf") ? <AiOutlineFilePdf color={color} size={20} /> : <FiImage color={color} size={20} />
                                }<div className="file-name" > {file.file}</div>
                            </div>
                        </div>
                    </a>
                </Tippy>
            </div>
            <Visiblity display={editShow} onHide={() => setEditShow(false)} onEdit={onUpdated} file={file} />
            <DeleteConfirm show={deleteShow} onHide={() => setDeleteShow(false)} onClick={onDelete} />
        </>
    )
}

export default FilePreview
