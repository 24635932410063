import React, { useState } from "react";
import "./DailyInsightsCard.css";
import { FiSend, FiEdit3, FiTrash } from "react-icons/fi";
import { useHistory } from "react-router";
import { useLazyWriteCypher } from "use-neo4j";
import DeleteConfirm from "../../dialogs/DeleteConfirm";
import { deleteFileToStorage } from "../../../services/firebaseServices";
import { v4 } from "uuid";
import { Toast } from "react-bootstrap";

const DailyInsightsCard = ({ dailyInsight, onDeleted, onEdited }) => {
  const [hover, setHover] = useState(false);
  const history = useHistory();
  const [error, setError] = useState("");
  const [showToast, setShowToast] = useState(false);
  const [showDelete, setShowDelete] = useState(false);

  const [deleteDailyInsight] = useLazyWriteCypher(
    `Match (n:DailyInsights) where ID(n) = $id detach delete n`
  );

  const onDelete = async () => {
    await deleteDailyInsight({ id: dailyInsight.id });
    await deleteFileToStorage(
      `${dailyInsight.imageUrl
        .split("english-letters.appspot.com/o/")[1]
        .replaceAll("%2F", "/")
        .split("?")[0]
      }`
    );
    if (dailyInsight.thumbnail)
      await deleteFileToStorage(
        `${dailyInsight.thumbnail
          .split("english-letters.appspot.com/o/")[1]
          .replaceAll("%2F", "/")
          .split("?")[0]
        }`
      );
    onDeleted(dailyInsight.id);
  };

  return (
    <>
      <div
        className="card-container"
        onMouseOver={() => {
          setHover(true);
        }}
        onMouseLeave={() => {
          setHover(false);
        }}
      >
        {hover && (
          <div className="card-overlay">
            <div
              className="icon-button"
              onClick={() => {
                var requestOptions = {
                  method: "POST",
                  redirect: "follow",
                };
                const notifiy = window.confirm(
                  "Are you sure you want to notify this article?"
                );
                if (notifiy)
                  fetch(
                    `https://us-central1-english-letters.cloudfunctions.net/notify?topic=quotes&title=${"Daily Insight"}&description=${dailyInsight.title
                    }&imageUrl=${encodeURIComponent(
                      dailyInsight.thumbnail ?? dailyInsight.imageUrl
                    )}&id=${dailyInsight.id}`,
                    requestOptions
                  )
                    .then((response) => response.json())
                    .then((result) => {
                      console.log(result);
                      setShowToast(true);
                      alert(result.message);
                      setError(() => result.message);
                    })
                    .catch((error) => {
                      setShowToast(true);
                      alert(error.message);
                      setError(() => error.message);
                    });
              }}
            >
              <FiSend color="#0077CE" className="icon-style" />
            </div>
            <div
              className="icon-button"
              onClick={() => {
                onEdited();
              }}
            >
              <FiEdit3 className="icon-style" />
            </div>
            <div
              className="icon-button"
              onClick={() => {
                setShowDelete(true);
              }}
            >
              <FiTrash color="#C33939" className="icon-style" />
            </div>
          </div>
        )}
        <img className="card-image" alt="" src={dailyInsight.imageUrl} />
      </div>
      <DeleteConfirm
        show={showDelete}
        onClick={() => onDelete()}
        onHide={() => setShowDelete(false)}
      />
      <Toast
        show={showToast}
        style={{
          position: "absolute",
          zIndex: 100,
          bottom: 20,
          right: 20,
        }}
        delay={1000}
        onClose={() => setShowToast(false)}
        autohide
      >
        <Toast.Header>
          <strong className="mr-auto">English Letters</strong>
        </Toast.Header>
        <Toast.Body>{error}</Toast.Body>
      </Toast>
    </>
  );
};

export default DailyInsightsCard;
