import React, { useContext } from 'react'
import { ColorContext } from '../AppContext'
import "./Loader.css"

const Loader = () => {
    const color = useContext(ColorContext)
    return (
        <div style={{display: "flex", alignItems: "center",justifyContent: "center",height: "100vh"}}><div className="loader" style={{borderTop: `10px solid ${color}`}}></div></div>
    )
}

export default Loader
