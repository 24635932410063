import React, { useContext, useEffect, useState } from "react";
import { FiTag } from "react-icons/fi";
import { MdPeopleOutline, MdArticle, MdLightbulbOutline } from "react-icons/md";
import { IoMdOptions } from "react-icons/io";
import "./SideBar.css";
import { ColorContext, UserContext } from "../../AppContext";
import DatabaseButton from "./DatabaseButton";
import { useLocation, useHistory, Link } from "react-router-dom";
import hexToRGB from "../../utils/HexToRGB";
import { auth } from "../../services/firebaseServices";
import HebrewDBButton from "./HebrewDBButton";
import TrendingButton from "./TrendingButton";
function SideBar({ collapsed, onCollapsed }) {
  const [color] = useContext(ColorContext);
  const [userSettings] = useContext(UserContext);
  const [activeId, setActiveId] = useState(0);
  let location = useLocation();
  let history = useHistory();

  useEffect(() => {
    if (location.pathname.includes("dailyinsights")) {
      setActiveId(9);
    }
    else if (location.pathname.includes("trending")) {
      setActiveId(8);
    }
    else if (location.pathname.includes("tags")) {
      setActiveId(7);
    } else if (location.pathname.includes("users")) {
      setActiveId(5);
    } else if (location.pathname.includes("articles")) {
      setActiveId(6);
    } else if (
      location.pathname.split("/").length >= 3 &&
      location.pathname.split("/")[2] !== ""
    ) {
      setActiveId(1);
    }
    if (location.pathname.includes("hebrew-letter")) {
      setActiveId(2);
    } else if (location.pathname.includes("letter")) {
      setActiveId(1);
    }
  }, []);
  return (
    <div className={`sidebar${collapsed ? " collapsed" : ""}`}>
      <DatabaseButton
        active={activeId === 1}
        selected={location.pathname.split("/")[2]}
        onClick={() => {
          setActiveId(1);
          onCollapsed();
        }}
        onCollapsed={onCollapsed}
      />
      <HebrewDBButton
        active={activeId === 2}
        selected={location.pathname.split("/")[2]}
        onClick={() => {
          setActiveId(2);
          onCollapsed();
        }}
        onCollapsed={onCollapsed}
      />
      {/* <span className="outline-button" onClick={onCollapsed}>
        <MdPeopleOutline
          color="#a0a0a0"
          size={19}
          style={{ marginRight: "10px" }}
        />
        People
      </span> */}
      {/* <span className="outline-button" onClick={onCollapsed}>
        <IoMdOptions
          color="#a0a0a0"
          size={17}
          style={{ marginRight: "10px" }}
        />
        Settings
      </span> */}
      {(auth.currentUser?.email ?? "") == "dev@osfy.io" && (
        <Link
          to="/users"
          className="outline-button"
          style={{
            backgroundColor: activeId == 5 ? hexToRGB(color, 0.19) : "white",
            color: `${activeId == 5 ? color : "#a0a0a0"}`,
            border: `${activeId == 5 ? "none" : "1px solid rgba(112, 112, 112, 0.19)"
              }`,
            marginBottom: `${activeId == 5 ? "15px" : "null"}`,
          }}
          onClick={() => {
            onCollapsed();
            setActiveId(5);
          }}
        >
          <IoMdOptions
            color={activeId == 5 ? color : "#a0a0a0"}
            size={17}
            style={{ marginRight: "10px" }}
          />
          Users
        </Link>
      )}
      {(auth.currentUser?.email ?? "") == "dev@osfy.io" && (
        <div>
          <Link
            to="/articles"
            className="outline-button"
            style={{
              backgroundColor: activeId == 6 ? hexToRGB(color, 0.19) : "white",
              color: `${activeId == 6 ? color : "#a0a0a0"}`,
              border: `${activeId == 6 ? "none" : "1px solid rgba(112, 112, 112, 0.19)"
                }`,
              marginBottom: `${activeId == 6 ? "15px" : "null"}`,
            }}
            onClick={() => {
              onCollapsed();
              setActiveId(6);
            }}
          >
            <MdArticle
              color={activeId == 6 ? color : "#a0a0a0"}
              size={17}
              style={{ marginRight: "10px" }}
            />
            Articles
          </Link>
          <Link
            to="/tags"
            className="outline-button"
            style={{
              backgroundColor: activeId == 7 ? hexToRGB(color, 0.19) : "white",
              color: `${activeId == 7 ? color : "#a0a0a0"}`,
              border: `${activeId == 7 ? "none" : "1px solid rgba(112, 112, 112, 0.19)"
                }`,
              marginBottom: `${activeId == 7 ? "15px" : "null"}`,
            }}
            onClick={() => {
              onCollapsed();
              setActiveId(7);
            }}
          >
            <FiTag color="#a0a0a0" size={17} style={{ marginRight: "10px" }} />
            Tags
          </Link>
          <TrendingButton
            active={activeId === 8}
            selected={location.pathname.split("/")[2]}
            onClick={() => {
              setActiveId(8);
              onCollapsed();
            }}
            onCollapsed={onCollapsed}
          />
          <Link
            to="/dailyinsights"
            className="outline-button"
            style={{
              backgroundColor: activeId == 9 ? hexToRGB(color, 0.19) : "white",
              color: `${activeId == 9 ? color : "#a0a0a0"}`,
              border: `${activeId == 9 ? "none" : "1px solid rgba(112, 112, 112, 0.19)"
                }`,
              marginBottom: `${activeId == 9 ? "15px" : "null"}`,
            }}
            onClick={() => {
              onCollapsed();
              setActiveId(9);
            }}
          >
            <MdLightbulbOutline
              color={activeId == 9 ? color : "#a0a0a0"}
              size={17}
              style={{ marginRight: "10px" }}
            />
            Daily Insights
          </Link>
        </div>
      )}
      <p className="footer" style={{ color: color, marginTop: "auto" }}>
        Powered By <b>Osfy</b>
      </p>
    </div>
  );
}

export default SideBar;
