import React, { useContext, useEffect, useState } from "react";
import { FiEye, FiTrash, FiMail, FiCalendar } from "react-icons/fi";
import { MdPersonOutline } from "react-icons/md";
import { ColorContext, UserContext } from "../AppContext";
import DragDrop from "./dragdrop/DragDrop";
import Editor from "./Editor";
import "./LetterEditor.css";
import months from "../data/MonthData";
import { useLazyReadCypher, useLazyWriteCypher } from "use-neo4j";
import Visiblity from "./dialogs/Visibility";
import { useParams, Prompt, useHistory } from "react-router-dom";
import { HotKeys } from "react-hotkeys";
import LinkTagFolderPopup from "./tags/popups/LinkTagFolderPopup";
import {
  deleteFileToStorage,
  uploadFileToStorage,
} from "../services/firebaseServices";
import { BrowserView, MobileView } from "react-device-detect";
import DeleteConfirm from "./dialogs/DeleteConfirm";
import TagsInput from "./tags/TagsInput";

const LetterEditor = (props) => {
  const history = useHistory();
  const { letterId, year } = useParams();
  const [state, setState] = useState(props.location?.state?.data);
  const [oldState, oldSetState] = useState(props.location?.state?.data);
  const [color] = useContext(ColorContext);
  const [focused, setFocused] = useState(false);
  const [userSettings] = useContext(UserContext);
  const [files, setFiles] = useState([]);
  const [id, setId] = useState(letterId);
  const [show, setShow] = useState(null);
  const [deleteShow, setDelete] = useState(false);
  const [tagsShow, setTagsShow] = useState(false);
  const [tags, setTags] = useState([]);
  const [lettersId, setLettersId] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [updateLetter, { }] = useLazyWriteCypher(
    `MATCH (n:ELetter) where ID(n) = ${id} SET n = $params`
  );
  const [saveLetter, { }] = useLazyWriteCypher(
    `Create (n:ELetter $params) return ID(n)`
  );
  const [saveLetterTags] = useLazyWriteCypher(
    `MATCH (n:ELetter) where ID(n) = ${id} SET n.tagsString = $string return ID(n)`
  );
  const [getFiles, { result, run }] = useLazyReadCypher(
    `MATCH (n:ELetter) where ID(n) = ${id} MATCH (n)-[:file]-(file) ${userSettings.attachmentsPermission.from == "Only public ones"
      ? `MATCH (file {visibile: "public"})`
      : ``
    } RETURN file`
  );
  const [getELetter, { loading, records }] = useLazyReadCypher(
    `MATCH (n:ELetter) where ID(n) = ${id} RETURN n`
  );
  const [getETagsForLetter] = useLazyReadCypher(
    `MATCH (n:ELetter) where ID(n) = ${id} MATCH(n)-[t:tag]->(tag:ETag) RETURN tag, t.level`
  );
  const [linkETagsForLetter] = useLazyWriteCypher(
    `MATCH (n:ELetter) where ID(n) = ${id} MATCH (tag:ETag) WHERE ID(tag) = $tag_id CREATE (n)-[t:tag]->(tag) SET t.level = toInteger($level)  RETURN tag`
  );

  const [updateLinkETagsForLetter] = useLazyWriteCypher(
    `MATCH (n:ELetter) where ID(n) = ${id} MATCH (tag:ETag) WHERE ID(tag) = $tag_id MATCH (n)-[t:tag]->(tag) SET t.level = toInteger($level)  RETURN tag`
  );
  const [unlinkETagsForLetter] = useLazyWriteCypher(
    `MATCH (n:ELetter) where ID(n) = ${id} MATCH (tag:ETag) WHERE ID(tag) = $tag_id MATCH (n)-[rel:tag]->(tag) DELETE rel`
  );

  const [deleteFiles] = useLazyWriteCypher(
    "MATCH (n:EFile) where ID(n) = $params detach delete n"
  );

  const [updateFile] = useLazyWriteCypher(
    "MATCH (n:EFile) where ID(n) = $id SET n.visible = $visible,  n.note = $note, n.file = $file"
  );
  const [deleteLetter] = useLazyWriteCypher(
    `MATCH (n:ELetter) where ID(n) = ${id} detach delete n`
  );

  const [getElettersId, { cypher }] = useLazyReadCypher(
    year === "nondated"
      ? `MATCH (n:ELetter) where n.year in ["","x"] 
       ${userSettings.letterPermission.view &&
        userSettings.letterPermission.from === "From Category"
        ? `MATCH (n) where n.status in [${userSettings.letterPermission.categories.map(
          (e) => `"${e}"`
        )}]`
        : ``
      } RETURN ID(n) as id Order by n.year , n.month, n.day, n.number`
      : year !== undefined
        ? `MATCH (n:ELetter) where n.year = "${year}"
       ${userSettings.letterPermission.view &&
          userSettings.letterPermission.from === "From Category"
          ? `MATCH (n) where n.status in [${userSettings.letterPermission.categories.map(
            (e) => `"${e}"`
          )}]`
          : ``
        } RETURN ID(n) as id Order by n.year , n.month, n.day, n.number`
        : `MATCH (n:ELetter) where n.year <> '' and n.year <> 'x' ${userSettings.letterPermission.view &&
          userSettings.letterPermission.from === "From Years"
          ? `MATCH (n) where n.year in [${userSettings.letterPermission.years.map(
            (e) => `"${e}"`
          )}]`
          : userSettings.letterPermission.view &&
            userSettings.letterPermission.from === "From Category"
            ? `MATCH (n) where n.status in [${userSettings.letterPermission.categories.map(
              (e) => `"${e}"`
            )}]`
            : !userSettings.letterPermission.view
              ? `MATCH (n) where n.year in []`
              : ``
        } RETURN ID(n) as id Order by n.year , n.month, n.day, n.number`
  );

  useEffect(() => {
    if (state != oldState) {
      window.onbeforeunload = () => true;
    } else {
      window.onbeforeunload = undefined;
    }
    if (!props.location.pathname.includes("new")) {
      getElettersId().then((val) => {
        var ids = val.records.map((e) => e.get("id").toNumber());
        setLettersId(() => [...ids]);
        window.addEventListener("keydown", function (e) {
          if (e.repeat) return;
          if (focused) return;
          var index = ids.findIndex((e) => e == parseInt(letterId));
          if (e.keyCode === 37) {
            if (index > 0) {
              var nextLetterId = ids[index - 1];
              if (nextLetterId) {
                if (year) {
                  history.push(`/letter/${year}/${nextLetterId}/edit`);
                  window.removeEventListener("keydown", () => { });
                } else {
                  history.push(`/letter/${nextLetterId}/edit`);
                  window.removeEventListener("keydown", () => { });
                }
              }
            }
          } else if (e.keyCode === 39) {
            if (index < ids.length) {
              var nextLetterId = ids[index + 1];
              if (nextLetterId) {
                if (year) {
                  history.push(`/letter/${year}/${nextLetterId}/edit`);
                  window.removeEventListener("keydown", () => { });
                } else {
                  history.push(`/letter/${nextLetterId}/edit`);
                  window.removeEventListener("keydown", () => { });
                }
              }
            }
          }
        });
      });
    }
  }, []);

  var handlers = {
    SAVE: (event) => {
      event.preventDefault();
      onSave();
    },
  };

  const [createEFile] = useLazyWriteCypher(
    `MATCH (n:ELetter) where ID(n) = $id CREATE (efile:EFile $params), (efile)-[:file]->(n) RETURN ID(efile)`
  );

  function getTags() {
    getETagsForLetter().then((val) => {
      setTags(
        val.records.map((e) => {
          return {
            id: e.get("tag").identity.toNumber(),
            ...e.get("tag").properties,
            level: e.get("t.level").toNumber(),
          };
        })
      );
    });
  }
  const removeTag = (id) => {
    setTags((tags) => {
      saveLetterTags({
        string: tags
          .filter((e) => e.id !== id)
          .map((e) => e.name)
          .join(", "),
      });
      return tags.filter((e) => e.id !== id);
    });
    unlinkETagsForLetter({ tag_id: id });
  };

  function onSaveTags(data = []) {
    let _tags = [...data];
    let tags_to_update = [];
    _tags.forEach((e) => {
      tags.find((tag) => {
        if (tag.id === e.id && tag.level !== e.level && e.level !== 0) {
          console.log("update tag", tag.id, tag.level, e.level);
          tags_to_update = [...tags_to_update, e];
          setTags((_tags) => {
            let data = [..._tags];
            data.splice(
              data.findIndex((e) => e.id === tag.id),
              1,
              { ...tag, level: e.level }
            );
            return data;
          });
        }
      });
    });
    tags_to_update.forEach((tag) => {
      updateLinkETagsForLetter({
        tag_id: tag.id,
        level: tag.level,
      });
    });

    tags.forEach((tag) => {
      if (
        _tags
          .filter((e) => e.level === 0)
          .map((e) => e.id)
          .includes(tag.id)
      ) {
        if (tags_to_update.map((e) => e.id).includes(tag.id)) return;
        console.log("delete tag", tag.id, tag.level);
        removeTag(tag.id);
      }
    });
    _tags
      .filter((e) => e.level !== 0)
      .forEach((tag) => {
        if (!tags.map((e) => e.id).includes(tag.id)) {
          console.log("create tag", tag.id, tag.level);
          linkETagsForLetter({ tag_id: tag.id, level: tag.level }).then(
            (res) => {
              setTags((tags) => {
                var data = [
                  ...tags,
                  {
                    id: res.records[0].get("tag").identity.toNumber(),
                    ...res.records[0].get("tag").properties,
                    level: tag.level,
                  },
                ];
                saveLetterTags({
                  string: data.map((e) => e.name).join(", "),
                });
                return data;
              });
            }
          );
        }
      });
    setTagsShow(false);
  }

  async function onDeleteLetter() {
    if (!userSettings.letterPermission.delete) {
      alert("User doesn't have access to delete letter");
      return;
    } else {
      await deleteLetter();
      for (let index = 0; index < files.length; index++) {
        const file = files[index];
        onDelete(file);
      }
      if (!state) {
        history.push("/letter");
      } else {
        history.push("/letter/" + state?.year);
      }
    }
  }

  async function onDelete(file) {
    deleteFiles({ params: file.id }).then((val) => {
      setFiles((ele) => ele.filter((e) => e.id !== file.id));
    });
    await deleteFileToStorage(
      `${file.downloadUrl
        .split("english-letters.appspot.com/o/")[1]
        .replaceAll("%2F", "/")
        .split("?")[0]
      }`
    );
  }

  async function onUpdated(file) {
    updateFile({ id: file.id, visible: file.visible, file: file.file, note: file.note }).then((val) => {
      console.log(val);
      console.log("here_ 3");

      setFiles((ele) =>
        ele.map((e) => {
          if (e.id === file.id) {
            return file;
          }
          return e;
        })
      );
    });
  }

  async function onFileLoad(selectedFiles) {
    let files = [];
    for (let index = 0; index < selectedFiles.length; index++) {
      files.push(selectedFiles[index]);
    }
    setSelectedFiles((val) => files);
    setShow(true);
  }

  function onFileSave(file) {
    console.log("here_ 4");
    setFiles((val) => [...val, file].sort(function (a, b) {
      if (a.file < b.file) { return -1; }
      if (a.file > b.file) { return 1; }
      return 0;
    }));

  }

  useEffect(() => {
    if (
      year === "nondated" || year === undefined
        ? false
        : userSettings.letterPermission.from === "From Years" &&
        !userSettings.letterPermission.years.includes(parseInt(year))
    ) {
      alert("User doesn't have access to this letter");
      history.goBack();
    } else {
      if (!props.location.pathname.includes("new")) {
        if (!state) {
          getELetter().then((val) => {
            setState(val.records[0]._fields[0].properties);
            oldSetState(val.records[0]._fields[0].properties);
          });
        }
      }
    }
  }, []);

  function onSave() {
    if (!props.location.pathname.includes("new")) {
      if (oldState != state) {
        updateLetter({ params: state }).then(() => console.log(cypher));
        oldSetState(state);
      }
    } else {
      saveLetter({ params: state }).then((val) => {
        let id = val.records[0]._fields[0].toNumber();
        setId(id);
        uploadFileToStorage(
          `${state?.year}/${id}`,
          files.map((e) => e.buffer),
          (val) => console.log(val),
          (downloadUrl, uuid, file) => {
            createEFile({
              params: {
                visible: files.find((e) => e.buffer === file).visible,
                downloadUrl: downloadUrl,
                uuid: uuid,
                file: file.name,
                type: file.type,
              },
              id: id,
            }).then((val) => {
              // files.map(e => {
              //   if (e.buffer === file) {
              //     console.log(e)
              //     // return { ...e, ...{ downloadUrl: downloadUrl, uuid: uuid, id: val.records[0]._fields[0].toNumber(), } }
              //   }
              // })
              console.log("here_ 1");
              setFiles((ele) =>
                ele.map((e) => {
                  if (e.buffer === file) {
                    console.log({
                      ...e,
                      downloadUrl: downloadUrl,
                      uuid: uuid,
                      id: val.records[0]._fields[0].toNumber(),
                    });
                    return {
                      ...e,
                      downloadUrl: downloadUrl,
                      uuid: uuid,
                      id: val.records[0]._fields[0].toNumber(),
                    };
                  } else {
                    return e;
                  }
                })
              );
            });
          },
          () => {
            history.push(`/letter/${id}/edit`);
          }
        );
      });
      oldSetState(state);
    }
  }

  useEffect(() => {
    if (
      !props.location.pathname.includes("new") &&
      userSettings.attachmentsPermission.view
    ) {
      getTags();
      getFiles().then((val) => {
        var result = val.records.map((e) => {
          return {
            ...e._fields[0].properties,
            ...{ id: e._fields[0].identity.toNumber() },
          };
        });
        result.sort(function (a, b) {
          if (a.file < b.file) { return -1; }
          if (a.file > b.file) { return 1; }
          return 0;
        })
        console.log("here_ 2");

        setFiles((val) => [...val, ...result]);
      });
    }
  }, []);

  function onDiscard() {
    setShow(false);
  }

  return (
    <>
      <Prompt
        when={oldState != state}
        message={"Are you sure you want to leave without saving your changes?"}
      />
      {(!props.location.pathname.includes("new") ? !state : false) ? (
        <div />
      ) : (
        <>
          <BrowserView>
            <HotKeys handlers={handlers}>
              <div className="editor">
                <div className="space-between">
                  <div>
                    <input
                      onFocus={() => {
                        setFocused(true);
                      }}
                      onBlur={() => {
                        setFocused(false);
                      }}
                      type="text"
                      disabled={!userSettings.letterPermission.edit}
                      value={state?.day}
                      placeholder="Day"
                      min={1}
                      max={31}
                      className="day"
                      required
                      onChange={(e) => {
                        setState((state) => ({
                          ...state,
                          day: e.target.value,
                        }));
                      }}
                    />
                    <select
                      placeholder="Month"
                      className="month"
                      onFocus={() => {
                        setFocused(true);
                      }}
                      onBlur={() => {
                        setFocused(false);
                      }}
                      disabled={!userSettings.letterPermission.edit}
                      value={
                        Object.values(
                          months.find(
                            (e) => Object.keys(e)[0] === (state?.month ?? "x")
                          )
                        )[0]
                      }
                      onChange={(e) => {
                        setState((state) => ({
                          ...state,
                          month: Object.keys(
                            months.find(
                              (v) => Object.values(v)[0] === e.target.value
                            )
                          )[0],
                        }));
                      }}
                    >
                      {months.map((e, i) => {
                        if (i === 0) {
                          return (
                            <option disabled value="x">
                              Month
                            </option>
                          );
                        } else {
                          return (
                            <option
                              value={Object.values(e)[0]}
                              key={Object.keys(e)[0]}
                            >
                              {Object.values(e)[0]}
                            </option>
                          );
                        }
                      })}
                    </select>
                    <input
                      onFocus={() => {
                        setFocused(true);
                      }}
                      onBlur={() => {
                        setFocused(false);
                      }}
                      type="text"
                      value={state?.year}
                      disabled={!userSettings.letterPermission.edit}
                      placeholder="Year"
                      className="year"
                      required
                      onChange={(e) => {
                        setState((state) => ({
                          ...state,
                          year: e.target.value,
                        }));
                      }}
                    />
                    <input
                      onFocus={() => {
                        setFocused(true);
                      }}
                      onBlur={() => {
                        setFocused(false);
                      }}
                      type="text"
                      placeholder="#"
                      className="hash"
                      disabled={!userSettings.letterPermission.edit}
                      required
                      value={state?.number}
                      onChange={(e) => {
                        setState((state) => ({
                          ...state,
                          number: e.target.value,
                        }));
                      }}
                    />
                  </div>
                  <div>
                    {!props.location.pathname.includes("new") && (
                      <span
                        className="preview"
                        onClick={() => {
                          setDelete(true);
                        }}
                      >
                        Delete <FiTrash />
                      </span>
                    )}
                    <select
                      placeholder="Status"
                      className="month"
                      disabled={!userSettings.letterPermission.edit}
                      value={state?.status}
                      onChange={(e) => {
                        setState((state) => ({
                          ...state,
                          status: e.target.value,
                        }));
                      }}
                    >
                      <option value="Generic">Generic</option>
                      <option value="OCR'ed">OCR'ed</option>
                      <option value="Spell-checked">Spell-checked</option>
                      <option value="Compared">Compared</option>
                      <option value="Live">Live</option>
                      <option value="Hashmata">Hashmata</option>
                      <option value="Tzarich Iyun">Tzarich Iyun</option>
                      <option value="Other">Other</option>
                      <option value="status1">Status 1</option>
                      <option value="status2">Status 2</option>
                      <option value="status3">Status 3</option>
                    </select>
                    <span className="preview">
                      Preview <FiEye style={{ width: "100%" }} />
                    </span>
                  </div>
                </div>
                <div className="space-between">
                  <div style={{ width: "100%" }}>
                    <div className="space-between">
                      <Editor
                        onFocus={() => {
                          setFocused(true);
                        }}
                        onBlur={() => {
                          setFocused(false);
                        }}
                        disabled={!userSettings.letterPermission.edit}
                        label="Source"
                        width="100%"
                        height="16.3vh"
                        index={0}
                        text={state?.source}
                        onChange={(html) => {
                          setState((state) => ({
                            ...state,
                            source: html,
                          }));
                        }}
                      />
                      <div style={{ width: "15vh", height: "14vh" }}></div>
                      <Editor
                        onFocus={() => {
                          setFocused(true);
                        }}
                        onBlur={() => {
                          setFocused(false);
                        }}
                        disabled={!userSettings.letterPermission.edit}
                        label="Source Page"
                        width="100%"
                        height="16.3vh"
                        index={1}
                        text={state?.sourcePage}
                        onChange={(html) => {
                          setState((state) => ({
                            ...state,
                            sourcePage: html,
                          }));
                        }}
                      />
                    </div>
                    <div style={{ marginBottom: "6vh" }}>
                      <Editor
                        onFocus={() => {
                          setFocused(true);
                        }}
                        onBlur={() => {
                          setFocused(false);
                        }}
                        disabled={!userSettings.letterPermission.edit}
                        label="Recipient text"
                        width="100%"
                        height="19.7vh"
                        index={2}
                        icon={<MdPersonOutline size={22} />}
                        text={state?.recipientText}
                        onChange={(html) => {
                          setState((state) => ({
                            ...state,
                            recipientText: html,
                          }));
                        }}
                      />
                    </div>
                    <div className="space-between">
                      <Editor
                        onFocus={() => {
                          setFocused(true);
                        }}
                        onBlur={() => {
                          setFocused(false);
                        }}
                        disabled={!userSettings.letterPermission.edit}
                        label="Address Text"
                        width="100%"
                        height="21vh"
                        index={3}
                        icon={<FiMail />}
                        text={state?.addressText}
                        onChange={(html) => {
                          setState((state) => ({
                            ...state,
                            addressText: html,
                          }));
                        }}
                      />
                      <div style={{ width: "15vh" }}></div>
                      <Editor
                        onFocus={() => {
                          setFocused(true);
                        }}
                        onBlur={() => {
                          setFocused(false);
                        }}
                        disabled={!userSettings.letterPermission.edit}
                        label="Date Text"
                        width="100%"
                        height="21vh"
                        index={4}
                        icon={<FiCalendar />}
                        text={state?.dateText}
                        onChange={(html) => {
                          setState((state) => ({
                            ...state,
                            dateText: html,
                          }));
                        }}
                      />
                    </div>
                  </div>
                  <DragDrop
                    disabled={!userSettings.attachmentsPermission.upload}
                    onFileLoad={onFileLoad}
                    files={files}
                    onDeleted={onDelete}
                    onUpdated={onUpdated}
                  />
                </div>
                <div style={{ marginTop: "10vh" }}>
                  <Editor
                    onFocus={() => {
                      setFocused(true);
                    }}
                    onBlur={() => {
                      setFocused(false);
                    }}
                    disabled={!userSettings.letterPermission.edit}
                    label="Center Text"
                    width="100%"
                    index={5}
                    text={state?.centerText}
                    onChange={(html) => {
                      setState((state) => ({
                        ...state,
                        centerText: html,
                      }));
                    }}
                  />
                </div>
                <div style={{ marginTop: "10vh" }}>
                  <Editor
                    onFocus={() => {
                      setFocused(true);
                    }}
                    onBlur={() => {
                      setFocused(false);
                    }}
                    disabled={!userSettings.letterPermission.edit}
                    label="Main Text"
                    width="100%"
                    height="75.4vh"
                    index={6}
                    text={state?.mainText}
                    onChange={(html) => {
                      setState((state) => ({
                        ...state,
                        mainText: html,
                      }));
                    }}
                  />
                </div>
                <div style={{ marginTop: "10vh", marginBottom: "9vh" }}>
                  <Editor
                    onFocus={() => {
                      setFocused(true);
                    }}
                    onBlur={() => {
                      setFocused(false);
                    }}
                    disabled={!userSettings.letterPermission.edit}
                    label="Footnotes"
                    width="100%"
                    height="50.4vh"
                    index={7}
                    text={state?.footnotes}
                    onChange={(html) => {
                      setState((state) => ({
                        ...state,
                        footnotes: html,
                      }));
                    }}
                  />
                </div>
                <div className="space-between">
                  <Editor
                    onFocus={() => {
                      setFocused(true);
                    }}
                    onBlur={() => {
                      setFocused(false);
                    }}
                    disabled={!userSettings.letterPermission.edit}
                    label="Editors Notes"
                    width="100%"
                    height="29vh"
                    index={8}
                    text={state?.editorsNotes}
                    onChange={(html) => {
                      setState((state) => ({
                        ...state,
                        editorsNotes: html,
                      }));
                    }}
                  />
                  <div style={{ width: "15vh" }}></div>
                  <Editor
                    onFocus={() => {
                      setFocused(true);
                    }}
                    onBlur={() => {
                      setFocused(false);
                    }}
                    disabled={!userSettings.letterPermission.edit}
                    label="Summary"
                    width="100%"
                    height="29vh"
                    index={9}
                    text={state?.summary}
                    onChange={(html) => {
                      setState((state) => ({
                        ...state,
                        summary: html,
                      }));
                    }}
                  />
                </div>
                <TagsInput
                  onRemove={removeTag}
                  onAdd={() => setTagsShow(true)}
                  canCreateTags={userSettings.letterPermission.createTag}
                  tags={tags}
                />
                <button
                  className="submit"
                  disabled={oldState === state}
                  style={{
                    backgroundColor: `${oldState === state ? "grey" : color}`,
                    marginTop: "8vh",
                  }}
                  onClick={() => {
                    console.log(state);
                    onSave();
                  }}
                >
                  Save Changes
                </button>
              </div>
            </HotKeys>
          </BrowserView>
          <MobileView>
            <HotKeys handlers={handlers}>
              <div className="editor-mobile">
                <div className="space-between mobile">
                  <input
                    onFocus={() => {
                      setFocused(true);
                    }}
                    onBlur={() => {
                      setFocused(false);
                    }}
                    disabled={!userSettings.letterPermission.edit}
                    type="text"
                    value={state?.day}
                    placeholder="Day"
                    min={1}
                    max={31}
                    className="day-mobile"
                    required
                    onChange={(e) => {
                      setState((state) => ({
                        ...state,
                        day: e.target.value,
                      }));
                    }}
                  />
                  <select
                    onFocus={() => {
                      setFocused(true);
                    }}
                    onBlur={() => {
                      setFocused(false);
                    }}
                    disabled={!userSettings.letterPermission.edit}
                    placeholder="Month"
                    className="month-mobile"
                    value={
                      Object.values(
                        months.find(
                          (e) => Object.keys(e)[0] === (state?.month ?? "x")
                        )
                      )[0]
                    }
                    onChange={(e) => {
                      setState((state) => ({
                        ...state,
                        month: Object.keys(
                          months.find(
                            (v) => Object.values(v)[0] === e.target.value
                          )
                        )[0],
                      }));
                    }}
                  >
                    {months.map((e, i) => {
                      if (i === 0) {
                        return (
                          <option disabled value="x">
                            Month
                          </option>
                        );
                      } else {
                        return (
                          <option
                            value={Object.values(e)[0]}
                            key={Object.keys(e)[0]}
                          >
                            {Object.values(e)[0]}
                          </option>
                        );
                      }
                    })}
                  </select>
                  <input
                    onFocus={() => {
                      setFocused(true);
                    }}
                    onBlur={() => {
                      setFocused(false);
                    }}
                    type="text"
                    disabled={!userSettings.letterPermission.edit}
                    value={state?.year}
                    placeholder="Year"
                    className="year-mobile"
                    required
                    onChange={(e) => {
                      setState((state) => ({
                        ...state,
                        year: e.target.value,
                      }));
                    }}
                  />
                  <input
                    onFocus={() => {
                      setFocused(true);
                    }}
                    onBlur={() => {
                      setFocused(false);
                    }}
                    type="text"
                    disabled={!userSettings.letterPermission.edit}
                    placeholder="#"
                    className="hash-mobile"
                    required
                    value={state?.number}
                    onChange={(e) => {
                      setState((state) => ({
                        ...state,
                        number: e.target.value,
                      }));
                    }}
                  />
                </div>
                <DragDrop
                  disabled={!userSettings.attachmentsPermission.upload}
                  onFileLoad={onFileLoad}
                  files={files}
                  onDeleted={onDelete}
                  onUpdated={onUpdated}
                />
                <Editor
                  onFocus={() => {
                    setFocused(true);
                  }}
                  onBlur={() => {
                    setFocused(false);
                  }}
                  disabled={!userSettings.letterPermission.edit}
                  label="Source"
                  width="100%"
                  height="16.3vh"
                  index={0}
                  text={state?.source}
                  onChange={(html) => {
                    setState((state) => ({
                      ...state,
                      source: html,
                    }));
                  }}
                />
                <Editor
                  onFocus={() => {
                    setFocused(true);
                  }}
                  onBlur={() => {
                    setFocused(false);
                  }}
                  disabled={!userSettings.letterPermission.edit}
                  label="Source Page"
                  width="100%"
                  height="16.3vh"
                  index={1}
                  text={state?.sourcePage}
                  onChange={(html) => {
                    setState((state) => ({
                      ...state,
                      sourcePage: html,
                    }));
                  }}
                />
                <Editor
                  onFocus={() => {
                    setFocused(true);
                  }}
                  onBlur={() => {
                    setFocused(false);
                  }}
                  disabled={!userSettings.letterPermission.edit}
                  label="Recipient text"
                  width="100%"
                  height="19.7vh"
                  index={2}
                  icon={<MdPersonOutline size={22} />}
                  text={state?.recipientText}
                  onChange={(html) => {
                    setState((state) => ({
                      ...state,
                      recipientText: html,
                    }));
                  }}
                />
                <Editor
                  onFocus={() => {
                    setFocused(true);
                  }}
                  onBlur={() => {
                    setFocused(false);
                  }}
                  disabled={!userSettings.letterPermission.edit}
                  label="Address Text"
                  width="100%"
                  height="21vh"
                  index={3}
                  icon={<FiMail />}
                  text={state?.addressText}
                  onChange={(html) => {
                    setState((state) => ({
                      ...state,
                      addressText: html,
                    }));
                  }}
                />
                <Editor
                  onFocus={() => {
                    setFocused(true);
                  }}
                  onBlur={() => {
                    setFocused(false);
                  }}
                  disabled={!userSettings.letterPermission.edit}
                  label="Date Text"
                  width="100%"
                  height="21vh"
                  index={4}
                  icon={<FiCalendar />}
                  text={state?.dateText}
                  onChange={(html) => {
                    setState((state) => ({
                      ...state,
                      dateText: html,
                    }));
                  }}
                />
                <Editor
                  onFocus={() => {
                    setFocused(true);
                  }}
                  onBlur={() => {
                    setFocused(false);
                  }}
                  disabled={!userSettings.letterPermission.edit}
                  label="Center Text"
                  width="100%"
                  index={5}
                  text={state?.centerText}
                  onChange={(html) => {
                    setState((state) => ({
                      ...state,
                      centerText: html,
                    }));
                  }}
                />
                <Editor
                  onFocus={() => {
                    setFocused(true);
                  }}
                  onBlur={() => {
                    setFocused(false);
                  }}
                  disabled={!userSettings.letterPermission.edit}
                  label="Main Text"
                  width="100%"
                  height="75.4vh"
                  index={6}
                  text={state?.mainText}
                  onChange={(html) => {
                    setState((state) => ({
                      ...state,
                      mainText: html,
                    }));
                  }}
                />
                <Editor
                  onFocus={() => {
                    setFocused(true);
                  }}
                  onBlur={() => {
                    setFocused(false);
                  }}
                  disabled={!userSettings.letterPermission.edit}
                  label="Footnotes"
                  width="100%"
                  height="50.4vh"
                  index={7}
                  text={state?.footnotes}
                  onChange={(html) => {
                    setState((state) => ({
                      ...state,
                      footnotes: html,
                    }));
                  }}
                />
                <Editor
                  onFocus={() => {
                    setFocused(true);
                  }}
                  onBlur={() => {
                    setFocused(false);
                  }}
                  disabled={!userSettings.letterPermission.edit}
                  label="Editors Notes"
                  width="100%"
                  height="29vh"
                  index={8}
                  text={state?.editorsNotes}
                  onChange={(html) => {
                    setState((state) => ({
                      ...state,
                      editorsNotes: html,
                    }));
                  }}
                />
                <Editor
                  onFocus={() => {
                    setFocused(true);
                  }}
                  onBlur={() => {
                    setFocused(false);
                  }}
                  disabled={!userSettings.letterPermission.edit}
                  label="Summary"
                  width="100%"
                  height="29vh"
                  index={9}
                  text={state?.summary}
                  onChange={(html) => {
                    setState((state) => ({
                      ...state,
                      summary: html,
                    }));
                  }}
                />
                <TagsInput
                  onRemove={removeTag}
                  onAdd={() => setTagsShow(true)}
                  tags={tags}
                />
                <button
                  className="submit"
                  disabled={oldState === state}
                  style={{
                    backgroundColor: `${oldState === state ? "grey" : color}`,
                    marginTop: "2vh",
                  }}
                  onClick={() => {
                    console.log(state);
                    onSave();
                  }}
                >
                  Save Changes
                </button>
              </div>
            </HotKeys>
          </MobileView>
        </>
      )}
      {tagsShow && (
        <LinkTagFolderPopup
          onCancel={() => setTagsShow(false)}
          value={tags}
          isTag={true}
          onSave={onSaveTags}
          isFromLetter={true}
        />
      )}

      {show && (
        <Visiblity
          display={show}
          onHide={onDiscard}
          files={selectedFiles}
          onAdd={onFileSave}
          letterId={id}
          path={`${state?.year}/${id}`}
        />
      )}
      {deleteShow && (
        <DeleteConfirm
          show={deleteShow}
          onHide={() => {
            setDelete(false);
          }}
          onClick={() => {
            onDeleteLetter();
          }}
        />
      )}
    </>
  );
};

export default LetterEditor;
