import React, { useContext, useEffect, useState } from "react";
import { FiEye, FiTrash, FiMail } from "react-icons/fi";
import { MdPersonOutline } from "react-icons/md";
import { ColorContext, UserContext } from "../../AppContext";
import Editor from "../Editor";
import "../LetterEditor.css";
import { useLazyReadCypher, useLazyWriteCypher } from "use-neo4j";
import { useParams, Prompt, useHistory } from "react-router-dom";
import { HotKeys } from "react-hotkeys";
import { BrowserView, MobileView } from "react-device-detect";
import DeleteConfirm from "../dialogs/DeleteConfirm";
import { HebrewSearchQueryContext } from "../../FieldStateContext";

const HebrewLetterEditor = (props) => {
    const history = useHistory()
    const { letterId, volume } = useParams();
    const [state, setState] = useState(props.location?.state?.data);
    const [oldState, oldSetState] = useState(props.location?.state?.data);
    const [color] = useContext(ColorContext);
    const [focused, setFocused] = useState(false)
    const [userSettings] = useContext(UserContext);
    const [id, setId] = useState(letterId);
    const [deleteShow, setDelete] = useState(false)
    const [query] = useContext(HebrewSearchQueryContext);
    const [updateLetter, { }] = useLazyWriteCypher(
        `MATCH (n:Letter) where ID(n) = ${id} SET n = $params`
    );
    const [saveLetter, { }] = useLazyWriteCypher(
        `Create (n:Letter $params) return ID(n)`
    );
    const [getLetter, { loading, records }] = useLazyReadCypher(
        `MATCH (n:Letter) where ID(n) = ${id} RETURN n`
    );
    const [deleteLetter] = useLazyWriteCypher(
        `MATCH (n:Letter) where ID(n) = ${id} detach delete n`
    )

    const [getLettersId, { cypher }] = useLazyReadCypher(
        `MATCH (n:Letter) ${volume ? `where n.vol = "${volume}"` : ""} ${query !== "" ? `Match(n) where ${query}` : ""}  RETURN ID(n) as id Order by n.vol`
    )

    useEffect(() => {
        if (state != oldState) {
            window.onbeforeunload = () => true;
        } else {
            window.onbeforeunload = undefined;
        }
        if (!props.location.pathname.includes("new")) {
            getLettersId().then((val) => {
                var ids = val.records.map((e) => e.get("id").toNumber())
                window.addEventListener('keydown', function (e) {
                    if (e.repeat) return
                    if (focused) return
                    var index = ids.findIndex((e) => e == parseInt(letterId))
                    if (e.keyCode === 37) {
                        if (index > 0) {
                            var nextLetterId = ids[index - 1];
                            if (nextLetterId) {
                                if (volume) {
                                    history.push(`/hebrew-letter/${volume}/${nextLetterId}/edit`)
                                    window.removeEventListener('keydown', () => { })
                                }
                                else {
                                    history.push(`/hebrew-letter/${nextLetterId}/edit`)
                                    window.removeEventListener('keydown', () => { })
                                }
                            }
                        }
                    }
                    else if (e.keyCode === 39) {
                        if (index < ids.length) {
                            var nextLetterId = ids[index + 1];
                            if (nextLetterId) {
                                if (volume) {
                                    history.push(`/hebrew-letter/${volume}/${nextLetterId}/edit`)
                                    window.removeEventListener('keydown', () => { })
                                }
                                else {
                                    history.push(`/hebrew-letter/${nextLetterId}/edit`)
                                    window.removeEventListener('keydown', () => { })
                                }
                            }
                        }
                    }
                })
            })
        }
    }, []);


    var handlers = {
        SAVE: (event) => {
            event.preventDefault();
            onSave();
        },
    };

    async function onDeleteLetter() {
        if (!userSettings.letterPermission.delete) {
            alert("User doesn't have access to delete letter")
            return;
        }
        else {
            await deleteLetter()
            if (!state) {
                history.push('/hebrew-letter')
            }
            else {
                history.push('/hebrew-letter/' + state?.volume)
            }
        }
    }




    useEffect(() => {
        if (!props.location.pathname.includes("new")) {
            if (!state) {
                getLetter().then((val) => {
                    setState(val.records[0]._fields[0].properties);
                    oldSetState(val.records[0]._fields[0].properties);
                });
            }
        }
    }, []);


    function onSave() {
        if (!props.location.pathname.includes("new")) {
            if (oldState != state) {
                updateLetter({ params: state }).then(() => console.log(cypher));
                oldSetState(state);
            }
        } else {
            saveLetter({ params: state }).then((val) => {
                let id = val.records[0]._fields[0].toNumber();
                setId(id);
                history.push(volume ? `/hebrew-letter/${volume}/${id}/edit` : `/hebrew-letter/${id}/edit`)
            });
            oldSetState(state);
        }
    }



    return (
        <>
            <Prompt
                when={oldState != state}
                message={"Are you sure you want to leave without saving your changes?"}
            />
            {(!props.location.pathname.includes("new") ? !state : false) ? (
                <div />
            ) : (
                <>
                    <BrowserView>
                        <HotKeys handlers={handlers}>
                            <div className="editor">
                                <div className="space-between">
                                    <div style={{ display: "flex", }}>
                                        <input
                                            onFocus={() => { setFocused(true) }}
                                            onBlur={() => { setFocused(false) }}
                                            type="text"
                                            disabled={!userSettings.letterPermission.edit}
                                            value={state?.vol}
                                            placeholder="Vol"
                                            min={1}
                                            max={31}
                                            className="day"
                                            required
                                            onChange={(e) => {
                                                setState((state) => ({
                                                    ...state,
                                                    vol: e.target.value,
                                                }));
                                            }}
                                        />
                                        <Editor
                                            onFocus={() => { setFocused(true) }}
                                            onBlur={() => { setFocused(false) }}
                                            theme="simple"
                                            text={state?.Page_number}
                                            disabled={!userSettings.letterPermission.edit}
                                            label="Number"
                                            index={0}
                                            isRTL={true}
                                            width="14vh"
                                            height="5.2vh"
                                            onChange={(e) => {
                                                setState((state) => ({
                                                    ...state,
                                                    Page_number: e,
                                                }));
                                            }}
                                        />
                                        <input
                                            onFocus={() => { setFocused(true) }}
                                            onBlur={() => { setFocused(false) }}
                                            type="text"
                                            value={state?.book_page}
                                            disabled={!userSettings.letterPermission.edit}
                                            placeholder="Book Page"
                                            className="year"
                                            required
                                            onChange={(e) => {
                                                setState((state) => ({
                                                    ...state,
                                                    book_page: e.target.value,
                                                }));
                                            }}
                                        />
                                        <input
                                            onFocus={() => { setFocused(true) }}
                                            onBlur={() => { setFocused(false) }}
                                            type="text"
                                            placeholder="Idx"
                                            className="hash"
                                            disabled={!userSettings.letterPermission.edit}
                                            required
                                            value={state?.idx}
                                            onChange={(e) => {
                                                setState((state) => ({
                                                    ...state,
                                                    idx: e.target.value,
                                                }));
                                            }}
                                        />
                                    </div>
                                    <div>
                                        {!props.location.pathname.includes("new") && <span className="preview" onClick={() => { setDelete(true) }}>
                                            Delete <FiTrash />
                                        </span>}
                                        <select
                                            placeholder="Status"
                                            className="month"
                                            disabled={!userSettings.letterPermission.edit}
                                            value={state?.status}
                                            onChange={(e) => {
                                                setState((state) => ({
                                                    ...state,
                                                    status: e.target.value,
                                                }));
                                            }}
                                        >
                                            <option value="Generic">Generic</option>
                                            <option value="OCR'ed">OCR'ed</option>
                                            <option value="Spell-checked">Spell-checked</option>
                                            <option value="Compared">Compared</option>
                                            <option value="Live">Live</option>
                                            <option value="Hashmata">Hashmata</option>
                                            <option value="Tzarich Iyun">Tzarich Iyun</option>
                                            <option value="Other">Other</option>
                                        </select>
                                        <span className="preview">
                                            Preview <FiEye style={{ width: "100%" }} />
                                        </span>
                                    </div>
                                </div>
                                <div className="space-between">
                                    <div style={{ width: "100%" }}>
                                        <div className="space-between">
                                            <Editor
                                                isRTL={true}
                                                onFocus={() => { setFocused(true) }}
                                                onBlur={() => { setFocused(false) }}
                                                disabled={!userSettings.letterPermission.edit}
                                                label="Date Text"
                                                width="100%"
                                                height="9.3vh"
                                                index={1}
                                                text={state?.Igroys_bsd}
                                                onChange={(html) => {
                                                    setState((state) => ({
                                                        ...state,
                                                        Igroys_bsd: html,
                                                    }));
                                                }}
                                            />
                                            <div style={{ width: "15vh", height: "14vh" }}></div>
                                            <Editor
                                                isRTL={true}
                                                onFocus={() => { setFocused(true) }}
                                                onBlur={() => { setFocused(false) }}
                                                disabled={!userSettings.letterPermission.edit}
                                                label="City"
                                                width="100%"
                                                height="9.3vh"
                                                index={2}
                                                text={state?.Igroys_city}
                                                onChange={(html) => {
                                                    setState((state) => ({
                                                        ...state,
                                                        Igroys_city: html,
                                                    }));
                                                }}
                                            />
                                        </div>
                                        <div className="space-between">
                                            <Editor
                                                isRTL={true}
                                                onFocus={() => { setFocused(true) }}
                                                onBlur={() => { setFocused(false) }}
                                                disabled={!userSettings.letterPermission.edit}
                                                label="Igros Address"
                                                width="100%"
                                                height="29vh"
                                                index={3}
                                                icon={<FiMail />}
                                                text={state?.Igroys_address}
                                                onChange={(html) => {
                                                    setState((state) => ({
                                                        ...state,
                                                        Igroys_address: html,
                                                    }));
                                                }}
                                            />
                                            <div style={{ width: "15vh" }}></div>
                                            <Editor
                                                isRTL={true}
                                                onFocus={() => { setFocused(true) }}
                                                onBlur={() => { setFocused(false) }}
                                                disabled={!userSettings.letterPermission.edit}
                                                label="Summary"
                                                width="100%"
                                                height="29vh"
                                                index={4}
                                                text={state?.summary}
                                                onChange={(html) => {
                                                    setState((state) => ({
                                                        ...state,
                                                        summary: html,
                                                    }));
                                                }}
                                            />
                                        </div>
                                    </div>

                                </div>
                                <div style={{ marginTop: "10vh" }}>
                                    <Editor
                                        isRTL={true}
                                        onFocus={() => { setFocused(true) }}
                                        onBlur={() => { setFocused(false) }}
                                        disabled={!userSettings.letterPermission.edit}
                                        label="Main Text"
                                        width="100%"
                                        height="75.4vh"
                                        index={5}
                                        text={state?.Igroys_text}
                                        onChange={(html) => {
                                            setState((state) => ({
                                                ...state,
                                                Igroys_text: html,
                                            }));
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: "10vh", marginBottom: "9vh" }}>
                                    <Editor
                                        isRTL={true}
                                        onFocus={() => { setFocused(true) }}
                                        onBlur={() => { setFocused(false) }}
                                        disabled={!userSettings.letterPermission.edit}
                                        label="Signature"
                                        width="100%"
                                        height="20.4vh"
                                        index={6}
                                        text={state?.Igroys_signature}
                                        onChange={(html) => {
                                            setState((state) => ({
                                                ...state,
                                                Igroys_signature: html,
                                            }));
                                        }}
                                    />
                                </div>
                                <div style={{ marginTop: "10vh", marginBottom: "9vh" }}>
                                    <Editor
                                        isRTL={true}
                                        onFocus={() => { setFocused(true) }}
                                        onBlur={() => { setFocused(false) }}
                                        disabled={!userSettings.letterPermission.edit}
                                        label="Footnotes"
                                        width="100%"
                                        height="50.4vh"
                                        index={7}
                                        text={state?.Igroys_comment}
                                        onChange={(html) => {
                                            setState((state) => ({
                                                ...state,
                                                Igroys_comment: html,
                                            }));
                                        }}
                                    />
                                </div>
                                <button
                                    className="submit"
                                    disabled={oldState === state}
                                    style={{
                                        backgroundColor: `${oldState === state ? "grey" : color}`,
                                        marginTop: "8vh",
                                    }}
                                    onClick={() => {
                                        onSave();
                                    }}
                                >
                                    Save Changes
                                </button>
                            </div>
                        </HotKeys>
                    </BrowserView>
                    <MobileView>
                        <HotKeys handlers={handlers}>
                            <div className="editor-mobile">
                                <div className="space-between mobile">
                                    <input
                                        onFocus={() => { setFocused(true) }}
                                        onBlur={() => { setFocused(false) }}
                                        disabled={!userSettings.letterPermission.edit}
                                        type="text"
                                        value={state?.vol}
                                        placeholder="Vol"
                                        min={1}
                                        max={31}
                                        className="day-mobile"
                                        required
                                        onChange={(e) => {
                                            setState((state) => ({
                                                ...state,
                                                vol: e.target.value,
                                            }));
                                        }}
                                    />
                                    <Editor
                                        onFocus={() => { setFocused(true) }}
                                        onBlur={() => { setFocused(false) }}
                                        theme="simple"
                                        text={state?.Page_number}
                                        disabled={!userSettings.letterPermission.edit}
                                        label="Number"
                                        index={0}
                                        isRTL={true}
                                        width="14vh"
                                        height="5.2vh"
                                        onChange={(e) => {
                                            setState((state) => ({
                                                ...state,
                                                Page_number: e,
                                            }));
                                        }}
                                    />
                                    <input
                                        onFocus={() => { setFocused(true) }}
                                        onBlur={() => { setFocused(false) }}
                                        type="text"
                                        disabled={!userSettings.letterPermission.edit}
                                        value={state?.book_page}
                                        placeholder="Book Page"
                                        className="year-mobile"
                                        required
                                        onChange={(e) => {
                                            setState((state) => ({
                                                ...state,
                                                book_page: e.target.value,
                                            }));
                                        }}
                                    />
                                    <input
                                        onFocus={() => { setFocused(true) }}
                                        onBlur={() => { setFocused(false) }}
                                        type="text"
                                        disabled={!userSettings.letterPermission.edit}
                                        placeholder="Idx"
                                        className="hash-mobile"
                                        required
                                        value={state?.idx}
                                        onChange={(e) => {
                                            setState((state) => ({
                                                ...state,
                                                idx: e.target.value,
                                            }));
                                        }}
                                    />
                                </div>
                                <Editor
                                    isRTL={true}
                                    onFocus={() => { setFocused(true) }}
                                    onBlur={() => { setFocused(false) }}
                                    disabled={!userSettings.letterPermission.edit}
                                    label="Date Text"
                                    width="100%"
                                    height="9.3vh"
                                    index={1}
                                    text={state?.Igroys_bsd}
                                    onChange={(html) => {
                                        setState((state) => ({
                                            ...state,
                                            Igroys_bsd: html,
                                        }));
                                    }}
                                />
                                <Editor
                                    isRTL={true}
                                    onFocus={() => { setFocused(true) }}
                                    onBlur={() => { setFocused(false) }}
                                    disabled={!userSettings.letterPermission.edit}
                                    label="City"
                                    width="100%"
                                    height="9.3vh"
                                    index={2}
                                    text={state?.Igroys_city}
                                    onChange={(html) => {
                                        setState((state) => ({
                                            ...state,
                                            Igroys_city: html,
                                        }));
                                    }}
                                />
                                <Editor
                                    isRTL={true}
                                    onFocus={() => { setFocused(true) }}
                                    onBlur={() => { setFocused(false) }}
                                    disabled={!userSettings.letterPermission.edit}
                                    label="Igros Address"
                                    width="100%"
                                    height="19.3vh"
                                    index={3}
                                    text={state?.Igroys_address}
                                    onChange={(html) => {
                                        setState((state) => ({
                                            ...state,
                                            Igroys_address: html,
                                        }));
                                    }}
                                />
                                <Editor
                                    isRTL={true}
                                    onFocus={() => { setFocused(true) }}
                                    onBlur={() => { setFocused(false) }}
                                    disabled={!userSettings.letterPermission.edit}
                                    label="Summary"
                                    width="100%"
                                    height="29vh"
                                    index={4}
                                    text={state?.summary}
                                    onChange={(html) => {
                                        setState((state) => ({
                                            ...state,
                                            summary: html,
                                        }));
                                    }}
                                />
                                <Editor
                                    isRTL={true}
                                    onFocus={() => { setFocused(true) }}
                                    onBlur={() => { setFocused(false) }}
                                    disabled={!userSettings.letterPermission.edit}
                                    label="Main Text"
                                    width="100%"
                                    height="75.4vh"
                                    index={5}
                                    text={state?.Igroys_text}
                                    onChange={(html) => {
                                        setState((state) => ({
                                            ...state,
                                            Igroys_text: html,
                                        }));
                                    }}
                                />
                                <Editor
                                    isRTL={true}
                                    onFocus={() => { setFocused(true) }}
                                    onBlur={() => { setFocused(false) }}
                                    disabled={!userSettings.letterPermission.edit}
                                    label="Signature"
                                    width="100%"
                                    height="20.4vh"
                                    index={6}
                                    text={state?.Igroys_signature}
                                    onChange={(html) => {
                                        setState((state) => ({
                                            ...state,
                                            Igroys_signature: html,
                                        }));
                                    }}
                                />
                                <Editor
                                    isRTL={true}
                                    onFocus={() => { setFocused(true) }}
                                    onBlur={() => { setFocused(false) }}
                                    disabled={!userSettings.letterPermission.edit}
                                    label="Footnotes"
                                    width="100%"
                                    height="50.4vh"
                                    index={7}
                                    text={state?.Igroys_comment}
                                    onChange={(html) => {
                                        setState((state) => ({
                                            ...state,
                                            Igroys_comment: html,
                                        }));
                                    }}
                                />
                                <button
                                    className="submit"
                                    disabled={oldState === state}
                                    style={{
                                        backgroundColor: `${oldState === state ? "grey" : color}`,
                                        marginTop: "2vh",
                                    }}
                                    onClick={() => {
                                        onSave();
                                    }}
                                >
                                    Save Changes
                                </button>
                            </div>
                        </HotKeys>
                    </MobileView>
                </>
            )}
            {deleteShow && (
                <DeleteConfirm
                    show={deleteShow}
                    onHide={() => { setDelete(false) }}
                    onClick={() => { onDeleteLetter() }}
                />
            )}
        </>
    );
};

export default HebrewLetterEditor;
