import React, { useContext, useState } from 'react'
import { ColorContext } from '../../AppContext'
import { IoIosArrowDown } from 'react-icons/io'
import "./CustomDropDown.css"

const CustomDropDown = ({ items, value, onClick }) => {
    const [item, setItem] = useState(value);

    function onChange(val) {
        setItem(val)
        onClick(val)
    }

    const [color] = useContext(ColorContext)
    return (
        <div class="dropdown">
            <button class="dropbtn" style={{ background: color }}><div style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
            }}>{item}</div><IoIosArrowDown color="rgba(255,255,255,0.5)" style={{padding:"0px"}}/></button>
            <div class="dropdown-content" style={{ background: color }}>
                {items.map((e) => {
                    if (e !== item)
                        return <div style={{ fontSize: "1.7vh", fontWeight: "300" }} onClick={() => onChange(e)}>{e}</div>
                })}
            </div>
        </div>
    )
}

export default CustomDropDown
