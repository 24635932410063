// external imports
import React from 'react'
import PropTypes from 'prop-types'
// local imports
import FileInput from './FileInput'

class FilePicker extends React.Component {
  constructor(props) {
    super(props)

    this._validate = this._validate.bind(this)
  }

  _validate(file) {
    const { onError, onChange, } = this.props

    // make sure a file was provided in the first place
    if (!file) {
      onError('Failed to upload a file.')
      return
    }

    // return native file object
    onChange(file)
  }

  render() {
    return (
      <FileInput onChange={this._validate} style={this.props.style} accept={this.props.accept} disabled={this.props.disabled}>
        {this.props.children}
      </FileInput>
    )
  }
}

FilePicker.propTypes = {
  children: PropTypes.node.isRequired,
  onChange: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  // file extension
  accept: PropTypes.string,
  disabled: PropTypes.bool,
  // validate file contents
  validateContent: PropTypes.func,
  style: PropTypes.object
}

FilePicker.defaultProps = {
  maxSize: 2
}

export default FilePicker
