import React, { useContext, useEffect } from "react";
import { FiTag } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { useLazyReadCypher } from "use-neo4j";
import { ColorContext } from "../../../AppContext";
import { hexToRGBAfterOpactiy } from "../../../utils/HexToRGB";
import { PopupCancelButton, PopupSaveButton } from "./PopupButtons";
import PopupTile from "./PopupTile";

function LinkTagFolderPopup({
  isTag = false,
  onCancel,
  onSave,
  value,
  isTrending = false,
  isFromLetter = false,
}) {
  const [color] = useContext(ColorContext);
  const [navigationStack, setNavigationStack] = React.useState([]);
  const [selected, setSelected] = React.useState(value ?? []);
  const [isSearchActive, setIsSearchActive] = React.useState(false);
  const [currentNavigationIndex, setCurrentNavigationIndex] = React.useState(0);

  const [getFolderChildren, {}] = useLazyReadCypher(
    navigationStack.length === 0
      ? 'MATCH (children:ETagsFolder {type: "root"}) RETURN children'
      : `MATCH (parent:ETagsFolder) WHERE ID(parent) = $parent_id MATCH (parent)-[child:child]->(children${
          (isTag || isTrending) ? "" : ":ETagsFolder"
        }) WHERE "ETagsFolder" in labels(children) or "ETag" in labels(children) RETURN children ORDER BY child.order`
  );
  const [_getSearchResults, {}] = useLazyReadCypher(
    `MATCH (item) WHERE toLower(item.name) CONTAINS $query OR $query IN item.keywords  MATCH(item) where "ETagsFolder" in labels(item) or "ETag" in labels(item) RETURN item LIMIT 20`
  );

  function getSearchResults(searchQuery) {
    if (searchQuery.length > 0) {
      _getSearchResults({ query: searchQuery.toLowerCase() }).then((res) => {
        if (!isSearchActive) {
          setCurrentNavigationIndex((index) => index + 1);
          setIsSearchActive(true);
        }
        let _categories = [],
          _tags = [];
        res.records.map((record) => {
          const data = {
            id: record.get("item").identity.toNumber(),
            ...record.get("item").properties,
          };
          if (data.type == "trending") return;
          if (record.get("item").labels[0] === "ETagsFolder") {
            _categories = [..._categories, data];
          } else {
            _tags = [..._tags, data];
          }
        });
        setNavigationStack((stack) => {
          const _stack = [...stack];
          _stack[
            isSearchActive ? currentNavigationIndex : currentNavigationIndex + 1
          ] = {
            categories: _categories,
            tags: _tags,
          };
          return _stack;
        });
      });
    }
  }

  async function getData(parent_id = 0, stackIndex = 0) {
    if (navigationStack[stackIndex] != null) {
      return null;
    }
    getFolderChildren({
      parent_id: parent_id,
    }).then((res) => {
      let _categories = [],
        _tags = [];
      res.records.map((record) => {
        const data = {
          id: record.get("children").identity.toNumber(),
          ...record.get("children").properties,
        };
        if (record.get("children").labels[0] === "ETagsFolder") {
          _categories = [..._categories, data];
        } else {
          _tags = [..._tags, data];
        }
      });
      setNavigationStack((stack) => {
        const _stack = [...stack];
        _stack[stackIndex] = {
          categories: _categories,
          tags: _tags,
        };
        console.log(_stack);
        return _stack;
      });
    });
  }

  const handleTickChange = (isChecked, value) => {
    if (isFromLetter) {
      let level = isChecked;
      setSelected((selected) => {
        let _selected = [...selected];
        if (_selected.map((e) => e.id).includes(value)) {
          _selected = _selected.filter((e) => e.id !== value);
        } 
        return [..._selected, { id: value, level: level }];
      });
      return;
    }
    if (isChecked) {
      setSelected([...selected, value]);
    } else {
      setSelected(selected.filter((item) => item !== value));
    }
  };

  useEffect(() => {
    setNavigationStack([]);
    getData();
  }, []);
  useEffect(() => {
    console.log(selected);
  }, [selected]);

  function push(parent_id) {
    setCurrentNavigationIndex((index) => index + 1);
    getData(parent_id, currentNavigationIndex + 1);
  }

  return (
    <div className="popup-background">
      <div className="popup-container">
        <div
          style={{
            backgroundColor: hexToRGBAfterOpactiy(color, 0.05),
          }}
          className="popup-header"
        >
          {navigationStack.length > 1 && (
            <IoIosArrowBack
              onClick={(e) => {
                setIsSearchActive(false);
                setCurrentNavigationIndex((index) => {
                  setNavigationStack((stack) => {
                    const _stack = [...stack];
                    _stack.splice(index, 1);
                    return _stack;
                  });
                  return index - 1;
                });
              }}
              style={{
                marginLeft: "-20px",
                marginRight: "10px",
                cursor: "pointer",
              }}
            />
          )}
          <span
            style={{
              flex: 1,
              fontWeight: "bold",
              fontSize: "13px",
              textAlign: "left",
              color: color,
            }}
          >
            {`Link ${isTag ? "Tag" : "Folder"}`}
          </span>
          <input
            className="search"
            type="search"
            placeholder="Search"
            onKeyUp={(e) =>
              e.key === "Enter" && getSearchResults(e.target.value)
            }
          />
        </div>
        <div
          style={{
            position: "absolute",
            overflow: "auto",
            width: "670px",
            height: "380px",
            top: "50px",
          }}
        >
          {navigationStack.length != 0 &&
            navigationStack[currentNavigationIndex]?.categories.map(
              (category, index) => (
                <PopupTile
                  key={category.id}
                  title={category.name}
                  showTick={!isTag}
                  onClick={() => push(category.id)}
                  isChecked={selected.includes(category.id)}
                  onChange={(isChecked) =>
                    handleTickChange(isChecked, category.id)
                  }
                />
              )
            )}
          {navigationStack.length != 0 &&
            navigationStack[currentNavigationIndex]?.tags.map((tag, index) => (
              <PopupTile
                key={tag.id}
                title={tag.name}
                icon={<FiTag color="#707071" size={15} />}
                onChange={(isChecked) => handleTickChange(isChecked, tag.id)}
                onClick={() => {}}
                isChecked={selected.includes(tag.id)}
                levelData={selected.find((e) => e.id === tag.id)?.level}
                isFromLetter={isFromLetter}
              />
            ))}
        </div>
        <div
          style={{
            backgroundColor: hexToRGBAfterOpactiy(color, 0.05),
          }}
          className="popup-footer"
        >
          <PopupCancelButton onClick={onCancel} />
          <PopupSaveButton onClick={() => onSave(selected)} />
        </div>
      </div>
    </div>
  );
}

export default LinkTagFolderPopup;
