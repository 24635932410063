import React, { useContext, useEffect, useState } from "react";
import "./SideBar.css";
import "./DatabaseButton.css";
import { FiDatabase } from "react-icons/fi";
import { FaFolder, FaFolderOpen } from "react-icons/fa";
import { ColorContext, UserContext } from "../../AppContext";
import hexToRGB from "../../utils/HexToRGB";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useLocation } from 'react-router-dom'

function HebrewDBButton({ active, selected, onClick, onCollapsed }) {
  const [color] = useContext(ColorContext);
  const location = useLocation();

  const [userSettings] = useContext(UserContext);
  const [years, setYears] = useState([...Array(28)].map((e, i) => i + 1));
  const [year, setYear] = useState();

//   useEffect(() => {
//     setYear(parseInt(selected));
//     if (!userSettings.letterPermission.view) {
//       setYears([]);
//     } else if (userSettings.letterPermission.from == "From Years") {
//       setYears(userSettings.letterPermission.years);
//     }
//     else {
//       setYears([...Array(27)].map((e, i) => 1 + i))
//     }
//   }, [userSettings]);

  return (
    <>
      <Link to="/hebrew-letter">
        <span
          className="outline-button"
          onClick={onClick}
          style={{
            backgroundColor: active ? hexToRGB(color, 0.19) : "white",
            color: `${active ? color : "#a0a0a0"}`,
            border: `${active ? "none" : "1px solid rgba(112, 112, 112, 0.19)"}`,
            marginBottom: `${active ? "15px" : "null"}`,
          }}
        >
          <FiDatabase
            color={active ? color : "#a0a0a0"}
            size={18}
            style={{ marginRight: "10px" }}
          />
          Hebrew DB
        </span>
      </Link>
      {active
        ? years.map((e, i) =>
          (year === 'nondated' ? false : e === year) ? (
            <Link
              to={`/hebrew-letter/${e}`}
              key={e}
              onClick={() => { setYear((v) => e); onCollapsed(); }}
            >
              <span className="letter-btn" style={{ color: color }}>
                <FaFolderOpen
                  color={color}
                  size={20}
                  style={{ marginRight: "10px" }}
                />
                {`Volume ${e}`}
              </span>
            </Link>
          ) : (
            <Link
              to={`/hebrew-letter/${e}`}
              key={e}
              onClick={() => {
                setYear((v) => e);
                if (isMobile)
                  onCollapsed();
              }}
            >
              <span className="letter-btn">
                <FaFolder
                  color={"#a0a0a0"}
                  size={18}
                  style={{ marginRight: "10px" }}
                />
                {`Volume ${e}`}
              </span>
            </Link>
          )
        )
        : null}
    </>
  );
}

export default HebrewDBButton;
