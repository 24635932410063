import { useContext, useState } from "react";
import { Modal } from "react-bootstrap";
import { isMobile } from "react-device-detect";
import { FiAlertCircle } from "react-icons/fi";
import { useHistory } from "react-router-dom";
import { useLazyWriteCypher } from "use-neo4j";
import { ColorContext } from "../../../AppContext";
import {
  signUp,
  uploadSingleFileToStorage,
} from "../../../services/firebaseServices";
import FilePicker from "../../dragdrop/FilePicker";
import "./NewUserCard.css";
const NewUserCard = ({ show, onHide }) => {
  const [color] = useContext(ColorContext);
  const history = useHistory();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [file, setFile] = useState(null);
  const [password, setPassword] = useState("");
  const [createUser] = useLazyWriteCypher(
    `CREATE (n:EUser $params) RETURN ID(n)`,
    "neo4j"
  );

  return (
    <Modal
      size="md"
      show={show}
      onHide={onHide}
      onEscapeKeyDown={onHide}
      onExit={onHide}
      centered
    >
      <Modal.Body style={{ padding: "40px" }}>
        <FilePicker
          onChange={(files) => {
            setFile(files[0]);
          }}
          onError={(errMsg) => console.log(errMsg)}
        >
          <div className="image-back">
            <img
              src={
                file != null
                  ? URL.createObjectURL(file)
                  : "https://www.w3schools.com/howto/img_avatar.png"
              }
              className="image"
            ></img>
          </div>
        </FilePicker>
        <div className="wrapper">
          <input
            className="textfield"
            onChange={(e) => setName(e.currentTarget.value)}
            placeholder="Name"
            required
          />
          <input
            className="textfield"
            onChange={(e) => setEmail(e.currentTarget.value)}
            placeholder="Email"
            type="email"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$"
            required
          />
          <input
            className="textfield"
            onChange={(e) => setPassword(e.currentTarget.value)}
            placeholder="Password"
            type="password"
            minLength={6}
            required
          />
          <div className="buttons">
            <button
              style={{
                backgroundColor: "rgba(0,0,0,0)",
                borderColor: "#95989A",
                border: "1px solid " + color,
                borderRadius: "3px",
                color: color,
                height: "48px",
                width: isMobile ? "40vw" : "144px",
                margin: "0px",
              }}
              onClick={onHide}
            >
              Cancel
            </button>
            <button
              style={{
                borderRadius: "3px",
                backgroundColor: color,
                color: "#fff",
                border: "1px solid rgba(0,0,0,0)",
                height: "48px",
                width: isMobile ? "40vw" : "144px",
                margin: "0px",
              }}
              onClick={() => {
                signUp(email, password).then(async (data) => {
                  var imageUrl
                  if (file)
                    imageUrl = await uploadSingleFileToStorage("users/" + data.user.uid + ".png", file)
                  else
                    imageUrl = "https://www.w3schools.com/howto/img_avatar.png"
                  createUser({
                    params: {
                      enabled: true,
                      settings: JSON.stringify({
                        letterPermission: {
                          view: true,
                          edit: false,
                          delete: false,
                          create: false,
                          from: "All Letters",
                          years: [],
                          categories: [],
                        },
                        attachmentsPermission: {
                          view: true,
                          upload: false,
                          delete: false,
                          from: "All Attachments",
                        },
                      }),
                      name: name,
                      email: email,
                      uid: data.user.uid,
                      imageUrl: imageUrl
                    },
                  }).then((res) => {
                    history.push("/users/" + data.user.uid);
                  });
                });
              }}
            >
              Save
            </button>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default NewUserCard;

