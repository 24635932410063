import "./App.css";
import { useEffect, useState } from "react";
import NavigationBar from "./components/NavBar";
import SideBar from "./components/sidebar/SideBar";
import DBTable from "./components/DBTable";
import { Route, Switch, useLocation } from "react-router-dom";
import LetterEditor from "./components/LetterEditor";
import { HotKeys } from "react-hotkeys";
import { isMobile } from "react-device-detect";
import { UserContext } from "./AppContext";
import { useLazyReadCypher } from "use-neo4j";
import { auth } from "./services/firebaseServices";
import HebrewDBTable from "./components/hebrewdb/HebrewDBTable";
import HebrewLetterEditor from "./components/hebrewdb/HebrewLetterEditor";
import DailyInsights from "./components/daily_insights/DailyInsights";
import ArticleTable from "./components/article/ArticleTable";
import ArticleEditor from "./components/article/ArticleEditor";
import Tags from "./components/tags/Tags";
import Category from "./components/tags/Tags";
import DailyInsightsEditor from "./components/daily_insights/DailyInsightsEditor";
import Users from "./components/users/Users";
import UserDetails from "./components/users/UserDetails";
import Homepage from "./components/homepage/homepage";
function App() {
  const [getUser] = useLazyReadCypher("MATCH (n:EUser {uid: $uid}) return n");
  const [userSettings, setUserSettings] = useState(null);

  useEffect(() => {
    if (auth.currentUser)
      getUser({
        uid: auth.currentUser.uid,
      }).then((user) => {
        setUserSettings(
          JSON.parse(user.records[0].get("n").properties.settings)
        );
      });
    else {
      setUserSettings({});
    }
  }, []);

  useEffect(() => {}, [userSettings]);

  const keyMap = {
    SAVE: ["command+s", "ctrl+s"],
  };
  const [collapsed, setCollapsed] = useState(isMobile);
  const location = useLocation();
  if (!userSettings) {
    return <div />;
  }
  return (
    <UserContext.Provider value={[userSettings, setUserSettings]}>
      <div style={{ overflow: "none" }}>
        <HotKeys keyMap={keyMap}>
          <NavigationBar
            collapsed={collapsed}
            onCollapsedPress={() => setCollapsed((val) => !val)}
          />
          <SideBar
            collapsed={collapsed}
            onCollapsed={() => {
              isMobile ? setCollapsed((val) => !val) : console.log("");
            }}
          />
          <div
            id="main"
            style={
              isMobile
                ? {
                    marginLeft: `${collapsed ? "0px" : "250px"}`,
                    marginTop: "8vh",
                    zIndex: 150,
                    overflow: "hidden",
                  }
                : {
                    marginLeft: `${collapsed ? "0px" : "250px"}`,
                    marginTop: "8vh",
                    zIndex: 150,
                    overflow: "hidden",
                  }
            }
          >
            <Switch>
              <Route
                path="/letter/:year/:letterId/edit"
                component={LetterEditor}
                key={location.pathname}
              />
              <Route
                path="/users/:userId"
                component={UserDetails}
                key={location.pathname}
              />
              {/* Deploying */}
              <Route path="/users" component={Users} key={location.pathname} />
              <Route
                path="/letter/:letterId/edit"
                component={LetterEditor}
                key={location.pathname}
              />
              <Route
                path="/hebrew-letter/:year/:letterId/edit"
                component={HebrewLetterEditor}
                key={location.pathname}
              />
              <Route
                path="/hebrew-letter/:letterId/edit"
                component={HebrewLetterEditor}
                key={location.pathname}
              />
              <Route path="/letter/new" component={LetterEditor} />
              <Route path="/letter/:year/new" component={LetterEditor} />
              <Route path="/hebrew-letter/new" component={HebrewLetterEditor} />
              <Route
                path="/hebrew-letter/:year/new"
                component={HebrewLetterEditor}
              />
              <Route
                path="/letter/:year"
                component={DBTable}
                key={location.pathname}
              />
              <Route
                path="/letter"
                component={DBTable}
                key={location.pathname}
              />
              <Route
                path="/dailyinsights"
                component={DailyInsights}
                key={location.pathname}
              />
              <Route
                path="/articles/:article_id"
                component={ArticleEditor}
                key={location.pathname}
              />
              <Route
                path="/articles"
                exact={true}
                component={ArticleTable}
                key={location.pathname}
              />
              <Route
                path="/hebrew-letter/:volume"
                component={HebrewDBTable}
                key={location.pathname}
              />
              <Route
                path="/hebrew-letter"
                component={HebrewDBTable}
                key={location.pathname}
              />
              <Route
                path="/trending/topics"
                component={Tags}
                key={location.pathname}
              />
              <Route
                path="/trending/homepage"
                component={Homepage}
                key={location.pathname}
              />
              <Route path="/tags" component={Tags} />
              <Route>
                <div />
              </Route>
            </Switch>
          </div>
        </HotKeys>
      </div>
    </UserContext.Provider>
  );
}

export default App;
