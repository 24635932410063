import { Chip } from "@material-ui/core";
import React, { useContext, useEffect } from "react";
import { ColorContext } from "../../../AppContext";
import { hexToRGBAfterOpactiy } from "../../../utils/HexToRGB";
import { PopupCancelButton, PopupSaveButton } from "./PopupButtons";
import { IoCloseCircleOutline } from "react-icons/io5";
import { useLazyReadCypher } from "use-neo4j";
import { v4 } from "uuid";
import DailyInsightsImageUploader from "../../daily_insights/DailyInsightsImageUploader";
import { uploadSingleFileToStorage } from "../../../services/firebaseServices";
import { toInteger } from "lodash";
import { Link } from "react-router-dom";

function EditTagFolder({
  isNew = false,
  isTag = false,
  isRoot = false,
  isTrending = false,
  onSave,
  onCancel,
  data,
}) {
  const [color] = useContext(ColorContext);
  const [state, setState] = React.useState({});
  const [letters, setLetters] = React.useState([]);
  const [letterIds, setLetterIds] = React.useState([]);
  const [folders, setFolders] = React.useState([]);
  const [file, setFile] = React.useState(null);
  const [linkedLetter, setLinkedLetter] = React.useState("");

  const handleSave = async () => {
    if (isTrending && folders.find((e) => e.id == state.linked) == undefined) {
      alert("Please select a tag to link to");
      return false;
    }
    if ((isRoot || isTrending) && file) {
      var uid = v4();
      var imageUrl = await uploadSingleFileToStorage("root-tags/" + uid, file);
      console.log("test2", imageUrl);
      setState((val) => {
        return {
          ...val,
          imageUrl: imageUrl,
        };
      });
      onSave({
        ...state,
        imageUrl: imageUrl,
      });
    } else {
      onSave({
        ...state,
      });
    }
  };

  const [_getSearchResults, {}] = useLazyReadCypher(
    `MATCH (item) WHERE  "ETagsFolder" IN labels(item) OR "ETag" IN labels(item) MATCH (item) WHERE toLower(item.name) CONTAINS $query OR $query IN item.keywords RETURN item LIMIT 10`
  );

  const [_getLinkedFolder, {}] = useLazyReadCypher(
    `MATCH (item) WHERE ID(item) = $id return item`
  );

  const [getLocations] = useLazyReadCypher(
    "MATCH (child) WHERE ID(child) = $id MATCH (child)<-[data:child]-(parents:ETagsFolder) return parents ORDER BY data.order"
  );
  const [getLetters] = useLazyReadCypher(
    isTrending
      ? "MATCH (folder:ETagsFolder) WHERE ID(folder) = $id MATCH (folder)-[:connectedLetter]->(letters:ELetter) return letters"
      : "MATCH (tag:ETag) WHERE ID(tag) = $id MATCH (tag)<-[:tag]-(letters:ELetter) return letters"
  );

  function getSearchResults(searchQuery) {
    if (searchQuery.length > 0) {
      _getSearchResults({ query: searchQuery.toLowerCase() }).then((res) => {
        let _folders = [];
        res.records.map((record) => {
          const data = {
            id: record.get("item").identity.toNumber(),
            ...record.get("item").properties,
          };
          if (data.type == "trending") return;
          _folders = [..._folders, data];
        });
        setFolders(_folders);
      });
    }
  }
  function getLinkedFolder() {
    if (data.linked) {
      _getLinkedFolder({ id: data.linked }).then((res) => {
        setLinkedLetter(res.records[0].get("item").properties.name);
        setFolders([
          {
            id: res.records[0].get("item").identity.toNumber(),
            ...res.records[0].get("item").properties,
          },
        ]);
      });
    }
  }

  useEffect(() => {
    if (!isNew) {
      getLocations({ id: data.id }).then((res) => {
        setState({
          ...data,
          locations: res.records.map(
            (record) => record.get("parents").properties.name
          ),
        });
      });
      if (isTrending) getLinkedFolder();
      if (isTrending || isTag) {
        getLetters({ id: data.id }).then((res) => {
          setLetterIds(res.records.map((record) => record.get("letters").identity.toNumber()));
          setLetters(
            res.records.map((record) => {
              const e = record.get("letters").properties;
              return (
                e.year +
                "-" +
                e.month +
                "-" +
                e.day +
                ((e.number || e.number != "") && " (" + e.number + ")")
              );
            })
          );
        });
      }
    }
  }, []);

  return (
    <div className="popup-background">
      <div className="popup-container">
        <div
          style={{
            backgroundColor: hexToRGBAfterOpactiy(color, 0.05),
          }}
          className="popup-header"
        >
          <span
            style={{
              fontWeight: "bold",
              fontSize: "13px",
              textAlign: "left",
              color: color,
            }}
          >
            {isTrending
              ? "Trending Topic"
              : (isNew ? "Create" : "Link") + (isTag ? " Tag" : " Folder")}
          </span>
        </div>
        <div
          style={{
            position: "absolute",
            display: "flex",
            flexDirection: "column",
            overflow: "auto",
            width: "670px",
            height: "380px",
            padding: "0 30px",
            top: "50px",
          }}
        >
          {!isNew && !isTrending && (
            <span
              style={{
                fontFamily: "Roboto",
                fontWeight: "bold",
                fontSize: "10px",
                marginTop: "30px",
              }}
            >
              Locations:{" "}
              <span
                style={{
                  fontFamily: "Roboto",
                  fontSize: "10px",
                  fontWeight: "normal",
                  marginTop: "30px",
                }}
              >
                {state?.locations?.map(
                  (location, index) => `${index == 0 ? "" : ", "}${location}`
                )}
              </span>
            </span>
          )}
          {!isNew && isTag && (
            <span
              style={{
                fontFamily: "Roboto",
                fontWeight: "bold",
                fontSize: "10px",
                marginTop: "30px",
              }}
            >
              Letters:
              <span
                style={{
                  fontFamily: "Roboto",
                  fontSize: "10px",
                  fontWeight: "normal",
                  marginTop: "30px",
                }}
              >
                {letters?.map((letter, index) => (
                  <Link to={`/letter/${letterIds[index]}/edit`}> {`${index == 0 ? "" : ", "}${letter}`}</Link>
                ))}
              </span>
            </span>
          )}
          {isTrending && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <input
                type="text"
                value={state.name}
                placeholder="Name"
                onChange={(event) =>
                  setState({ ...state, name: event.currentTarget.value })
                }
                style={{
                  color: "#000",
                  marginTop: "20px",
                  marginRight: "10px",
                  fontSize: "15px",
                  padding: "10px 15px",
                  width: "100%",
                }}
              />
              <input
                type="text"
                value={linkedLetter}
                placeholder="Linked to"
                list="cityname"
                onChange={(event) => {
                  setLinkedLetter(event.currentTarget.value);
                  if (folders.map((e) => e.name).includes(event.target.value)) {
                    setState({
                      ...state,
                      linked: folders.find((e) => (e.name = event.target.value))
                        .id,
                    });
                  }
                  getSearchResults(event.target.value);
                }}
                style={{
                  color: "#000",
                  marginTop: "20px",
                  marginLeft: "10px",
                  fontSize: "15px",
                  padding: "10px 15px",
                  width: "100%",
                }}
              />
              <datalist id="cityname">
                {folders.map((e) => (
                  <option value={e.name} key={e.id} id={e.id}>
                    {e.id}
                  </option>
                ))}
              </datalist>
            </div>
          )}
          {!isTrending && (
            <input
              type="text"
              value={state.name}
              placeholder="Name"
              onChange={(event) =>
                setState({ ...state, name: event.currentTarget.value })
              }
              style={{
                color: "#000",
                marginTop: "20px",
                fontSize: "15px",
                padding: "10px 15px",
                width: "100%",
              }}
            />
          )}
          <KeywordsInput
            isTrending={isTrending}
            key={state.keywords}
            value={state.keywords}
            onChange={(keywords) =>
              setState({
                ...state,
                keywords: keywords,
                searchData: [state.name, ...keywords].join(", "),
              })
            }
          />
          <div style={{ marginTop: "20px" }} />
          {(isRoot || isTrending) && (
            <DailyInsightsImageUploader
              onFileLoad={(file) => setFile(file)}
              value={state.imageUrl}
            />
          )}
          <div style={{ marginTop: "20px" }} />
        </div>
        <div
          style={{
            backgroundColor: hexToRGBAfterOpactiy(color, 0.05),
          }}
          className="popup-footer"
        >
          <PopupCancelButton onClick={onCancel} />
          <PopupSaveButton onClick={() => handleSave()} />
        </div>
      </div>
    </div>
  );
}

export default EditTagFolder;

const KeywordsInput = ({ onChange, value, isTrending = false }) => {
  const [color] = useContext(ColorContext);
  const [tags, setTags] = React.useState(value ?? []);

  useEffect(() => {
    console.log(tags);
    onChange(tags);
  }, [tags]);

  const addTags = (event) => {
    if (event.key === "Enter" && event.target.value !== "") {
      setTags([...tags, event.target.value.toLowerCase()]);
      event.target.value = "";
    }
  };
  const removeTags = (index) => {
    setTags([...tags.filter((tag) => tags.indexOf(tag) !== index)]);
  };
  return (
    <div className="tags-input">
      {tags.map((tag, index) => (
        <div
          className="chip"
          key={index}
          style={{
            backgroundColor: color,
          }}
          onClick={() => removeTags(index)}
        >
          {tag}
          <IoCloseCircleOutline
            size={14}
            color={"#fff"}
            style={{
              marginLeft: "5px",
            }}
          />
        </div>
      ))}
      <input
        type="text"
        onKeyUp={(event) => addTags(event)}
        placeholder={
          tags.length == 0
            ? isTrending
              ? "Press enter to add letter ids"
              : "Press enter to add keywords"
            : ""
        }
      />
    </div>
  );
};
