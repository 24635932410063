import React, { useContext, useEffect, useState } from "react";
import { TransitionGroup, CSSTransition } from "react-transition-group";
import "./Article.css";
import TitleCard from "./cards/TitleCard";
import ParagraphCard from "./cards/ParagraphCard";
import LinksCard from "./cards/LinksCard";
import ImageCard from "./cards/ImageCard";
import AddDropDown from "./cards/dropdowns/AddDropDown";
import { v4 } from "uuid";
import HeaderCard from "./cards/HeaderCard";
import { ColorContext } from "../../AppContext";
import { useLazyReadCypher, useLazyWriteCypher } from "use-neo4j";
import {
  deleteDirectoryToStorage,
  deleteFileToStorage,
  uploadSingleFileToStorage,
} from "../../services/firebaseServices";
import { useParams } from "react-router";
import MetaCard from "./cards/MetaCard";
import { FiEye, FiTrash, FiSend } from "react-icons/fi";

var _ = require("lodash");
var images_to_delete = [];
function ArticleEditor(props) {
  const { article_id } = useParams();
  const [hoverIndex, setHoverIndex] = useState(null);
  const [color] = useContext(ColorContext);
  const [hoverSingleIndex, setSingleHoverIndex] = useState(null);
  const [cards, setCards] = useState([]);
  const [status, setStatus] = useState("In Progress");
  const [oldStatus, setOldStatus] = useState(status);
  const [oldcards, setOldCards] = useState([]);
  const [createArticle] = useLazyWriteCypher(
    "CREATE (article:EArticle) return article"
  );

  const [upadateArticle, {}] = useLazyWriteCypher(
    "MATCH (article:EArticle) WHERE ID(article) = $params.id SET article.data = $params.data SET article.status = $params.status return article"
  );
  const [getArticle, {}] = useLazyReadCypher(
    "MATCH (article:EArticle) WHERE ID(article) = $params.id return article"
  );
  const [_deleteArticle, {}] = useLazyWriteCypher(
    "MATCH (article:EArticle) WHERE ID(article) = $id DETACH DELETE article"
  );

  useEffect(() => {
    if (props.location?.state?.data) {
      setCards(JSON.parse(props.location?.state?.data));
      setOldCards(JSON.parse(props.location?.state?.data));
      setStatus(props.location?.state?.status);
      setOldStatus(props.location?.state?.status);
    } else if (article_id != "new") {
      console.log(article_id);
      getArticle({ params: { id: parseInt(article_id) } }).then((res) => {
        setStatus(
          res.records[0].get("article").properties.status ?? "In Progress"
        );
        setOldStatus(
          res.records[0].get("article").properties.status ?? "In Progress"
        );
        setCards(JSON.parse(res.records[0].get("article").properties.data));
        setOldCards(JSON.parse(res.records[0].get("article").properties.data));
      });
    }
  }, []);
  useEffect(() => {
    console.log(cards);
  }, [cards]);

  const saveArticle = async () => {
    for (let i = 0; i < cards.length; i++) {
      const card = cards[i];

      if (card.required) {
        if (card.type == "Image") {
          if (card.value == undefined || card.value == "") {
            alert("Please upload an Header image for this card");
            return;
          }
        } else if (card.type == "Header") {
          if (
            card.value == undefined ||
            card.value == "" ||
            card.value.title == undefined ||
            card.value.title == "" ||
            card.value.title.replace(/<\/?[^>]+(>|$)/g, "") == ""
          ) {
            alert("Please enter a title for this card");
            return;
          }
        }
      }
    }
    // delete images
    if (article_id != "new") {
      images_to_delete.forEach((uid) => {
        deleteFileToStorage("articles/" + article_id + `/${uid}` + ".png");
      });
      images_to_delete = [];
    }

    // upload image to firebase hosting
    if (article_id == "new") {
      createArticle().then(async (res) => {
        const id = res.records[0].get("article").identity.toNumber();
        console.log(id);
        await dbOperation(id);
      });
    } else {
      dbOperation(parseInt(article_id));
    }
  };

  async function dbOperation(id) {
    for (let index = 0; index < cards.length; index++) {
      const card = cards[index];
      if (card.type === "Image" && typeof card.value == "object") {
        console.log("uploading image");
        let url = await uploadSingleFileToStorage(
          "articles/" + id + `/${card.uid}` + ".png",
          card.value
        );
        cards[index].value = url;
      }
      if (card.type === "Meta" && typeof card.value.file == "object") {
        console.log("uploading image");
        let url = await uploadSingleFileToStorage(
          "articles/" + id + `/${card.uid}` + ".png",
          card.value.file
        );
        delete cards[index].value.file;
        cards[index].value.imageUrl = url;
      }
    }
    const d = JSON.stringify(cards);
    setOldCards(JSON.parse(d));
    setOldStatus(status);
    upadateArticle({
      params: { id: id, status: status, data: JSON.stringify(cards) },
    });
  }

  const deleteArticle = async () => {
    if (article_id != "new") {
      const del = window.confirm(
        "Are you sure you want to delete this article?"
      );
      if (del) {
        _deleteArticle({ id: parseInt(article_id) }).then((res) => {
          console.log(res);
          deleteDirectoryToStorage("articles/" + article_id);
          props.history.push("/articles");
        });
      }
    }
  };

  const handleNotification = () => {
    if (article_id != "new") {
      const notifiy = window.confirm(
        "Are you sure you want to notify this article?"
      );
      if (notifiy) {
        var requestOptions = {
          method: "POST",
          redirect: "follow",
        };
        const title = cards[1].value.title?.replace(/<[^>]*>/g, "");
        const subtitle = cards[1].value.subtitle?.replace(/<[^>]*>/g, "");
        const image = cards[0].value;
        const isnewlyreleased = article_id.toString() === "27247";
        console.log(title, subtitle, image);
        fetch(
          `https://us-central1-english-letters.cloudfunctions.net/notify?topic=${
            isnewlyreleased ? "newlyreleased" : "articles"
          }&title=${title}&description=${subtitle}&imageUrl=${encodeURIComponent(
            image
          )}&id=${article_id}`,
          requestOptions
        )
          .then((response) => response.json())
          .then((result) => {
            console.log(result);
            alert(result.message);
          })
          .catch((error) => {
            alert(error.message);
          });
      }
    }
  };

  const addItem = (type, index) => {
    if (!type) return;
    let card = {
      type: type,
      uid: v4(),
    };
    setCards((cards) => [
      ...cards.slice(0, index),
      card,
      ...cards.slice(index),
    ]);
  };
  const onDelete = (uid) => {
    const card_to_delete = cards.find((card) => card.uid === uid);
    if (card_to_delete.type === "Image") {
      images_to_delete.push(card_to_delete.uid);
    }
    const newCards = cards.filter((card) => {
      return uid !== card.uid;
    });
    setCards(newCards);
  };

  const moveUp = (uid) => {
    const index = cards.findIndex((card) => card.uid === uid);
    if (index === 0) return;
    const newCards = [...cards];
    const card = newCards[index];
    newCards[index] = newCards[index - 1];
    newCards[index - 1] = card;
    setCards(newCards);
  };

  const moveDown = (uid) => {
    const index = cards.findIndex((card) => card.uid === uid);
    if (index === cards.length - 1) return;
    const newCards = [...cards];
    const card = newCards[index];
    newCards[index] = newCards[index + 1];
    newCards[index + 1] = card;
    setCards(newCards);
  };

  function onChanged(i, value) {
    setCards((_cards) => {
      const newCards = [..._cards];
      newCards[i].value = value;
      return newCards;
    });
  }
  // function MouseOver(index) {
  //   // console.log("HOVER OVER INDEX: ", index);
  //   setHoverIndex(index);
  // }
  // function MouseOut(_) {
  //   // console.log("HOVER OUT INDEX: ", hoverIndex);
  //   setHoverIndex(null);
  // }
  function PlusMouseOver(index) {
    // console.log("SINGLE HOVER OVER INDEX: ", index);
    setSingleHoverIndex(index);
  }
  function PlusMouseOut(_) {
    // console.log("SINGLE HOVER OUT INDEX: ", hoverIndex);
    setSingleHoverIndex(null);
  }
  return (
    <>
      <div
        style={{
          margin: "auto",
          margin: "0 20%",
          marginTop: "7vh",
          display: "flex",
          justifyContent: "space-between",
          textAlign: "center",
        }}
      >
        <select
          placeholder="Status"
          style={{
            margin: "0px !important",
          }}
          className="article-status"
          value={status}
          onChange={(e) => {
            console.log(e.target.value);
            setStatus(e.target.value);
          }}
        >
          <option value="In Progress">In Progress</option>
          <option value="Live">Live</option>
          <option value="Pinned">Pinned</option>
        </select>
        <span className="preview">
          Preview <FiEye style={{ width: "100%" }} />
        </span>
        <span className="preview" onClick={deleteArticle}>
          Delete <FiTrash style={{ width: "100%" }} />
        </span>
        <span className="preview" onClick={handleNotification}>
          Send Notification <FiSend />
        </span>
      </div>
      <div
        style={{
          width: "100%",
          margin: "auto",
          marginTop: "7vh",
          textAlign: "center",
        }}
      >
        <TransitionGroup>
          {cards.map((card, i) => (
            <CSSTransition key={card.uid} timeout={300} classNames="item">
              <div
              // key={card.uid}
              // onMouseEnter={() => MouseOver(i)}
              // onMouseLeave={MouseOut}
              >
                {card.type === "Header" ? (
                  <HeaderCard
                    onChanged={(_val) => onChanged(i, _val)}
                    uid={card.uid}
                    value={card.value}
                    onDelete={() => onDelete(card.uid)}
                    onMoveUp={() => moveUp(card.uid)}
                    onMoveDown={() => moveDown(card.uid)}
                  />
                ) : card.type === "Title" ? (
                  <TitleCard
                    onChanged={(_val) => onChanged(i, _val)}
                    uid={card.uid}
                    value={card.value}
                    onDelete={() => onDelete(card.uid)}
                    onMoveUp={() => moveUp(card.uid)}
                    onMoveDown={() => moveDown(card.uid)}
                  />
                ) : card.type === "Paragraph" ? (
                  <ParagraphCard
                    onChanged={(_val) => onChanged(i, _val)}
                    uid={card.uid}
                    value={card.value}
                    onDelete={() => onDelete(card.uid)}
                    onMoveUp={() => moveUp(card.uid)}
                    onMoveDown={() => moveDown(card.uid)}
                  />
                ) : card.type === "Card" ? (
                  <LinksCard
                    onChanged={(_val) => onChanged(i, _val)}
                    uid={card.uid}
                    value={card.value}
                    onDelete={() => onDelete(card.uid)}
                    onMoveUp={() => moveUp(card.uid)}
                    onMoveDown={() => moveDown(card.uid)}
                  />
                ) : card.type === "Meta" ? (
                  <MetaCard
                    onChanged={(_val) => onChanged(i, _val)}
                    uid={card.uid}
                    value={card.value}
                    onDelete={() => onDelete(card.uid)}
                    onMoveUp={() => moveUp(card.uid)}
                    onMoveDown={() => moveDown(card.uid)}
                  />
                ) : (
                  card.type === "Image" && (
                    <ImageCard
                      onChanged={(_val) => onChanged(i, _val)}
                      value={card.value}
                      showTitle={i !== 0}
                      onDelete={() => onDelete(card.uid)}
                      onMoveUp={() => moveUp(card.uid)}
                      onMoveDown={() => moveDown(card.uid)}
                    />
                  )
                )}
                <div
                  onMouseEnter={() => PlusMouseOver(i)}
                  onMouseLeave={PlusMouseOut}
                >
                  <AddDropDown
                    onSelect={(type) => addItem(type, i + 1)}
                    show={
                      i > 0
                        ? hoverSingleIndex != null
                          ? hoverSingleIndex === i
                          : hoverIndex === i + 1 || hoverIndex === i
                        : false
                    }
                  />
                </div>
              </div>
            </CSSTransition>
          ))}
        </TransitionGroup>
        <button
          className="submit"
          disabled={_.isEqual(oldcards, cards) && status === oldStatus}
          style={{
            backgroundColor: `${
              _.isEqual(oldcards, cards) && status === oldStatus
                ? "grey"
                : color
            }`,
          }}
          onClick={() => {
            saveArticle();
          }}
        >
          Save Changes
        </button>
      </div>
    </>
  );
}

export default ArticleEditor;
