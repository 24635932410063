/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { ColorContext, UserContext } from "../AppContext";
import { IoMdOptions } from "react-icons/io";
import { BiExport } from "react-icons/bi";
import "./DBTable.css";
import hexToRGB from "../utils/HexToRGB";
import { useLazyReadCypher, useReadCypher } from "use-neo4j";
import { Menu } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useParams } from "react-router";
import { BrowserView, MobileView } from "react-device-detect";
import { SearchQueryContext, SortStateContext } from "../FieldStateContext";
import { FaAngleDown, FaAngleUp, FaPlus } from "react-icons/fa";
import { v4 } from "uuid";
import { get, set } from "local-storage";
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';
import { auth } from "../services/firebaseServices";

const DBTable = () => {
  const history = useHistory();
  const { year } = useParams();
  const [theme] = useContext(ColorContext);
  const [userSettings] = useContext(UserContext);
  const [progress, setProgress] = useState(false);
  const [orderBy, setOrderBy] = useState(
    "Order by n.year , n.month, n.day, n.number"
  );
  const [page, setPage] = useState(0);
  const [length, setLength] = useState(0);
  const [letters, setLetters] = useState([]);
  const [query] = useContext(SearchQueryContext);
  const [getELetter, { loading, records }] = useLazyReadCypher(
    year === 'nondated' ? `MATCH (n:ELetter) where n.year in ["","x"]  ${query !== "" ? `Match(n) where ${query}` : ""
      } ${userSettings.letterPermission.view &&
        userSettings.letterPermission.from === "From Category" ?
        `MATCH (n) where n.status in [${userSettings.letterPermission.categories.map(
          (e) => `"${e}"`
        )}]` : !userSettings.letterPermission.view ? `MATCH (n) where n.year in []` : ``
      } RETURN n ${orderBy} skip ${page * 50} limit 50` : year !== undefined
      ? `MATCH (n:ELetter) where n.year = "${year}" ${query !== "" ? `Match(n) where ${query}` : ""
      } ${userSettings.letterPermission.view &&
        userSettings.letterPermission.from === "From Category" ?
        `MATCH (n) where n.status in [${userSettings.letterPermission.categories.map(
          (e) => `"${e}"`
        )}]` : ``} RETURN n ${orderBy} skip ${page * 50} limit 50`
      : `MATCH (n:ELetter) where n.year <> '' and n.year <> 'x' Match(n)${query !== "" ? `where ${query}` : ""} ${userSettings.letterPermission.view &&
        userSettings.letterPermission.from === "From Years" ?
        `MATCH (n) where n.year in [${userSettings.letterPermission.years.map(
          (e) => `"${e}"`
        )}]` : userSettings.letterPermission.view &&
          userSettings.letterPermission.from === "From Category" ?
          `MATCH (n) where n.status in [${userSettings.letterPermission.categories.map(
            (e) => `"${e}"`
          )}]` : !userSettings.letterPermission.view ? `MATCH (n) where n.year in []` : ``
      } RETURN n ${orderBy} skip ${page * 50} limit 50`
  );


  const [getExcelData, { }] = useLazyReadCypher(
    year === 'nondated' ? `MATCH (n:ELetter) where n.year in ["","x"]  ${query !== "" ? `Match(n) where ${query}` : ""
      } ${userSettings.letterPermission.view &&
        userSettings.letterPermission.from === "From Category" ?
        `MATCH (n) where n.status in [${userSettings.letterPermission.categories.map(
          (e) => `"${e}"`
        )}]` : !userSettings.letterPermission.view ? `MATCH (n) where n.year in []` : ``
      } MATCH (n)-[:file]-(file) RETURN n, collect(file.downloadUrl) as files ${orderBy}` : year !== undefined
      ? `MATCH (n:ELetter) where n.year = "${year}" ${query !== "" ? `Match(n) where ${query}` : ""
      } ${userSettings.letterPermission.view &&
        userSettings.letterPermission.from === "From Category" ?
        `MATCH (n) where n.status in [${userSettings.letterPermission.categories.map(
          (e) => `"${e}"`
        )}]` : ``} MATCH (n)-[:file]-(file) RETURN n, collect(file.downloadUrl) as files ${orderBy}`
      : `MATCH (n:ELetter) where n.year <> '' and n.year <> 'x' Match(n)${query !== "" ? `where ${query}` : ""} ${userSettings.letterPermission.view &&
        userSettings.letterPermission.from === "From Years" ?
        `MATCH (n) where n.year in [${userSettings.letterPermission.years.map(
          (e) => `"${e}"`
        )}]` : userSettings.letterPermission.view &&
          userSettings.letterPermission.from === "From Category" ?
          `MATCH (n) where n.status in [${userSettings.letterPermission.categories.map(
            (e) => `"${e}"`
          )}]` : !userSettings.letterPermission.view ? `MATCH (n) where n.year in []` : ``
      } MATCH (n)-[:file]-(file) RETURN n, collect(file.downloadUrl) as files ${orderBy}`
  );
  const { first, run } = useReadCypher(
    year === 'nondated' ? `MATCH (n:ELetter) where n.year in ["","x"]  Match(n)  ${query !== "" ? `where ${query}` : ""
      } ${userSettings.letterPermission.view &&
        userSettings.letterPermission.from === "From Category" ?
        `MATCH (n) where n.status in [${userSettings.letterPermission.categories.map(
          (e) => `"${e}"`
        )}]` : ``} RETURN count(n)` : year !== undefined
      ? `MATCH (n:ELetter) where n.year = "${year}" Match(n)  ${query !== "" ? `where ${query}` : ""
      } ${userSettings.letterPermission.view &&
        userSettings.letterPermission.from === "From Category" ?
        `MATCH (n) where n.status in [${userSettings.letterPermission.categories.map(
          (e) => `"${e}"`
        )}]` : ``} RETURN count(n)`
      : `MATCH (n:ELetter) where n.year <> '' and n.year <> 'x' Match(n) ${query !== "" ? `where ${query}` : ""} ${userSettings.letterPermission.view &&
        userSettings.letterPermission.from === "From Years" ?
        `MATCH (n) where n.year in [${userSettings.letterPermission.years.map(
          (e) => `"${e}"`
        )}]` : userSettings.letterPermission.view &&
          userSettings.letterPermission.from === "From Category" ?
          `MATCH (n) where n.status in [${userSettings.letterPermission.categories.map(
            (e) => `"${e}"`
          )}]` : !userSettings.letterPermission.view ? `MATCH (n) where n.year in []` : ``
      } RETURN count(n)`
  );

  useEffect(() => {
    if (first !== undefined) {
      setLength(first.get("count(n)").toNumber());
    }
  }, [first]);

  useEffect(() => {
    if (page !== 0) getData();
    if (orderBy !== "" && page === 0) getData(true);
    if (query !== "" && page === 0) {
      getData(true);
      run();
    }
  }, [page, orderBy, query]);

  useEffect(() => {
    if ((year === 'nondated' || year === undefined) ? false : userSettings.letterPermission.from === "From Years" && !userSettings.letterPermission.years.includes(parseInt(year))) {
      alert("User doesn't have access to this year")
      history.goBack()
    } else {
      setPage(0);
      if (query === "") {
        getData(true);
      }
    }
  }, [query]);

  function getData(sorted) {
    if (sorted) {
      setLetters([]);
    }
    let result;
    getELetter().then((val) => {
      const res = val.records.map((e) => {
        return {
          ...e._fields[0].properties,
          ...{
            id: e._fields[0].identity.toNumber(),
            state: e._fields[0].properties,
          },
        };
      });
      result = res;
      setLetters((val) => [
        ...(sorted ? [] : val),
        ...result.map((e) => {
          return {
            id: e.id,
            Date: e.year + "-" + e.month + "-" + e.day,
            Number: e.number,
            "Date on Letter": e.dateText,
            Name: e.recipientText,
            Address: e.addressText,
            Source: e.source,
            "Source Page": e.sourcePage,
            "Editors Notes": e.editorsNotes,
            "Main Text": e.mainText,
            "Center Text": e.centerText,
            Footnotes: e.footnotes,
            Summary: e.summary,
            Status: e.status,
            day: e.day,
            month: e.month,
            year: e.year,
            state: e.state,
          };
        }),
      ]);
    });
  }

  async function handleExport() {
    setProgress(true);
    getExcelData().then((val) => {
      console.log(val);
      console.log('done');
      var data = val.records.map((e) => {
        return {
          ...e.get('n').properties,
          id: e.get('n').identity.toNumber(),
          files: e.get('files'),
        };
      });
      console.log('data', data);
      var results = data.map((e) => {
        return {
          id: e.id,
          Date: e.year + "-" + e.month + "-" + e.day,
          Number: e.number,
          "Date on Letter": e.dateText,
          Name: e.recipientText,
          Address: e.addressText,
          Source: e.source,
          "Source Page": e.sourcePage,
          "Editors Notes": e.editorsNotes,
          "Main Text": e.mainText,
          "Center Text": e.centerText,
          Footnotes: e.footnotes,
          Summary: e.summary,
          Status: e.status,
          Attachments: e.files.join('\n'),
        };
      })
      var ws = XLSX.utils.json_to_sheet(results);
      var wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Letters");
      XLSX.writeFile(wb, "export.xlsx");
      setProgress(false);
    });
  }

  function getOrderByStatments(header, value) {
    let order = "";
    if (value === "dsc") {
      order = "desc";
    }
    switch (header) {
      case "Date":
        return (
          "order by " +
          (year !== undefined ? "" : `n.year ${order},`) +
          " n.month " +
          order +
          ", n.day " +
          order
        );
      case "Number":
        return "order by n.number " + order;
      case "Date on Letter":
        return "order by n.dateText " + order;
      case "Name":
        return "order by n.recipientText " + order;
      case "Address":
        return "order by n.addressText " + order;
      case "Source":
        return "order by n.source " + order;
        case "Source Page":
          return "order by n.sourcePage " + order;  
      case "Editors Notes":
        return "order by n.editorsNotes " + order;
      case "Main Text":
        return "order by n.mainText " + order;
      case "Center Text":
        return "order by n.centerText " + order;
      case "Footnotes":
        return "order by n.footnotes " + order;
      case "Summary":
        return "order by n.summary " + order;
      case "Status":
        return "order by n.status " + order;
      default:
        break;
    }
  }

  const tableList = [
    { header: "Date", value: 1 },
    { header: "Number", value: 1.5 },
    { header: "Date on Letter", value: 2 },
    { header: "Name", value: 2 },
    { header: "Address", value: 2 },
    { header: "Source", value: 2 },
    { header: "Source Page", value: 2 },
    { header: "Editors Notes", value: 2 },
    { header: "Main Text", value: 2 },
    { header: "Center Text", value: 2 },
    { header: "Footnotes", value: 2 },
    { header: "Summary", value: 2 },
    { header: "Status", value: 2 },
  ];

  const fetchData = () => {
    setPage((val) => (val += 1));
  };

  const onCheckChanged = async (value, header) => {
    setState((e) =>
      e.map((ele) => {
        if (ele.header === header) {
          return { header: header, value: value };
        }
        return ele;
      })
    );
  };

  const onHover = async (value, header) => {
    setSortFilter((e) =>
      e.map((ele) => {
        if (ele.header === header) {
          return { ...ele, ...{ header: header, hover: value } };
        }
        return ele;
      })
    );
  };

  const onSortChanged = async (value, header) => {
    setPage((val) => 0);
    let order = getOrderByStatments(header, value);
    setOrderBy((val) => order);
    setSortFilter((e) =>
      e.map((ele) => {
        if (ele.header === header) {
          return { ...ele, ...{ header: header, value: value } };
        }
        return { ...ele, ...{ value: "null" } };
      })
    );
  };

  const [sortFilter, setSortFilter] = useContext(SortStateContext);

  const [state, setState] = useState(
    get("sort") ?? [
      { header: "Date", value: true },
      { header: "Number", value: true },
      { header: "Date on Letter", value: true },
      { header: "Name", value: true },
      { header: "Address", value: true },
      { header: "Source", value: true },
      { header: "Source Page", value: true },
      { header: "Editors Notes", value: false },
      { header: "Main Text", value: false },
      { header: "Center Text", value: false },
      { header: "Footnotes", value: false },
      { header: "Summary", value: false },
      { header: "Status", value: false },
    ]
  );
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    set("sort", state);
  }, [state]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <BrowserView style={{ width: "100%" }}>
        <div style={{ overflow: "auto" }}>
          <div className="table-header2">
            <div
              className="table-header"
              style={{ backgroundColor: hexToRGB(theme, 0.05) }}
            >
              {tableList.map((table) => {
                if (state.find((e) => e.header === table.header).value) {
                  var total = 0;
                  tableList.forEach((ele) => {
                    if (state.find((e) => e.header === ele.header).value) {
                      total += ele.value;
                    }
                  });
                  let width = `${(table.value / total) * 100}%`;
                  return (
                    <span
                      style={{
                        width: width,
                        textAlign: table.align,
                        userSelect: "none",
                      }}
                      onClick={() =>
                        onSortChanged(
                          sortFilter.find((e) => e.header === table.header)
                            .value === "asc" ||
                            sortFilter.find((e) => e.header === table.header)
                              .value === "null"
                            ? "dsc"
                            : "asc",
                          table.header
                        )
                      }
                      onMouseOver={() => onHover(true, table.header)}
                      onMouseOut={() => onHover(false, table.header)}
                      key={table.header + "0"}
                    >
                      {table.header}
                      {sortFilter.find((e) => e.header === table.header)
                        .hover &&
                        sortFilter.find((e) => e.header === table.header)
                          .value === "null" && (
                          <FaAngleDown
                            key={table.header + "3"}
                            size={10}
                            color={"#707070"}
                            onClick={() => onSortChanged("dsc", table.header)}
                          />
                        )}
                      {sortFilter.find((e) => e.header === table.header)
                        .value === "asc" ? (
                        <FaAngleUp
                          key={table.header + "3"}
                          size={10}
                          color={"#707070"}
                          onClick={() => onSortChanged("dsc", table.header)}
                        />
                      ) : sortFilter.find((e) => e.header === table.header)
                        .value === "dsc" ? (
                        <FaAngleDown
                          key={table.header + "4"}
                          size={10}
                          color={"#707070"}
                          onClick={() => onSortChanged("asc", table.header)}
                        />
                      ) : (
                        <div />
                      )}
                    </span>
                  );
                }
              })}
              <div style={{ position: "absolute", right: "1.2vw" }}>
                <IoMdOptions
                  color={theme}
                  size={20}
                  aria-label="more"
                  aria-controls="long-menu-1"
                  aria-haspopup="true"
                  onClick={handleClick}
                />
                {(auth.currentUser?.email ?? '') === 'dev@osfy.io' ? (progress ? <img src={'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif'} height={20} width={20} /> : <BiExport
                  color={theme}
                  size={20}
                  style={{ marginLeft: "1vw" }}
                  onClick={handleExport}
                />): <></>}
                <Menu
                  id="long-menu-1"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  PaperProps={{
                    style: {
                      borderRadius: "4px",
                      border: "1px solid #B7B7B7",
                      fontSize: "1.6vh",
                    },
                  }}
                  onClose={handleClose}
                >
                  {tableList.map((e) => {
                    return (
                      <div
                        className="d-flex align-items-center"
                        style={{
                          padding: "1vh 2vh",
                          borderBottom: "1px solid #B7B7B7",
                          width: "12vw",
                        }}
                        key={e.header + "1"}
                      >
                        {" "}
                        <input
                          type="checkbox"
                          onChange={(input) =>
                            onCheckChanged(input.target.checked, e.header)
                          }
                          checked={
                            state.find((s) => s.header === e.header).value
                          }
                        />
                        <div style={{ paddingLeft: "10px" }}>{e.header}</div>
                      </div>
                    );
                  })}
                </Menu>
              </div>
            </div>
          </div>
          <div style={{ margin: "30px 50px" }}>
            {<InfiniteScroll
              dataLength={letters.length} //This is important field to render the next data
              next={fetchData}
              hasMore={letters.length !== length || loading}
              loader={<h4>Loading...</h4>}
            >
              {letters.map((e) => (
                <Link to={
                  year !== undefined
                    ? `/letter/${year}/${e.id}/edit`
                    : `/letter/${e.id}/edit`} target="_blank">
                  <div
                    className="table-row"
                    key={v4()}
                    onClick={(event) => {
                      event.preventDefault();
                      history.push({
                        pathname:
                          year !== undefined
                            ? `/letter/${year}/${e.id}/edit`
                            : `/letter/${e.id}/edit`,
                        state: {
                          data: e.state,
                          id: e.id,
                        },
                      });
                    }}
                  >
                    {tableList.map((table) => {
                      if (state.find((e) => e.header === table.header).value) {
                        var total = 0;
                        tableList.forEach((ele) => {
                          if (state.find((e) => e.header === ele.header).value) {
                            total += ele.value;
                          }
                        });
                        let width = `${(table.value / total) * 100}%`;
                        return (
                          <div
                            style={{
                              width: width,
                              textAlign: table.align,
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              textOverflow: "ellipsis",
                            }}
                          >
                            {e[table.header]?.replace(/<[^>]*>/g, "")}
                          </div>
                        );
                      }
                    })}
                  </div>
                </Link>
              ))}
            </InfiniteScroll>}
          </div>
        </div>
      </BrowserView>
      <MobileView style={{ width: "100%", height: "100%" }}>
        <div style={{ overflow: "auto" }}>
          <div className="table-header2">
            <div
              className="table-header-mobile"
              style={{ backgroundColor: hexToRGB(theme, 0.05) }}
            >
              {tableList.map((table) => {
                return (
                  <div
                    style={{
                      width: `${table.value * 100}px`,
                      textAlign: table.align,
                      display: "inline-block",
                    }}
                    key={table.header + "0"}
                  >
                    {table.header}
                    {sortFilter.find((e) => e.header === table.header).hover &&
                      sortFilter.find((e) => e.header === table.header)
                        .value === "null" && (
                        <FaAngleDown
                          key={table.header + "3"}
                          size={10}
                          color={"#707070"}
                          onClick={() => onSortChanged("dsc", table.header)}
                        />
                      )}
                    {sortFilter.find((e) => e.header === table.header).value ===
                      "asc" ? (
                      <FaAngleUp
                        key={table.header + "3"}
                        size={10}
                        color={"#707070"}
                        onClick={() => onSortChanged("dsc", table.header)}
                      />
                    ) : sortFilter.find((e) => e.header === table.header)
                      .value === "dsc" ? (
                      <FaAngleDown
                        key={table.header + "4"}
                        size={10}
                        color={"#707070"}
                        onClick={() => onSortChanged("asc", table.header)}
                      />
                    ) : (
                      <div />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div style={{ margin: "30px 0px" }}>
            <InfiniteScroll
              dataLength={letters.length} //This is important field to render the next data
              next={fetchData}
              style={{ overflow: "none" }}
              hasMore={letters.length !== length || loading}
              loader={<h4>Loading...</h4>}
            >
              {letters.map((e) => (
                <div
                  className="table-row-mobile"
                  key={e.id}
                  onClick={(event) => {
                    event.preventDefault();
                    history.push({
                      pathname:
                        year !== undefined
                          ? `/letter/${year}/${e.id}/edit`
                          : `/letter/${e.id}/edit`,
                      state: {
                        data: e.state,
                        id: e.id,
                      },
                    });
                  }}
                >
                  {tableList.map((table) => {
                    let width = `${table.value * 100}px`;
                    return (
                      <div
                        style={{
                          width: width,
                          textAlign: table.align,
                          whiteSpace: "nowrap",
                          display: "inline-block",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {e[table.header]?.replace(/<[^>]*>/g, "")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </div>
      </MobileView>
      {userSettings.letterPermission.create &&
        <div
          className="add_letter"
          style={{ backgroundColor: theme }}
          onClick={(event) => {
            event.preventDefault();
            history.push({
              pathname:
                year !== undefined ? `/letter/${year}/new` : `/letter/new`,
            });
          }}
        >
          <FaPlus className="add_icon" />
        </div>
      }
    </>
  );
};

export default DBTable;
