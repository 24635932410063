import React, { useContext } from "react";
import { IoMdAdd } from "react-icons/io";
import { ColorContext } from "../../AppContext";

function AddMarker({ onClick, show }) {
  const [color] = useContext(ColorContext);
  return (
    <div
      style={{
        width: "67.493px",
        padding: 0,
        margin: "0 0 0 85%",
        cursor: "pointer",
      }}
      onClick={!show ? () => {} : onClick}
    >
      <svg width="67.493" height="57.96" viewBox="0 0 67.493 57.96">
        {show && (
          <defs>
            <filter
              id="Icon_feather-droplet"
              x="0"
              y="0"
              width="67.493"
              height="57.96"
              filterUnits="userSpaceOnUse"
            >
              <feOffset dx="-1" dy="1" input="SourceAlpha" />
              <feGaussianBlur stdDeviation="2" result="blur" />
              <feFlood floodOpacity="0.255" />
              <feComposite operator="in" in2="blur" />
              <feComposite in="SourceGraphic" />
            </filter>
          </defs>
        )}
        {show && (
          <g id="Group_112" data-name="Group 112" transform="translate(7 5)">
            <g id="Group_115" data-name="Group 115">
              <g
                transform="matrix(1, 0, 0, 1, -7, -5)"
                filter="url(#Icon_feather-droplet)"
              >
                <path
                  id="Icon_feather-droplet-2"
                  data-name="Icon feather-droplet"
                  d="M22.966,0,39.224,16.258a22.98,22.98,0,1,1-32.488,0Z"
                  transform="translate(7 50.96) rotate(-90)"
                  fill="#fff"
                />
              </g>
              <path
                id="Icon_material-add"
                data-name="Icon material-add"
                d="M19.78,11.3H11.3V19.78H8.477V11.3H0V8.477H8.477V0H11.3V8.477H19.78Z"
                transform="translate(20.494 32.871) rotate(-90)"
                fill={color}
              />
            </g>
          </g>
        )}
      </svg>
    </div>
  );
}

export default AddMarker;
