import React, { useContext, useEffect, useState } from "react";
import "./SideBar.css";
import "./DatabaseButton.css";
import { FiDatabase } from "react-icons/fi";
import { FaFolder, FaFolderOpen } from "react-icons/fa";
import { ColorContext, UserContext } from "../../AppContext";
import hexToRGB from "../../utils/HexToRGB";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { useLocation } from 'react-router-dom'

function TrendingButton({ active, selected, onClick, onCollapsed }) {
  const [color] = useContext(ColorContext);
  const location = useLocation();
  const [year, setYear] = useState();


  return (
    <>
      <Link to="/trending">
        <span
          className="outline-button"
          onClick={onClick}
          style={{
            backgroundColor: active ? hexToRGB(color, 0.19) : "white",
            color: `${active ? color : "#a0a0a0"}`,
            border: `${active ? "none" : "1px solid rgba(112, 112, 112, 0.19)"}`,
            marginBottom: `${active ? "15px" : "null"}`,
          }}
        >
          <FiDatabase
            color={active ? color : "#a0a0a0"}
            size={18}
            style={{ marginRight: "10px" }}
          />
          Trending
        </span>
      </Link>
      {active ? (location.pathname.includes('topics') ? <Link to="/trending/topics">
        <span className="letter-btn" style={{ color: color }}>
          <FaFolderOpen
            color={color}
            size={20}
            style={{ marginRight: "10px" }}
          />
          Topics
        </span>
      </Link> : <Link
        to="/trending/topics"
        onClick={() => {
          setYear((v) => 'topics');
          if (isMobile)
            onCollapsed();
        }}
      >
        <span className="letter-btn">
          <FaFolder
            color={"#a0a0a0"}
            size={18}
            style={{ marginRight: "10px" }}
          />
          Topics
        </span>
      </Link>) : null}
      {active ? (location.pathname.includes('homepage') ? <Link to="/trending/homepage">
        <span className="letter-btn" style={{ color: color }}>
          <FaFolderOpen
            color={color}
            size={20}
            style={{ marginRight: "10px" }}
          />
          Homepage
        </span>
      </Link> : <Link
        to={`/trending/homepage`}
        key={-1}
        onClick={() => {
          setYear((v) => 'homepage');
          if (isMobile)
            onCollapsed();
        }}
      >
        <span className="letter-btn">
          <FaFolder
            color={"#a0a0a0"}
            size={18}
            style={{ marginRight: "10px" }}
          />
          Homepage
        </span>
      </Link>) : null}
    </>
  );
}

export default TrendingButton;
