import React from "react";
import SimpleEditor from "../../SimpleEditor";
import "./Card.css";
import MoreOptions from "./dropdowns/MoreOptions";
function ParagraphCard({
  onDelete,
  onMoveUp,
  onMoveDown,
  value,
  onChanged,
  uid,
}) {
  return (
    <div
      className="article-card"
      style={{
        minHeight: "320px",
        overflowY: "scroll",
        resize: "vertical",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            textAlign: "left",
            padding: "24px",
            lineHeight: 1,
          }}
        >
          Paragraph
        </div>
        <MoreOptions
          onDelete={onDelete}
          onMoveDown={onMoveDown}
          onMoveUp={onMoveUp}
        />
      </div>
      <div
        style={{
          padding: "0 24px 4px 24px",
          height: "80%",
        }} 
      >
        <SimpleEditor
          onFocus={() => {}}
          onBlur={() => {}}
          disabled={false}
          label="Paragraph"
          width="100%"
          height="80%"
          index={1}
          uid={uid}
          text={value}
          onChange={(html) => onChanged(html)}
        />
      </div>
    </div>
  );
}

export default ParagraphCard;
