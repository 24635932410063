function hexToRGB(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  } else {
    return "rgb(" + r + ", " + g + ", " + b + ")";
  }
}
function hexToRGBAfterOpactiy(hex, alpha) {
  var r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);
  const afterOpacity = [r, g, b].map(
    (colFg, idx) => alpha * colFg + (1 - alpha) * [255, 255, 255][idx]
  );
  return (
    "rgb(" +
    afterOpacity[0] +
    ", " +
    afterOpacity[1] +
    ", " +
    afterOpacity[2] +
    ")"
  );
}

export { hexToRGB as default, hexToRGBAfterOpactiy };
