
import { moduleExpression } from '@babel/types';
import React, { useContext, useEffect } from 'react'
import { Modal } from 'react-bootstrap';
import { FaPlus } from 'react-icons/fa';
import { useHistory } from 'react-router';
import { useLazyReadCypher } from 'use-neo4j';
import { ColorContext } from '../../AppContext';
import hexToRGB from '../../utils/HexToRGB';
import UploadComponent from '../dragdrop/UploadComponent';
import DailyInsightsCard from './card/DailyInsightsCard';
import './DailyInsights.css';
import DailyInsightsEditor from './DailyInsightsEditor';
const DailyInsights = () => {
    const [theme] = useContext(ColorContext);
    const history = useHistory();
    const [dailyInsights, setDailyInsights] = React.useState([]);
    const [dailyInsight, setDailyInsight] = React.useState(null);
    const [getDailyInsights] = useLazyReadCypher(
        `Match (n:DailyInsights) return n`
    );
    const getData = () => {
        getDailyInsights().then(res => {
            const parsedData = res.records.map((e) => {
                return {
                    ...e.get("n").properties,
                    id: e.get('n').identity.toNumber(),
                    state: e.get('n').properties,
                };
            });
            console.log(parsedData);
            setDailyInsights(parsedData);
        });
    }

    const onAdd = ( value, isNew) => {
        var newDailyInsight = {
            ...value.properties,
            id: value.identity.toNumber(),
            state: value.properties,
        };
        setDailyInsights((val) => {
            if (isNew) {
                return [...val, newDailyInsight];
            } else {
                return val.map(e => {
                    if (e.id === newDailyInsight.id) {
                        return newDailyInsight;
                    } else {
                        return e;
                    }
                });
            }
        });
    }
    const onDelete = (id) => {
        setDailyInsights(val => val.filter(e => e.id !== id));
    }

    useEffect(() => {
        getData();
    }, []);
    return (
        <div>
            <div className="daily-insights-grid">
                {dailyInsights.map((e, index) => <DailyInsightsCard key={index} dailyInsight={e} onDeleted={onDelete} onEdited={() => setDailyInsight(e)} />)}
            </div>
            {
                <div
                    className="add_letter"
                    style={{ backgroundColor: theme }}
                    onClick={(event) => {
                        setDailyInsight({
                            title: '',
                            imageUrl: '',
                            link: '',
                            published: new Date().toISOString(),
                        });
                    }}
                >
                    <FaPlus className="add_icon" />
                </div>
            }
            {dailyInsight && <DailyInsightsEditor dailyInsight={dailyInsight} onHide={() => setDailyInsight(null)} onCompleted={onAdd} />}
        </div>
    )
}

export default DailyInsights
