/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from "react";
import { ColorContext, UserContext } from "../../AppContext";
import { IoMdOptions } from "react-icons/io";
import "../DBTable.css";
import { BiExport } from 'react-icons/bi';
import hexToRGB from "../../utils/HexToRGB";
import { useLazyReadCypher, useReadCypher } from "use-neo4j";
import { Menu } from "@material-ui/core";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useParams } from "react-router";
import { BrowserView, MobileView } from "react-device-detect";
import { HebrewSearchQueryContext, HebrewSortStateContext, } from "../../FieldStateContext";
import { FaAngleDown, FaAngleUp, FaPlus } from "react-icons/fa";
import { v4 } from "uuid";
import { get, set } from "local-storage";
import { Link } from "react-router-dom";
import * as XLSX from 'xlsx';
import { auth } from "../../services/firebaseServices";

const HebrewDBTable = () => {
    const history = useHistory();
    const { volume } = useParams();
    const [theme] = useContext(ColorContext);
    const [userSettings] = useContext(UserContext);
    const [progress, setProgress] = useState(false);
    const [orderBy, setOrderBy] = useState(
        "order by n.vol"
    );
    const [page, setPage] = useState(0);
    const [length, setLength] = useState(0);
    const [letters, setLetters] = useState([]);
    const [query] = useContext(HebrewSearchQueryContext);
    const [getLetter, { loading, records }] = useLazyReadCypher(
        `Match (n:Letter) ${volume ? `where n.vol = "${volume}"` : ""} ${query !== "" ? `Match(n) where ${query}` : ""} return n ${orderBy} skip ${page * 50} limit 50 `
    );
    const [getExcelData,] = useLazyReadCypher(
        `Match (n:Letter) ${volume ? `where n.vol = "${volume}"` : ""} ${query !== "" ? `Match(n) where ${query}` : ""} return n ${orderBy}`
    );
    const { first, run } = useReadCypher(
        `MATCH (n:Letter) ${volume ? `where n.vol = "${volume}"` : ""} ${query !== "" ? `Match(n) where ${query}` : ""}  RETURN count(n)`
    );

    useEffect(() => {
        if (first !== undefined) {
            setLength(first.get("count(n)").toNumber());
        }
    }, [first]);

    useEffect(() => {
        if (page !== 0) getData();
        if (orderBy !== "" && page === 0) getData(true);
        if (query !== "" && page === 0) {
            getData(true);
            run();
        }
    }, [page, orderBy, query]);

    useEffect(() => {
        setPage(0);
        if (query === "") {
            getData(true);
        }
    }, [query]);

    function getData(sorted) {
        if (sorted) {
            setLetters([]);
        }
        let result;
        getLetter().then((val) => {
            const res = val.records.map((e) => {
                return {
                    ...e.get('n').properties,
                    ...{
                        id: e.get('n').identity.toNumber(),
                        state: e.get('n').properties,
                    },
                };
            });
            result = res;
            setLetters((val) => [
                ...(sorted ? [] : val),
                ...result.map((e) => {
                    return {
                        id: e.id,
                        Idx: e.idx,
                        "Igroys City": e.Igroys_city,
                        "Igroys Signature": e.Igroys_signature,
                        "Book Page": e.book_page,
                        "Igroys Address": e.Igroys_address,
                        "Igroys Text": e.Igroys_text,
                        "Page Number": e.Page_number,
                        "Igroys Comment": e.Igroys_comment,
                        Summary: e.summary,
                        volume: e.vol,
                        "Igroys Bsd": e.Igroys_bsd,
                        state: e.state,
                    };
                }),
            ]);
        });
    }

    async function handleExport() {
        setProgress(true);
        getExcelData().then((val) => {
            console.log(val);
            console.log('done');
            var data = val.records.map((e) => {
                return {
                    ...e.get('n').properties,
                    id: e.get('n').identity.toNumber(),
                };
            });
            console.log('data', data);
            var results = data.map((e) => {
                return {
                    id: e.id,
                    Idx: e.idx,
                    "Igroys City": e.Igroys_city,
                    "Igroys Signature": e.Igroys_signature,
                    "Book Page": e.book_page,
                    "Igroys Address": e.Igroys_address,
                    "Igroys Text": e.Igroys_text,
                    "Page Number": e.Page_number,
                    "Igroys Comment": e.Igroys_comment,
                    Summary: e.summary,
                    volume: e.vol,
                    "Igroys Bsd": e.Igroys_bsd,
                };
            })
            var ws = XLSX.utils.json_to_sheet(results);
            var wb = XLSX.utils.book_new();
            XLSX.utils.book_append_sheet(wb, ws, "Hebrew Letters");
            XLSX.writeFile(wb, "export.xlsx");
            setProgress(false);
        });
    }

    function getOrderByStatments(header, value) {
        let order = "";
        if (value === "dsc") {
            order = "desc";
        }
        switch (header) {
            case "volume":
                return "order by toInteger(n.vol) " + order;
            case "Page Number":
                return "order by n.Page_number " + order;
            case "Book Page":
                return "order by n.book_page " + order;
            case "Igroys Bsd":
                return "order by n.Igroys_bsd " + order;
            case "Igroys City":
                return "order by n.Igroys_city " + order;
            case "Igroys Address":
                return "order by n.Igroys_address " + order;
            case "Igroys Text":
                return "order by n.Igroys_text " + order;
            case "Igroys Signature":
                return "order by n.Igroys_signature " + order;
            case "Igroys Comment":
                return "order by n.Igroys_comment " + order;
            case "Summary":
                return "order by n.summary " + order;
            // case "Status":
            //     return "order by n.status " + order;
            default:
                break;
        }
    }

    const tableList = [
        { header: "volume", value: 1 },
        { header: "Page Number", value: 1.5 },
        { header: "Book Page", value: 2 },
        { header: "Igroys Bsd", value: 2 },
        { header: "Igroys City", value: 2 },
        { header: "Igroys Address", value: 2 },
        { header: "Summary", value: 2 },
        { header: "Igroys Text", value: 2 },
        { header: "Igroys Signature", value: 2 },
        { header: "Igroys Comment", value: 2 },
        // { header: "Status", value: 2 },
    ];

    const fetchData = () => {
        setPage((val) => (val += 1));
    };

    const onCheckChanged = async (value, header) => {
        setState((e) =>
            e.map((ele) => {
                if (ele.header === header) {
                    return { header: header, value: value };
                }
                return ele;
            })
        );
    };

    const onHover = async (value, header) => {
        setSortFilter((e) =>
            e.map((ele) => {
                if (ele.header === header) {
                    return { ...ele, ...{ header: header, hover: value } };
                }
                return ele;
            })
        );
    };

    const onSortChanged = async (value, header) => {
        setPage((val) => 0);
        let order = getOrderByStatments(header, value);
        setOrderBy((val) => order);
        setSortFilter((e) =>
            e.map((ele) => {
                if (ele.header === header) {
                    return { ...ele, ...{ header: header, value: value } };
                }
                return { ...ele, ...{ value: "null" } };
            })
        );
    };

    const [sortFilter, setSortFilter] = useContext(HebrewSortStateContext);

    const [state, setState] = useState(
        get("hebrew-letter") ?? [
            { header: "volume", value: true },
            { header: "Page Number", value: true },
            { header: "Igroys Bsd", value: true },
            { header: "Igroys City", value: true },
            { header: "Igroys Signature", value: true },
            { header: "Book Page", value: false },
            { header: "Igroys Address", value: false },
            { header: "Igroys Text", value: false },
            { header: "Igroys Comment", value: false },
            { header: "Summary", value: false },
        ]
    );
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    useEffect(() => {
        set("hebrew-sort", state);
    }, [state]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <BrowserView style={{ width: "100%" }}>
                <div style={{ overflow: "auto" }}>
                    <div className="table-header2">
                        <div
                            className="table-header"
                            style={{ backgroundColor: hexToRGB(theme, 0.05) }}
                        >
                            {tableList.map((table) => {
                                if (state.find((e) => e.header === table.header).value) {
                                    var total = 0;
                                    tableList.forEach((ele) => {
                                        if (state.find((e) => e.header === ele.header).value) {
                                            total += ele.value;
                                        }
                                    });
                                    let width = `${(table.value / total) * 100}%`;
                                    return (
                                        <span
                                            style={{
                                                width: width,
                                                textAlign: table.align,
                                                userSelect: "none",
                                            }}
                                            onClick={() =>
                                                onSortChanged(
                                                    sortFilter.find((e) => e.header === table.header)
                                                        .value === "asc" ||
                                                        sortFilter.find((e) => e.header === table.header)
                                                            .value === "null"
                                                        ? "dsc"
                                                        : "asc",
                                                    table.header
                                                )
                                            }
                                            onMouseOver={() => onHover(true, table.header)}
                                            onMouseOut={() => onHover(false, table.header)}
                                            key={table.header + "0"}
                                        >
                                            {table.header}
                                            {sortFilter.find((e) => e.header === table.header).hover &&
                                                sortFilter.find((e) => e.header === table.header)
                                                    .value === "null" && (
                                                    <FaAngleDown
                                                        key={table.header + "3"}
                                                        size={10}
                                                        color={"#707070"}
                                                        onClick={() => onSortChanged("dsc", table.header)}
                                                    />
                                                )}
                                            {sortFilter.find((e) => e.header === table.header).value ===
                                                "asc" ? (
                                                <FaAngleUp
                                                    key={table.header + "3"}
                                                    size={10}
                                                    color={"#707070"}
                                                    onClick={() => onSortChanged("dsc", table.header)}
                                                />
                                            ) : sortFilter.find((e) => e.header === table.header)
                                                .value === "dsc" ? (
                                                <FaAngleDown
                                                    key={table.header + "4"}
                                                    size={10}
                                                    color={"#707070"}
                                                    onClick={() => onSortChanged("asc", table.header)}
                                                />
                                            ) : (
                                                <div />
                                            )}
                                        </span>
                                    );
                                }
                            })}
                            <div style={{ position: "absolute", right: "2vw" }}>
                                <IoMdOptions
                                    color={theme}
                                    size={20}
                                    aria-label="more"
                                    aria-controls="long-menu-1"
                                    aria-haspopup="true"
                                    onClick={handleClick}
                                />
                                {(auth.currentUser?.email ?? '') === 'dev@osfy.io' ? (progress ? <img src={'https://c.tenor.com/I6kN-6X7nhAAAAAj/loading-buffering.gif'} height={20} width={20} /> : <BiExport
                                    color={theme}
                                    size={20}
                                    style={{ marginLeft: "1vw" }}
                                    onClick={handleExport}
                                />) : <></>}
                                <Menu
                                    id="long-menu-1"
                                    anchorEl={anchorEl}
                                    keepMounted
                                    open={open}
                                    PaperProps={{
                                        style: {
                                            borderRadius: "4px",
                                            border: "1px solid #B7B7B7",
                                            fontSize: "1.6vh",
                                        },
                                    }}
                                    onClose={handleClose}
                                >
                                    {tableList.map((e) => {
                                        return (
                                            <div
                                                className="d-flex align-items-center"
                                                style={{
                                                    padding: "1vh 2vh",
                                                    borderBottom: "1px solid #B7B7B7",
                                                    width: "12vw",
                                                }}
                                                key={e.header + "1"}
                                            >
                                                {" "}
                                                <input
                                                    type="checkbox"
                                                    onChange={(input) =>
                                                        onCheckChanged(input.target.checked, e.header)
                                                    }
                                                    checked={
                                                        state.find((s) => s.header === e.header).value
                                                    }
                                                />
                                                <div style={{ paddingLeft: "10px" }}>{e.header}</div>
                                            </div>
                                        );
                                    })}
                                </Menu>
                            </div>
                        </div>
                    </div>
                    <div style={{ margin: "30px 50px" }}>
                        {<InfiniteScroll
                            dataLength={letters.length} //This is important field to render the next data
                            next={fetchData}
                            hasMore={letters.length !== length || loading}
                            loader={<h4>Loading...</h4>}
                        >
                            {letters.map((e) => (
                                <Link to={
                                    volume !== undefined
                                        ? `/hebrew-letter/${volume}/${e.id}/edit`
                                        : `/hebrew-letter/${e.id}/edit`} target="_blank">
                                    <div
                                        className="table-row"
                                        key={v4()}
                                        onClick={(event) => {
                                            event.preventDefault();
                                            history.push({
                                                pathname:
                                                    volume !== undefined
                                                        ? `/hebrew-letter/${volume}/${e.id}/edit`
                                                        : `/hebrew-letter/${e.id}/edit`,
                                                state: {
                                                    data: e.state,
                                                    id: e.id,
                                                },
                                            });
                                        }}
                                    >
                                        {tableList.map((table) => {
                                            if (state.find((e) => e.header === table.header).value) {
                                                var total = 0;
                                                tableList.forEach((ele) => {
                                                    if (state.find((e) => e.header === ele.header).value) {
                                                        total += ele.value;
                                                    }
                                                });
                                                let width = `${(table.value / total) * 100}%`;
                                                return (
                                                    <div
                                                        style={{
                                                            width: width,
                                                            textAlign: table.align,
                                                            whiteSpace: "nowrap",
                                                            overflow: "hidden",
                                                            textOverflow: "ellipsis",
                                                        }}
                                                    >
                                                        {e[table.header]?.replace(/<[^>]*>/g, "")}
                                                    </div>
                                                );
                                            }
                                        })}
                                    </div>
                                </Link>
                            ))}
                        </InfiniteScroll>}
                    </div>
                </div>
            </BrowserView>
            <MobileView style={{ width: "100%", height: "100%" }}>
                <div style={{ overflow: "auto" }}>
                    <div className="table-header2">
                        <div
                            className="table-header-mobile"
                            style={{ backgroundColor: hexToRGB(theme, 0.05) }}
                        >
                            {tableList.map((table) => {
                                return (
                                    <div
                                        style={{
                                            width: `${table.value * 100}px`,
                                            // textAlign: table.a lign,
                                            display: "inline-block",
                                        }}
                                        key={table.header + "0"}
                                    >
                                        {table.header}
                                        {sortFilter.find((e) => e.header === table.header).hover &&
                                            sortFilter.find((e) => e.header === table.header)
                                                .value === "null" && (
                                                <FaAngleDown
                                                    key={table.header + "3"}
                                                    size={10}
                                                    color={"#707070"}
                                                    onClick={() => onSortChanged("dsc", table.header)}
                                                />
                                            )}
                                        {sortFilter.find((e) => e.header === table.header).value ===
                                            "asc" ? (
                                            <FaAngleUp
                                                key={table.header + "3"}
                                                size={10}
                                                color={"#707070"}
                                                onClick={() => onSortChanged("dsc", table.header)}
                                            />
                                        ) : sortFilter.find((e) => e.header === table.header)
                                            .value === "dsc" ? (
                                            <FaAngleDown
                                                key={table.header + "4"}
                                                size={10}
                                                color={"#707070"}
                                                onClick={() => onSortChanged("asc", table.header)}
                                            />
                                        ) : (
                                            <div />
                                        )}
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div style={{ margin: "30px 0px" }}>
                        <InfiniteScroll
                            dataLength={letters.length} //This is important field to render the next data
                            next={fetchData}
                            style={{ overflow: "none" }}
                            hasMore={letters.length !== length || loading}
                            loader={<h4>Loading...</h4>}
                        >
                            {letters.map((e) => (
                                <div
                                    className="table-row-mobile"
                                    key={e.id}
                                    onClick={(event) => {
                                        event.preventDefault();
                                        history.push({
                                            pathname:
                                                volume !== undefined
                                                    ? `/hebrew-letter/${volume}/${e.id}/edit`
                                                    : `/hebrew-letter/${e.id}/edit`,
                                            state: {
                                                data: e.state,
                                                id: e.id,
                                            },
                                        });
                                    }}
                                >
                                    {tableList.map((table) => {
                                        let width = `${table.value * 100}px`;
                                        return (
                                            <div
                                                style={{
                                                    width: width,
                                                    textAlign: table.align,
                                                    whiteSpace: "nowrap",
                                                    display: "inline-block",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                }}
                                            >
                                                {e[table.header]?.replace(/<[^>]*>/g, "")}
                                            </div>
                                        );
                                    })}
                                </div>
                            ))}
                        </InfiniteScroll>
                    </div>
                </div>
            </MobileView>
            {userSettings.letterPermission.create &&
                <div
                    className="add_letter"
                    style={{ backgroundColor: theme }}
                    onClick={(event) => {
                        event.preventDefault();
                        history.push({
                            pathname:
                                volume !== undefined ? `/hebrew-letter/${volume}/new` : `/hebrew-letter/new`,
                        });
                    }}
                >
                    <FaPlus className="add_icon" />
                </div>
            }
        </>
    );
};

export default HebrewDBTable;
