import React from "react";
import "./Card.css";

import UploadComponent from "../../dragdrop/UploadComponent";
import MoreOptions from "./dropdowns/MoreOptions";

function ImageCard({ onDelete, onMoveUp, onMoveDown, showTitle, value, onChanged }) {
  return (
    <div className="article-card">
      {showTitle && (
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "end",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              fontWeight: "bold",
              fontSize: "20px",
              textAlign: "left",
              padding: "24px",
              paddingBottom: "0px",
              lineHeight: 1,
            }}
          >
            Image
          </div>
          <MoreOptions
            onDelete={onDelete}
            onMoveDown={onMoveDown}
            onMoveUp={onMoveUp}
          />
        </div>
      )}
      <div
        style={{
          paddingTop: `${!showTitle ? "24px" : "0px"}`,
          paddingBottom: "24px",
        }}
      >
        <UploadComponent
          value={value}
          showTitle={showTitle}
          disabled={false}
          onFileLoad={(file) => {}}
          onDeleted={() => {}}
          onUpdated={onChanged}
        />
      </div>
    </div>
  );
}

export default ImageCard;
