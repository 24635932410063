import React, { useContext, useEffect, useState } from "react";
import { ColorContext } from "../../AppContext";
import { hexToRGBAfterOpactiy } from "../../utils/HexToRGB";
import { MdNavigateNext, MdOutlineFolder } from "react-icons/md";
import "./Tags.css";
import { FiTag, FiPlus } from "react-icons/fi";
import { Route, Switch, Link } from "react-router-dom";
import { ReactSortable } from "react-sortablejs";
import { ListItemIcon, ListItemText, Menu, MenuItem } from "@material-ui/core";
import FolderLink from "./icons/FolderLink";
import FolderAdd from "./icons/FolderAdd";
import TagAdd from "./icons/TagAdd";
import TagLink from "./icons/TagLink";
import LinkTagFolderPopup from "./popups/LinkTagFolderPopup";
import EditTagFolder from "./popups/EditFolder";
import { useLazyReadCypher, useLazyWriteCypher } from "use-neo4j";
import { Menu as CMenu, Item, useContextMenu } from "react-contexify";
import "react-contexify/dist/ReactContexify.css";
import { toInteger } from "lodash";

const Tags = ({ match, location, history }) => {
  const isTrending = location?.pathname?.includes("trending");
  const parents = location?.state?.parents;
  const parent_id = match.params.id;
  const [color] = useContext(ColorContext);
  const [popupType, setPopupType] = useState();
  const [categories, setCategories] = useState([]);
  const [tags, setTags] = useState([]);
  const [popupData, setPopupData] = useState({});
  const { show } = useContextMenu({
    id: "context-menu",
  });

  const [_createFolder] = useLazyWriteCypher(
    !parent_id
      ? "CREATE (folder:ETagsFolder $folder) SET folder.order = toInteger($order) return folder"
      : `MATCH(parent:ETagsFolder) WHERE ID(parent) = ${parent_id} CREATE (folder:ETagsFolder $folder)<-[:child {order: toInteger($order)}]-(parent) return folder`
  );
  const [_updateItem] = useLazyWriteCypher(
    `MATCH (item) WHERE ID(item) = $id SET item = $data RETURN item`
  );
  const [_unlink] = useLazyWriteCypher(
    `MATCH(parent:ETagsFolder) WHERE ID(parent) = ${parent_id}  MATCH (item) WHERE ID(item) = $id MATCH(item)<-[c:child]-(parent) DELETE c  RETURN item`
  );
  const [_delete] = useLazyWriteCypher(
    `MATCH (item) WHERE ID(item) = $id DETACH DELETE item`
  );
  const [_createTag] = useLazyWriteCypher(
    `MATCH(parent:ETagsFolder) WHERE ID(parent) = ${parent_id} CREATE (tag:ETag $tag)<-[:child {order: toInteger($order)}]-(parent) return tag`
  );
  const [updateFolderOrder] = useLazyWriteCypher(
    `MATCH(folder:ETagsFolder) WHERE ID(folder) = $folder_id MATCH (folder)<-[relation:child]-() SET relation.order = toInteger($order) RETURN folder`
  );
  const [updateRootOrder] = useLazyWriteCypher(
    `MATCH(folder:ETagsFolder) WHERE ID(folder) = $folder_id SET folder.order = toInteger($order) RETURN folder`
  );
  const [updateTagOrder] = useLazyWriteCypher(
    `MATCH(tag:ETag) WHERE ID(tag) = $tag_id MATCH (tag)<-[relation:child]-() SET relation.order = toInteger($order) RETURN tag`
  );
  const [linkExternal] = useLazyWriteCypher(
    `MATCH(parent:ETagsFolder) WHERE ID(parent) = ${parent_id} MATCH(external) WHERE ID(external) = $external_id CREATE (parent)-[:child {order: toInteger($order)}]->(external) RETURN external`
  );
  const [linkLetter] = useLazyWriteCypher(
    "MATCH(folder:ETagsFolder) WHERE ID(folder) = $id MATCH(letter) WHERE ID(letter) = $letter_id CREATE (folder)-[:connectedLetter]->(letter) RETURN letter"
  );
  const [unlinkLetter] = useLazyWriteCypher(
    "MATCH(folder:ETagsFolder) WHERE ID(folder) = $id MATCH(letter) WHERE ID(letter) = $letter_id MATCH (folder)-[c:connectedLetter]->(letter) DELETE c RETURN letter"
  );
  const [getFolderChildren, {}] = useLazyReadCypher(
    isTrending
      ? `MATCH (children:ETagsFolder {type: "trending"}) RETURN children ORDER BY children.order`
      : !parent_id
      ? `MATCH (children:ETagsFolder {type: "root"}) RETURN children ORDER BY children.order`
      : `MATCH (parent:ETagsFolder) WHERE ID(parent) = ${parent_id} MATCH (parent)-[child:child]->(children) WHERE "ETagsFolder" in labels(children) or "ETag" in labels(children) RETURN children ORDER BY child.order`
  );

  function handleItemClick({ event, props, triggerEvent, data }) {
    switch (data) {
      case "edit":
        setPopupData(props);
        setPopupType(props.type ? "Edit Folder" : "Edit Tag");
        break;
      case "unlink":
        const confirmation = window.confirm("Are You Sure you want to unlink?");
        if (confirmation) {
          _unlink({
            id: props.id,
          }).then((data) => {
            if (props.type) {
              setCategories((categories) =>
                categories.filter((category) => category.id !== props.id)
              );
            } else {
              setTags((tags) => tags.filter((tag) => tag.id !== props.id));
            }
          });
        }
        break;
      case "delete":
        const confirmatio = window.confirm("Are You Sure You want to Delete?");
        if (confirmatio) {
          _delete({
            id: props.id,
          }).then((data) => {
            if (props.type) {
              setCategories((categories) =>
                categories.filter((category) => category.id !== props.id)
              );
            } else {
              setTags((tags) => tags.filter((tag) => tag.id !== props.id));
            }
          });
        }
        break;
    }
  }
  const initData = async () => {
    getFolderChildren().then((res) => {
      let _categories = [],
        _tags = [];
      if (res)
        res.records.map((record) => {
          const data = {
            id: record.get("children").identity.toNumber(),
            ...record.get("children").properties,
          };
          if (record.get("children").labels[0] === "ETagsFolder") {
            _categories = [..._categories, data];
          } else {
            _tags = [..._tags, data];
          }
        });
      setCategories(_categories);
      setTags(_tags);
    });
  };

  function sortData(oldData = [], newData = [], type) {
    const changedData = oldData.filter(
      (val, index) => val.id !== newData[index].id
    );
    changedData.forEach((val) => {
      if (!parent_id) {
        updateRootOrder({
          folder_id: val.id,
          order: newData.map((e) => e.id).indexOf(val.id) + 1,
        });
      } else if (type === "folder") {
        updateFolderOrder({
          folder_id: val.id,
          order: newData.map((e) => e.id).indexOf(val.id) + 1,
        });
      } else {
        updateTagOrder({
          tag_id: val.id,
          order: newData.map((e) => e.id).indexOf(val.id) + 1,
        });
      }
    });

    return newData;
  }

  function createFolder(data) {
    setPopupType(null);

    _createFolder({
      folder: {
        type: isTrending ? "trending" : parent_id ? "regular" : "root",
        ...data,
      },
      order: categories.length + 1,
    }).then((res) => {
      const folder = res.records[0].get("folder");
      setCategories((categories) => [
        ...categories,
        { ...folder.properties, id: folder.identity.toNumber() },
      ]);
      if (isTrending) {
        data.keywords
          .map((e) => toInteger(e))
          .forEach((val) => {
            linkLetter({
              id: folder.identity.toNumber(),
              letter_id: val,
            }).then((res) => {
              console.log(res);
            });
          });
      }
    });
  }
  function createTag(data) {
    setPopupType(null);
    _createTag({
      tag: data,
      order: tags.length + 1,
    }).then((res) => {
      const tag = res.records[0].get("tag");
      setTags((tags) => [
        ...tags,
        { ...tag.properties, id: tag.identity.toNumber() },
      ]);
    });
  }
  function updateFolder(id, data) {
    setPopupType(null);
    _updateItem({
      id: id,
      data: data,
    }).then((res) => {
      const category = res.records[0].get("item");
      setCategories((categories) => {
        if (data.type == "trending") {
          data.keywords
            .filter(
              (x) => !categories.find((e) => e.id == id).keywords.includes(x)
            )
            .forEach((val) => {
              linkLetter({
                id: category.identity.toNumber(),
                letter_id: toInteger(val),
              });
            });
          categories
            .find((e) => e.id == id)
            .keywords.filter((x) => !data.keywords.includes(x))
            .forEach((val) => {
              unlinkLetter({
                id: category.identity.toNumber(),
                letter_id: toInteger(val),
              });
            });
        }
        const _categories = categories.map((e) => {
          if (e.id == id) {
            e = category.properties;
          }
          return e;
        });
        return _categories;
      });
    });
  }
  function updateTag(id, data) {
    setPopupType(null);
    _updateItem({
      id: id,
      data: data,
    }).then((res) => {
      const tag = res.records[0].get("item");
      setTags((tags) => {
        const _tags = tags.map((e) => {
          if (e.id == id) {
            e = tag.properties;
          }
          return e;
        });
        return _tags;
      });
    });
  }

  useEffect(() => {
    initData();
  }, []);
  // useEffect(() => {
  //   console.log(categories);
  // }, [categories]);

  return (
    <>
      {match.isExact && (
        <>
          <div
            id="header"
            style={{
              backgroundColor: hexToRGBAfterOpactiy(color, 0.05),
            }}
          >
            <div
              className="header-text"
              onClick={() => parents && history.go(-parents.length)}
            >
              Topics
            </div>

            {parents &&
              parents.map((parent, i) => (
                <>
                  <MdNavigateNext color="#707070" size={24} />
                  <div
                    className="header-text"
                    onClick={() =>
                      i + 1 != parents.length &&
                      history.go(i - parents.length + 1)
                    }
                  >
                    {parent.name}
                  </div>
                </>
              ))}
          </div>
          <ReactSortable
            list={categories}
            setList={(data) =>
              setCategories(sortData(categories, data, "folder"))
            }
            animation={200}
            ghostClass="ghost"
            delay={400}
          >
            {categories.map((value, index) =>
              value.type == "trending" ? (
                <div
                  key={value.id}
                  className="tile"
                  onContextMenu={(e) =>
                    show(e, {
                      props: {
                        ...value,
                      },
                    })
                  }
                >
                  <MdOutlineFolder color="#707071" size={24} />
                  <div className="tile-text">{value.name}</div>
                </div>
              ) : (
                <Link
                  key={value.id}
                  to={{
                    pathname: `${match.url}/${value.id}`,
                    state: {
                      parents: parents ? [...parents, value] : [value],
                    },
                  }}
                  className="tile"
                  onContextMenu={(e) =>
                    show(e, {
                      props: {
                        ...value,
                      },
                    })
                  }
                >
                  <MdOutlineFolder color="#707071" size={24} />
                  <div className="tile-text">{value.name}</div>
                </Link>
              )
            )}
          </ReactSortable>
          <ReactSortable
            list={tags}
            setList={(data) => setTags(sortData(tags, data, "tag"))}
            animation={200}
            ghostClass="ghost"
            delay={400}
          >
            {tags.map((value, index) => (
              <div
                className="tile"
                key={index}
                onContextMenu={(e) =>
                  show(e, {
                    props: {
                      ...value,
                    },
                  })
                }
              >
                <FiTag color="#707071" size={24} />
                <div className="tile-text">{value.name}</div>
              </div>
            ))}
          </ReactSortable>
        </>
      )}
      {popupType === "Add Folder" ? (
        <EditTagFolder
          isNew={true}
          isTrending={isTrending}
          isRoot={!parent_id}
          onSave={createFolder}
          onCancel={() => setPopupType(null)}
        />
      ) : popupType === "Edit Folder" ? (
        <EditTagFolder
          isNew={false}
          isTrending={isTrending}
          isRoot={!parent_id}
          data={popupData}
          onSave={(data) => updateFolder(popupData.id, data)}
          onCancel={() => setPopupType(null)}
        />
      ) : parent_id && popupType === "Link Folder" ? (
        <LinkTagFolderPopup
          value={categories.map((e) => e.id)}
          onCancel={() => setPopupType(null)}
          onSave={(selected) => {
            setPopupType(null);
            selected
              .filter((x) => !categories.map((e) => e.id).includes(x))
              .forEach((val) => {
                linkExternal({
                  external_id: val,
                  order: categories.length + 1,
                }).then((res) => {
                  const external = res.records[0].get("external");
                  setCategories((categories) => [
                    ...categories,
                    {
                      ...external.properties,
                      id: external.identity.toNumber(),
                    },
                  ]);
                });
              });
            categories
              .map((e) => e.id)
              .filter((x) => !selected.includes(x))
              .forEach((val) => {
                _unlink({
                  id: val,
                }).then((res) => {
                  const external = res.records[0].get("item");
                  setCategories((categories) =>
                    categories.filter(
                      (e) => e.id !== external.identity.toNumber()
                    )
                  );
                });
              });
          }}
        />
      ) : popupType === "Add Tag" ? (
        <EditTagFolder
          isNew={true}
          isTag={true}
          isRoot={!parent_id}
          onSave={createTag}
          onCancel={() => setPopupType(null)}
        />
      ) : popupType === "Edit Tag" ? (
        <EditTagFolder
          isNew={false}
          isTag={true}
          isRoot={!parent_id}
          data={popupData}
          onSave={(data) => updateTag(popupData.id, data)}
          onCancel={() => setPopupType(null)}
        />
      ) : (
        popupType === "Link Tag" && (
          <LinkTagFolderPopup
            value={tags.map((e) => e.id)}
            isTag={true}
            onCancel={() => setPopupType(null)}
            onSave={(selected) => {
              setPopupType(null);
              selected
                .filter((x) => !tags.map((e) => e.id).includes(x))
                .forEach((val) => {
                  linkExternal({
                    external_id: val,
                    order: tags.length + 1,
                  }).then((res) => {
                    const external = res.records[0].get("external");
                    setTags((tags) => [
                      ...tags,
                      {
                        ...external.properties,
                        id: external.identity.toNumber(),
                      },
                    ]);
                    if (
                      tags.map((e) => e.id).filter((x) => !selected.includes(x))
                        .length == 0
                    ) {
                    }
                  });
                });
              tags
                .map((e) => e.id)
                .filter((x) => !selected.includes(x))
                .forEach((val) => {
                  _unlink({
                    id: val,
                  }).then((res) => {
                    const external = res.records[0].get("item");
                    setTags((tags) =>
                      tags.filter((e) => e.id !== external.identity.toNumber())
                    );
                  });
                });
            }}
          />
        )
      )}
      <AddTag onClick={setPopupType} showTagOptions={parent_id != null} />
      <CMenu id={"context-menu"}>
        <Item onClick={handleItemClick} data={"edit"}>
          Edit
        </Item>
        {!isTrending && (
          <Item onClick={handleItemClick} data={"unlink"}>
            Unlink
          </Item>
        )}
        <Item onClick={handleItemClick} data={"delete"}>
          Delete
        </Item>
      </CMenu>
      <Switch>
        <Route path={`${match.path}/:id`} component={Tags} />
      </Switch>
    </>
  );
};
export default Tags;

function AddTag({ onClick, showTagOptions = true }) {
  const [color] = useContext(ColorContext);

  const [anchorEl, open] = React.useState(null);
  const handleClick = (event) => {
    open(event.currentTarget);
  };

  const handleClose = (type) => {
    if (type) {
      onClick(type);
    }
    open(null);
  };
  return (
    <div>
      <div
        className="add_letter"
        style={{ backgroundColor: color }}
        onClick={handleClick}
      >
        <FiPlus className="add_icon" />
      </div>
      <Menu
        id="Menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleClose("Add Folder")}>
          <ListItemIcon style={{ minWidth: "0", paddingRight: "15px" }}>
            <FolderAdd />
          </ListItemIcon>
          <span className="options-title">Add Folder</span>
        </MenuItem>
        {showTagOptions && (
          <MenuItem onClick={() => handleClose("Link Folder")}>
            <ListItemIcon style={{ minWidth: "0", paddingRight: "15px" }}>
              <FolderLink />
            </ListItemIcon>
            <span className="options-title">Link Folder</span>
          </MenuItem>
        )}
        {showTagOptions && (
          <MenuItem onClick={() => handleClose("Add Tag")}>
            <ListItemIcon style={{ minWidth: "0", paddingRight: "15px" }}>
              <TagAdd />
            </ListItemIcon>
            <span className="options-title">Add Tag</span>
          </MenuItem>
        )}
        {showTagOptions && (
          <MenuItem onClick={() => handleClose("Link Tag")}>
            <ListItemIcon style={{ minWidth: "0", paddingRight: "15px" }}>
              <TagLink />
            </ListItemIcon>
            <span className="options-title">Link Tag</span>
          </MenuItem>
        )}
      </Menu>
    </div>
  );
}
