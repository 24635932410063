import { useContext } from "react";
import { Modal } from "react-bootstrap"
import { isMobile } from "react-device-detect";
import { FiAlertCircle } from "react-icons/fi";
import { ColorContext } from "../../AppContext";
import './Visibility.css'

const DeleteConfirm = ({ show, onHide, onClick }) => {
    const [color] = useContext(ColorContext)
    const onDelete = () => {
        onClick();
        onHide();
    }
    return <Modal size="md" show={show} onHide={onHide} onEscapeKeyDown={onHide}
        onExit={onHide}
        centered>
        <Modal.Body style={{ padding: "40px" }}>
            <div className="text-center"><FiAlertCircle color={color} size={80} /></div>
            <div className="text-center"><p style={{ fontSize: 30, textAlign: "center", margin: "20px" }}>Are you sure want to delete it?</p></div>

            <div className="d-flex justify-content-center">
                <button
                    style={{
                        backgroundColor: "rgba(0,0,0,0)",
                        borderColor: "#95989A",
                        color: "#959595",
                        height: "5vh",
                        width: isMobile ? "40vw" : "8vw",
                        margin: "0px 0.8vw",
                    }}
                    className={`footer-button ${isMobile ? "mobile" : ""}`}
                    onClick={onHide}
                >
                    Cancel
            </button>
                <button
                    style={{
                        backgroundColor: color,
                        color: "#fff",
                        border: "1px solid rgba(0,0,0,0)",
                        height: "5vh",
                        width: isMobile ? "40vw" : "8vw",
                        margin: "0px 0.8vw",
                    }}
                    className={`footer-button ${isMobile ? "mobile" : ""}`}
                    onClick={onDelete}
                >
                    Ok
            </button>
            </div>
        </Modal.Body>
    </Modal>
}

export default DeleteConfirm;