let months = [
  { "x": "x"},
  { "01": "Tishrei" },
  { "02": "Cheshvan" },
  { "03": "Kislev" },
  { "04": "Teves" },
  { "05": "Shevat" },
  { "06": "Adar" },
  { "06a": "Adar I" },
  { "06b": "Adar II" },
  { "07": "Nissan" },
  { "08": "Iyar" },
  { "09": "Sivan" },
  { 10: "Tammuz" },
  { 11: "Av" },
  { 12: "Elul" },
];

export default months;
