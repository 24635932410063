import React, { useContext, useEffect, useState } from 'react'
import Editor from "../Editor.js";
import { Modal, ProgressBar } from 'react-bootstrap';
import { isMobile } from 'react-device-detect';
import { useHistory, useLocation, useParams } from 'react-router';
import { useLazyWriteCypher } from 'use-neo4j';
import { ColorContext } from '../../AppContext';
import { uploadFileToStorage } from '../../services/firebaseServices';
import hexToRGB from '../../utils/HexToRGB';
import './Visibility.css'

const Visiblity = ({ display, onHide, path, files, onAdd, file, letterId, onEdit }) => {


    const [color,] = useContext(ColorContext);
    const [completed, setCompleted] = useState(0)
    const [uploading, setUploading] = useState(false)
    const [visible, setVisible] = useState("private")
    const [fileName, setFileName] = useState(file?.file ?? files[0].name);
    const [note, setNote] = useState(file?.note??"");

    const [createEFile, { loading, first, cypher }] = useLazyWriteCypher(
        `MATCH (n:ELetter) where ID(n) = ${letterId} CREATE (efile:EFile $params), (efile)-[:file]->(n) RETURN ID(efile)`
    );

    const onExit = () => {
        onHide()
    };

    const onClick = (val) => {
        setVisible(val);
    }

    useEffect(() => {
        if (file !== undefined) {
            setVisible(file.visible)
        }
    }, [])

    const onSave = async () => {
        if (file !== undefined) {
            file.file = fileName;
            file.note = note;
            onEdit({ ...file, ...{ visible: visible } });
            onExit();
        } else {
            if (letterId) {
                setUploading(true)
                uploadFileToStorage(
                    path, files, (val) => console.log(val), (downloadUrl, uuid, file) => {
                        createEFile({
                            params: {
                                visible: visible, downloadUrl: downloadUrl, uuid: uuid, file: fileName, note: note, type: file.type
                            }
                        }).then(val => {
                            let url = { visible: visible, downloadUrl: downloadUrl, uuid: uuid, id: val.records[0]._fields[0].toNumber(), type: file.type, file:  fileName, note: note};
                            onAdd(url);
                            setCompleted(val => val + 1)
                        }
                        )
                    },
                    () => {
                        setUploading(false)
                        onExit()
                    }
                )
            } else {
                setUploading(true)
                for (let index = 0; index < files.length; index++) {
                    const file = files[index];
                    let url = { visible: visible, downloadUrl: '', uuid: '', id: '', type: file.type, file: fileName, buffer: file };
                    onAdd(url);
                    setCompleted(val => val + 1)
                }
                setUploading(false)
                onExit()
            }
        }
    }

    return (

        <>

            <Modal show={display} size="md" onHide={onExit} onExit={onExit} onEscapeKeyDown={onExit} centered>
                <Modal.Body style={{ paddingTop: "20px", borderRadius: "10px" }}>
                    {
                    /* <Editor
                        // onFocus={() => {
                        //     setFocused(true);
                        // }}
                        // onBlur={() => {
                        //     setFocused(false);
                        // }}
                        // disabled={!userSettings.letterPermission.edit}
                        label="File Name"
                        width="100%"
                        height="8.0vh"
                        index={0}
                        text={file?.file ?? files[0].name}
                        onChange={(html) => {
                            // setState((state) => ({
                            //     ...state,
                            //     fileName: html,
                            // }));
                        }}

                    /> */}
                      <fieldset>
                        <legend>File Name</legend>
                        <input
                            // onFocus={() => {
                            //   setFocused(true);
                            // }}
                            // onBlur={() => {
                            //   setFocused(false);
                            // }}
                            type="text"
                            value={fileName}
                            className="field-modal"
                            // style={{ height: "10vh"}}
                        onChange={(e) => {
                            setFileName(e.target.value);
                            // console.log(fileName);
                        }}
                        />
                    </fieldset>
                    <fieldset>
                        <legend>Note</legend>
                        <textarea
                            // onFocus={() => {
                            //   setFocused(true);
                            // }}
                            // onBlur={() => {
                            //   setFocused(false);
                            // }}
                            type="text"
                            // value={file?.file ?? files[0].name}
                            className="field-modal"
                            value={note}
                            rows="4" 
                            cols="30"
                            onChange={(e) => {
                                setNote(e.target.value);
                                // console.log(note);
                            }}
                        // onChange={(e) => {
                        //   setState((state) => ({
                        //     ...state,
                        //     year: e.target.value,
                        //   }));
                        // }}
                        />
                    </fieldset>
                    {/* <Editor

                        // onFocus={() => {
                        //     setFocused(true);
                        // }}
                        // onBlur={() => {
                        //     setFocused(false);
                        // }}
                        // disabled={!userSettings.letterPermission.edit}
                        label="Note"
                        width="100%"
                        height="18vh"
                        index={0}
                    // text={state?.source}
                    // onChange={(html) => {
                    //     setState((state) => ({
                    //         ...state,
                    //         source: html,
                    //     }));
                    // }}
                    /> */}
                    <button className="visibility-button visibility-button-special" style={visible === "private" ? { borderColor: `${color}`, background: hexToRGB(color, 0.08) } : {}} onClick={e => onClick("private")}>
                        <p style={visible === "private" ? { color: `${color}` } : {}} className="header-text-special">Private Upload</p>
                        {/* <p>Integer eleifend odio at iaculis ornare. Maecenas sem lacus, ultrices eget suscipit sed, semper vel enim. Quisque at sem magna. Phasellus consectetur ultrices nunc, ac suscipit enim. Nam sed dacerat blandit sem quis, congue lectus.</p> */}
                    </button>
                    <button className="visibility-button visibility-button-special" style={visible === "public" ? { borderColor: `${color}`, background: hexToRGB(color, 0.08) } : {}} onClick={e => onClick("public")}>
                        <p style={visible === "public" ? { color: `${color}` } : {}} className="header-text-special">Public Upload</p>
                        {/* <p>Integer eleifend odio at iaculis ornare. Maecenas sem lacus, ultrices eget suscipit sed, semper vel enim. Quisque at sem magna. Phasellus consectetur ultrices nunc, ac suscipit enim. Nam sed dacerat blandit sem quis, congue lectus.</p> */}
                    </button>
                    <div className="footer-style footer-style-special">
                        <button className={`footer-button ${isMobile ? "mobile" : ""}`} style={{ background: "transparent", borderColor: "#95989A", color: "#959595" }} onClick={onExit}>Cancel</button>
                        <button className={`footer-button ${isMobile ? "mobile" : ""}`} style={{ background: `${color}`, color: "#fff" }} onClick={onSave}>Upload</button>
                    </div>
                </Modal.Body>
            </Modal>
            {uploading && <Modal show={uploading} centered size="sm">
                <Modal.Body >
                    <ProgressBar animated now={completed} max={files.length} />
                    <p style={{ fontSize: "20px", textAlign: "center" }}>{`Uploading ${completed} out of ${files.length}`}</p>
                </Modal.Body>
            </Modal>}
        </>
    )
}

export default Visiblity