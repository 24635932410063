import React from "react";
import { IoMdMore } from "react-icons/io";
import { Menu, MenuItem } from "@material-ui/core";
import AddMarker from "../../AddMarker";
function AddDropDown({ onSelect, show }) {
  const [anchorEl, open] = React.useState(null);
  const handleClick = (event) => {
    open(event.currentTarget);
  };

  const handleClose = (type) => {
    onSelect(type);
    open(null);
  };
  return (
    <div>
      <AddMarker onClick={handleClick} show={show} />
      <Menu
        id="Menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => open(null)}
      >
        <MenuItem onClick={() => handleClose("Title")}>
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "bold",
              fontSize: "15px",
              textAlign: "center",
              color: "#000",
            }}
          >
            Title
          </span>
        </MenuItem>
        <MenuItem onClick={() => handleClose("Paragraph")}>
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "bold",
              fontSize: "15px",
              textAlign: "center",
              color: "#000",
            }}
          >
            Paragraph
          </span>
        </MenuItem>
        <MenuItem onClick={() => handleClose("Image")}>
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "bold",
              fontSize: "15px",
              textAlign: "center",
              color: "#000",
            }}
          >
            Image
          </span>
        </MenuItem>
        <MenuItem onClick={() => handleClose("Card")}>
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "bold",
              fontSize: "15px",
              textAlign: "center",
              color: "#000",
            }}
          >
            Card
          </span>
        </MenuItem>
        <MenuItem onClick={() => handleClose("Meta")}>
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "bold",
              fontSize: "15px",
              textAlign: "center",
              color: "#000",
            }}
          >
            Metadata
          </span>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default AddDropDown;
