import React, { useState } from "react";
import "./Card.css";
import MoreOptions from "./dropdowns/MoreOptions";
import Editor from "../../Editor";
import SimpleEditor from "../../SimpleEditor";

function HeaderCard({ onDelete, onMoveUp, onMoveDown, value, onChanged, uid }) {
  var title = value?.title;
  var subtitle = value?.subtitle;
  return (
    <div className="article-card">
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            textAlign: "left",
            padding: "24px",
            lineHeight: 1,
          }}
        >
          Header
        </div>
        <MoreOptions
          onDelete={onDelete}
          onMoveDown={onMoveDown}
          onMoveUp={onMoveUp}
        />
      </div>
      <div
        style={{
          padding: "0 24px 4px 24px",
        }}
      >
        <SimpleEditor
          onFocus={() => {}}
          onBlur={() => {}}
          disabled={false}
          label="Title"
          width="100%"
          height="45px"
          index={1}
          uid={uid}
          text={title}
          onChange={(html) => {
            title = html;
            onChanged({ title: title, subtitle: subtitle });
          }}
        />
        <SimpleEditor
          onFocus={() => {}}
          onBlur={() => {}}
          label="Subtitle"
          width="100%"
          height="45px"
          index={2}
          uid={uid}
          text={subtitle}
          onChange={(html) => {
            subtitle = html;
            onChanged({ title: title, subtitle: subtitle });
          }}
        />
        {/* // <input
            //   type="text"
            //   style={{
            //     borderRadius: "4px",
            //     color: "black",
            //     padding: "10px",
            //     borderColor: "rgba(112, 112,  112, 0.25)",
            //     width: "100%",
            //     height: "45px",
            //     marginBottom: "20px",
            //   }}
            //   onChange={(e) => {
            //     switch (i) {
            //       case 0:
            //         title = e.target.value;
            //         break;
            //       case 1:
            //         subtitle = e.target.value;
            //         break;
            //     }
            //     return onChanged({
            //       title: title,
            //       subtitle: subtitle,
            //     });
            //   }}
            //   placeholder={i == 0 ? "Title" : "Subtitle"}
            //   value={i == 0 ? value?.title : value?.subtitle}
            // /> */}
      </div>
    </div>
  );
}

export default HeaderCard;
