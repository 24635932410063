import React, { useContext, useEffect, useState } from "react";
import { Item, Menu, useContextMenu } from "react-contexify";
import { FiFolder, FiPlus, FiTag } from "react-icons/fi";
import { ColorContext } from "../../AppContext";
import "./homepage.css";
import "react-contexify/dist/ReactContexify.css";
import HexToRGB from "../../utils/HexToRGB";
import { hexToRgb } from "@material-ui/core";
import LinkArticle from "./popup/LinkArticle";
import { useLazyReadCypher, useLazyWriteCypher } from "use-neo4j";
import LinkLetter from "./popup/LinkLetter";
import LinkTopic from "./popup/LinkTopic";
import { ReactSortable } from "react-sortablejs";
import LinkInsights from "./popup/LinkInsights";
import { deleteFileToStorage } from "../../services/firebaseServices";
import DeleteConfirm from "../dialogs/DeleteConfirm";
import * as Vibrant from "node-vibrant";

const Homepage = () => {
  const [theme] = useContext(ColorContext);
  const [showDialog, setShowDialog] = useState({
    show: false,
    type: "",
  });

  const [lefttrending, setLeftTrending] = useState([]);
  const [righttrending, setRightTrending] = useState([]);

  const [getTrending] = useLazyReadCypher(
    `MATCH (trending: ETrending) RETURN trending`
  );

  const [updateTrendingOrder] = useLazyWriteCypher(
    "MATCH (trending: ETrending) where ID(n) = $id SET trending.order = $order RETURN trending"
  );
  const [getInsights] = useLazyReadCypher(
    "MATCH (n:DailyInsights) return n"
  );
  const [setInsights] = useLazyWriteCypher(
    "MATCH (n:DailyInsights) WHERE ID(n) = $id SET n.color = $color RETURN n"
  );

//   const setColorForInsights = async () => {
//       let i = 1;
//     getInsights().then(async (insights) => {
//       insights.records.forEach(async (insight) => {
//         const id = insight.get("n").identity.toNumber();
//         const color = await Vibrant.from(
//           insight.get("n").properties.imageUrl
//         ).getPalette();
//         const hex = color.DarkVibrant.hex;
//         console.log(i);
//         await setInsights({
//           id: id,
//           color: hex,
//         }).then((res) => {
//             i++
//         });
//       });
//     });
//   };

  const onAddTrending = (trending) => {
    if (trending.column === "1")
      setLeftTrending((val) => {
        return [...val, trending];
      });
    else
      setRightTrending((val) => {
        return [...val, trending];
      });
  };

  function sortData(oldData = [], newData = []) {
    let changedData = oldData.filter(
      (val, index) => val.id !== newData[index].id
    );

    changedData = changedData.map((val, index) => {
      return { ...val, order: `${index + 1}` };
    });

    changedData.forEach((val) => {
      updateTrendingOrder({
        id: val.id,
        order: val.order,
      });
    });

    return newData;
  }

  const onDelete = (trending) => {
    if (trending.column === "1")
      setLeftTrending((val) => val.filter((val) => val.id !== trending.id));
    else setRightTrending((val) => val.filter((val) => val.id !== trending.id));
  };

  const onUpdate = (trending, oldTrending) => {
    if (oldTrending.column !== trending.column) {
      if (oldTrending.column) {
        if (oldTrending.column === "1") {
          setLeftTrending((val) =>
            val.filter((val) => val.id !== oldTrending.id)
          );
        } else {
          setRightTrending((val) =>
            val.filter((val) => val.id !== oldTrending.id)
          );
        }
      }
    }
    if (trending.column === "1")
      setLeftTrending((val) => {
        return val.map((e) => (e.id === trending.id ? trending : e));
      });
    else
      setRightTrending((val) => {
        return val.map((e) => (e.id === trending.id ? trending : e));
      });
  };

  useEffect(() => {
    getTrending().then((res) => {
      var trending = res.records.map((record) => {
        return {
          ...record.get("trending").properties,
          id: record.get("trending").identity.toNumber(),
        };
      });
      setLeftTrending(trending.filter((val) => val.column === "1"));
      setRightTrending(trending.filter((val) => val.column === "2"));
    });
  }, []);
  return (
    <div>
      <div
        className="heading"
        style={{ backgroundColor: HexToRGB(theme, 0.05) }}
      >
        <div style={{ flex: 1, paddingLeft: "2vw" }}>Trending Homepage</div>
        <div style={{ flex: 1, paddingLeft: "2vw" }}>Trending Homepage</div>
      </div>
      <div style={{ display: "flex", width: "100%", height: "81vh" }}>
        <div style={{ borderRight: "1px solid #70707041", flex: 1 }}>
          <ReactSortable
            list={lefttrending}
            setList={(data) => setLeftTrending(sortData(lefttrending, data))}
            animation={200}
            ghostClass="ghost"
            delay={400}
          >
            {lefttrending
              .sort((a, b) => parseInt(a.order) - parseInt(b.order))
              .map((trend) => {
                return (
                  <TrendingTile
                    trending={trend}
                    onRemove={onDelete}
                    onUpdate={onUpdate}
                    order={[
                      `${lefttrending.length + 1}`,
                      `${righttrending.length + 1}`,
                    ]}
                  />
                );
              })}
          </ReactSortable>
        </div>
        <div style={{ flex: 1 }}>
          <ReactSortable
            list={righttrending}
            setList={(data) => setRightTrending(sortData(righttrending, data))}
            animation={200}
            ghostClass="ghost"
            delay={400}
          >
            {righttrending
              .sort((a, b) => parseInt(a.order) - parseInt(b.order))
              .map((trend) => {
                return (
                  <TrendingTile
                    trending={trend}
                    onRemove={onDelete}
                    onUpdate={onUpdate}
                    order={[
                      `${lefttrending.length + 1}`,
                      `${righttrending.length + 1}`,
                    ]}
                  />
                );
              })}
          </ReactSortable>
        </div>
      </div>
      <AddTag
        onClick={(type) => {
          setShowDialog((val) => {
            return { show: true, type: type };
          });
        }}
      />
      {showDialog.show &&
        (showDialog.type === "Article" ? (
          <LinkArticle
            onHide={() => {
              setShowDialog((val) => {
                return { show: false };
              });
            }}
            onSave={onAddTrending}
            order={[
              `${lefttrending.length + 1}`,
              `${righttrending.length + 1}`,
            ]}
          />
        ) : showDialog.type === "Letter" ? (
          <LinkLetter
            onHide={() => {
              setShowDialog((val) => {
                return { show: false };
              });
            }}
            onSave={onAddTrending}
            order={[
              `${lefttrending.length + 1}`,
              `${righttrending.length + 1}`,
            ]}
          />
        ) : showDialog.type === "Topic" ? (
          <LinkTopic
            onHide={() => {
              setShowDialog((val) => {
                return { show: false };
              });
            }}
            onSave={onAddTrending}
            order={[
              `${lefttrending.length + 1}`,
              `${righttrending.length + 1}`,
            ]}
          />
        ) : showDialog.type === "Insights" ? (
          <LinkInsights
            onHide={() => {
              setShowDialog((val) => {
                return { show: false };
              });
            }}
            onSave={onAddTrending}
            order={[
              `${lefttrending.length + 1}`,
              `${righttrending.length + 1}`,
            ]}
          />
        ) : (
          <div />
        ))}
    </div>
  );
};
function AddTag({ onClick, showTagOptions = true }) {
  const [color] = useContext(ColorContext);

  const { show } = useContextMenu({
    id: "menu-id",
  });

  function handleItemClick({ event, props, triggerEvent, data }) {
    onClick(data);
    console.log(event, props, triggerEvent, data);
  }

  function displayMenu(e) {
    // put whatever custom logic you need
    // you can even decide to not display the Menu
    show(e);
  }

  return (
    <div>
      <div
        className="add_letter"
        style={{ backgroundColor: color }}
        onClick={displayMenu}
      >
        <FiPlus className="add_icon" />
      </div>
      <Menu id={"menu-id"}>
        <Item onClick={handleItemClick} data={"Article"}>
          <span className="options-title">Article</span>
        </Item>
        <Item onClick={handleItemClick} data={"Topic"}>
          <span className="options-title">Topic</span>
        </Item>
        <Item onClick={handleItemClick} data={"Letter"}>
          <span className="options-title">Letter</span>
        </Item>
        <Item onClick={handleItemClick} data={"Insights"}>
          <span className="options-title">Insights</span>
        </Item>
      </Menu>
    </div>
  );
}

function TrendingTile({ trending, onRemove, onUpdate, order }) {
  const { show } = useContextMenu({
    id: trending.id + "-menu",
  });

  const [deleteShow, setDeleteShow] = useState(false);
  const [updateShow, setUpdateShow] = useState(false);

  const [deleteTrending] = useLazyWriteCypher(
    `MATCH (n:ETrending) WHERE ID(n) = ${trending.id} DETACH DELETE n`
  );

  async function onDelete() {
    await deleteTrending().then(console.log);
    await deleteFileToStorage(
      `${
        trending.imageUrl
          .split("english-letters.appspot.com/o/")[1]
          .replaceAll("%2F", "/")
          .split("?")[0]
      }`
    );
    onRemove(trending);
  }

  return (
    <div>
      <div className="tile" key={trending.id} onContextMenu={show}>
        <FiFolder color="#707071" size={24} />
        <div className="tile-text">{trending.name}</div>
      </div>
      <Menu id={`${trending.id}-menu`}>
        <Item
          onClick={() => {
            setUpdateShow(true);
          }}
        >
          <span className="options-title">Edit</span>
        </Item>
        <Item
          onClick={() => {
            setDeleteShow(true);
          }}
        >
          <span className="options-title">Delete</span>
        </Item>
      </Menu>
      {deleteShow && (
        <DeleteConfirm
          onClick={onDelete}
          onHide={() => setDeleteShow(false)}
          show={deleteShow}
        />
      )}
      {updateShow &&
        (trending.type === "article" ? (
          <LinkArticle
            onHide={() => setUpdateShow(false)}
            onSave={(newTrend) => onUpdate(newTrend, trending)}
            order={order}
            data={trending}
          />
        ) : trending.type === "letter" ? (
          <LinkLetter
            onHide={() => setUpdateShow(false)}
            onSave={onUpdate}
            order={order}
            data={trending}
          />
        ) : trending.type === "topic" ? (
          <LinkTopic
            onHide={() => setUpdateShow(false)}
            onSave={onUpdate}
            order={order}
            data={trending}
          />
        ) : trending.type === "insights" ? (
          <LinkInsights
            onHide={() => setUpdateShow(false)}
            onSave={onUpdate}
            order={order}
            data={trending}
          />
        ) : (
          <div />
        ))}
    </div>
  );
}

export default Homepage;
