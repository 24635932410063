import { Menu } from "@material-ui/core";
import { get } from "jquery";
import { set } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { BrowserView, MobileView } from "react-device-detect";
import { FaAngleDown, FaAngleUp, FaPlus } from "react-icons/fa";
import { IoMdOptions } from "react-icons/io";
import InfiniteScroll from "react-infinite-scroll-component";
import { useHistory, useParams } from "react-router";
import { Link } from "react-router-dom";
import { useLazyReadCypher, useReadCypher } from "use-neo4j";
import { v4 } from "uuid";
import { ColorContext, UserContext } from "../../AppContext";
import {
  ArticleSearchQueryContext,
  ArticleSortStateContext,
} from "../../FieldStateContext";
import hexToRGB from "../../utils/HexToRGB";

function ArticleTable() {
  const data = JSON.stringify([
    {
      type: "Image",
      uid: v4(),
      required: true,
    },
    {
      type: "Header",
      uid: v4(),
      required: true,
    },
  ]);
  const history = useHistory();
  const [theme] = useContext(ColorContext);
  const [userSettings] = useContext(UserContext);
  const [page, setPage] = useState(0);
  const [length, setLength] = useState(0);
  const [staticArticles, setStaticArticles] = useState([]);
  const [articles, setArticles] = useState([]);
  const [query] = useContext(ArticleSearchQueryContext);
  const [getEArticles, { loading }] = useLazyReadCypher(
    `Match (n:EArticle) where not ID(n) in [27247,27248] ${query !== "" ? `Match(n) where ${query}` : ""
    } return n skip ${page * 50} limit 50 `
  );
  const { first, run } = useReadCypher(
    `MATCH (n:EArticle) where not ID(n) in [27247,27248] ${query !== "" ? `Match(n) where ${query}` : ""
    }  RETURN count(n)`
  );


  const [getStaticArticles] = useLazyReadCypher(
    `Match (n:EArticle) where ID(n) in [27247,27248] return n`
  )

  useEffect(() => {
    if (first !== undefined) {
      setLength(first.get("count(n)").toNumber());
    }
  }, [first]);

  useEffect(() => {
    if (page !== 0) getData();
    if (query !== "" && page === 0) {
      getData(true);
      run();
    }
  }, [page, query]);

  useEffect(() => {
    setPage(0);
    if (query === "") {
      getData(true);
    }
  }, [query]);

  function getData(sorted) {
    if (sorted) {
      setArticles([]);
    }
    let result;

    getStaticArticles().then((val) => {
      const res = val.records.map((e) => {
        return {
          ...e.get("n").properties,
          id: e.get("n").identity.toNumber(),
          state: JSON.parse(e.get("n").properties.data) || [],
        };
      });
      result = res;
      setStaticArticles((val) => {
        return [...val, ...result.map((e) => {
          return {
            id: e.id,
            Title: Array.from(e.state).filter((e) => e.type === "Header")[0]
              .value.title,
            Subtitle: Array.from(e.state).filter((e) => e.type === "Header")[0]
              .value.subtitle,
            Status: e.status,
            Views: e.views,
            "Date Published": e.published,
            state: e.data,
            status: e.status,
          };
        }),];
      })
    })
    getEArticles().then((val) => {
      const res = val.records.map((e) => {
        return {
          ...e.get("n").properties,
          id: e.get("n").identity.toNumber(),
          state: JSON.parse(e.get("n").properties.data) || [],
        };
      });
      result = res;
      setArticles((val) => [
        ...(sorted ? [] : val),
        ...result.map((e) => {
          return {
            id: e.id,
            Title: Array.from(e.state).filter((e) => e.type === "Header")[0]
              .value.title,
            Subtitle: Array.from(e.state).filter((e) => e.type === "Header")[0]
              .value.subtitle,
            Status: e.status,
            Views: e.views,
            "Date Published": e.published,
            state: e.data,
            status: e.status,
          };
        }),
      ]);
    });
  }

  const tableList = [
    { header: "Title", value: 2 },
    { header: "Subtitle", value: 2 },
    { header: "Status", value: 1 },
    { header: "Views", value: 1 },
    { header: "Date Published", value: 1 },
  ];

  const fetchData = () => {
    setPage((val) => (val += 1));
  };

  const onCheckChanged = async (value, header) => {
    setState((e) =>
      e.map((ele) => {
        if (ele.header === header) {
          return { header: header, value: value };
        }
        return ele;
      })
    );
  };

  const onHover = async (value, header) => {
    setSortFilter((e) =>
      e.map((ele) => {
        if (ele.header === header) {
          return { ...ele, ...{ header: header, hover: value } };
        }
        return ele;
      })
    );
  };

  const onSortChanged = async (value, header) => {
    setPage((val) => 0);
    setArticles((val) =>
      val.sort((a, b) => {
        if (value === "asc") {
          return a[header] > b[header] ? 1 : -1;
        } else {
          return a[header] < b[header] ? 1 : -1;
        }
      })
    );
    setSortFilter((e) =>
      e.map((ele) => {
        if (ele.header === header) {
          return { ...ele, ...{ header: header, value: value } };
        }
        return { ...ele, ...{ value: "null" } };
      })
    );
  };

  const [sortFilter, setSortFilter] = useContext(ArticleSortStateContext);

  const [state, setState] = useState([
    { header: "Title", value: true },
    { header: "Subtitle", value: true },
    { header: "Date Published", value: false },
    { header: "Status", value: true },
    { header: "Views", value: true },
  ]);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  useEffect(() => {
    set("article-sort", state);
  }, [state]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  return (
    <>
      <BrowserView style={{ width: "100%" }}>
        <div style={{ overflow: "auto" }}>
          <div className="table-header2">
            <div
              className="table-header"
              style={{ backgroundColor: hexToRGB(theme, 0.05) }}
            >
              {tableList.map((table) => {
                if (state.find((e) => e.header === table.header).value) {
                  var total = 0;
                  tableList.forEach((ele) => {
                    if (state.find((e) => e.header === ele.header).value) {
                      total += ele.value;
                    }
                  });
                  let width = `${(table.value / total) * 100}%`;
                  return (
                    <span
                      style={{
                        width: width,
                        textAlign: table.align,
                        userSelect: "none",
                      }}
                      onClick={() =>
                        onSortChanged(
                          sortFilter.find((e) => e.header === table.header)
                            .value === "asc" ||
                            sortFilter.find((e) => e.header === table.header)
                              .value === "null"
                            ? "dsc"
                            : "asc",
                          table.header
                        )
                      }
                      onMouseOver={() => onHover(true, table.header)}
                      onMouseOut={() => onHover(false, table.header)}
                      key={table.header + "0"}
                    >
                      {table.header}
                      {sortFilter.find((e) => e.header === table.header)
                        .hover &&
                        sortFilter.find((e) => e.header === table.header)
                          .value === "null" && (
                          <FaAngleDown
                            key={table.header + "3"}
                            size={10}
                            color={"#707070"}
                            onClick={() => onSortChanged("dsc", table.header)}
                          />
                        )}
                      {sortFilter.find((e) => e.header === table.header)
                        .value === "asc" ? (
                        <FaAngleUp
                          key={table.header + "3"}
                          size={10}
                          color={"#707070"}
                          onClick={() => onSortChanged("dsc", table.header)}
                        />
                      ) : sortFilter.find((e) => e.header === table.header)
                        .value === "dsc" ? (
                        <FaAngleDown
                          key={table.header + "4"}
                          size={10}
                          color={"#707070"}
                          onClick={() => onSortChanged("asc", table.header)}
                        />
                      ) : (
                        <div />
                      )}
                    </span>
                  );
                }
              })}
              <div style={{ position: "absolute", right: "2vw" }}>
                <IoMdOptions
                  color={theme}
                  size={20}
                  aria-label="more"
                  aria-controls="long-menu-1"
                  aria-haspopup="true"
                  onClick={handleClick}
                />
                <Menu
                  id="long-menu-1"
                  anchorEl={anchorEl}
                  keepMounted
                  open={open}
                  PaperProps={{
                    style: {
                      borderRadius: "4px",
                      border: "1px solid #B7B7B7",
                      fontSize: "1.6vh",
                    },
                  }}
                  onClose={handleClose}
                >
                  {tableList.map((e) => {
                    return (
                      <div
                        className="d-flex align-items-center"
                        style={{
                          padding: "1vh 2vh",
                          borderBottom: "1px solid #B7B7B7",
                          width: "12vw",
                        }}
                        key={e.header + "1"}
                      >
                        {" "}
                        <input
                          type="checkbox"
                          onChange={(input) =>
                            onCheckChanged(input.target.checked, e.header)
                          }
                          checked={
                            state.find((s) => s.header === e.header).value
                          }
                        />
                        <div style={{ paddingLeft: "10px" }}>{e.header}</div>
                      </div>
                    );
                  })}
                </Menu>
              </div>
            </div>
          </div>
          <div style={{ margin: "30px 50px" }}>
            {
              <InfiniteScroll
                dataLength={articles.length + 2} //This is important field to render the next data
                next={fetchData}
                hasMore={(articles.length + 2) !== (length +2) || loading}
                loader={<h4>Loading...</h4>}
              >
                {staticArticles.map((e) => (
                  <Link to={`/articles/${e.id}`} target="_blank">
                    <div
                      className="table-row"
                      key={v4()}
                      onClick={(event) => {
                        event.preventDefault();
                        history.push({
                          pathname: `/articles/${e.id}`,
                          state: {
                            data: e.state,
                            status: e.status,
                            id: e.id,
                          },
                        });
                      }}
                    >
                      {tableList.map((table) => {
                        if (
                          state.find((e) => e.header === table.header).value
                        ) {
                          var total = 0;
                          tableList.forEach((ele) => {
                            if (
                              state.find((e) => e.header === ele.header).value
                            ) {
                              total += ele.value;
                            }
                          });
                          let width = `${(table.value / total) * 100}%`;
                          return (
                            <div
                              style={{
                                width: width,
                                textAlign: table.align,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {e[table.header]?.replace(/<[^>]*>/g, "")}
                            </div>
                          );
                        }
                      })}
                    </div>
                  </Link>
                ))}
                {articles.map((e) => (
                  <Link to={`/articles/${e.id}`} target="_blank">
                    <div
                      className="table-row"
                      key={v4()}
                      onClick={(event) => {
                        event.preventDefault();
                        history.push({
                          pathname: `/articles/${e.id}`,
                          state: {
                            data: e.state,
                            status: e.status,
                            id: e.id,
                          },
                        });
                      }}
                    >
                      {tableList.map((table) => {
                        if (
                          state.find((e) => e.header === table.header).value
                        ) {
                          var total = 0;
                          tableList.forEach((ele) => {
                            if (
                              state.find((e) => e.header === ele.header).value
                            ) {
                              total += ele.value;
                            }
                          });
                          let width = `${(table.value / total) * 100}%`;
                          return (
                            <div
                              style={{
                                width: width,
                                textAlign: table.align,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {e[table.header]?.replace(/<[^>]*>/g, "")}
                            </div>
                          );
                        }
                      })}
                    </div>
                  </Link>
                ))}
              </InfiniteScroll>
            }
          </div>
        </div>
      </BrowserView>
      <MobileView style={{ width: "100%", height: "100%" }}>
        <div style={{ overflow: "auto" }}>
          <div className="table-header2">
            <div
              className="table-header-mobile"
              style={{ backgroundColor: hexToRGB(theme, 0.05) }}
            >
              {tableList.map((table) => {
                return (
                  <div
                    style={{
                      width: `${table.value * 100}px`,
                      // textAlign: table.a lign,
                      display: "inline-block",
                    }}
                    key={table.header + "0"}
                  >
                    {table.header}
                    {sortFilter.find((e) => e.header === table.header).hover &&
                      sortFilter.find((e) => e.header === table.header)
                        .value === "null" && (
                        <FaAngleDown
                          key={table.header + "3"}
                          size={10}
                          color={"#707070"}
                          onClick={() => onSortChanged("dsc", table.header)}
                        />
                      )}
                    {sortFilter.find((e) => e.header === table.header).value ===
                      "asc" ? (
                      <FaAngleUp
                        key={table.header + "3"}
                        size={10}
                        color={"#707070"}
                        onClick={() => onSortChanged("dsc", table.header)}
                      />
                    ) : sortFilter.find((e) => e.header === table.header)
                      .value === "dsc" ? (
                      <FaAngleDown
                        key={table.header + "4"}
                        size={10}
                        color={"#707070"}
                        onClick={() => onSortChanged("asc", table.header)}
                      />
                    ) : (
                      <div />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div style={{ margin: "30px 0px" }}>
            <InfiniteScroll
              dataLength={articles.length} //This is important field to render the next data
              next={fetchData}
              style={{ overflow: "none" }}
              hasMore={articles.length !== length || loading}
              loader={<h4>Loading...</h4>}
            >
              {staticArticles.map((e) => (
                  <Link to={`/articles/${e.id}`} target="_blank">
                    <div
                      className="table-row"
                      key={v4()}
                      onClick={(event) => {
                        event.preventDefault();
                        history.push({
                          pathname: `/articles/${e.id}`,
                          state: {
                            data: e.state,
                            status: e.status,
                            id: e.id,
                          },
                        });
                      }}
                    >
                      {tableList.map((table) => {
                        if (
                          state.find((e) => e.header === table.header).value
                        ) {
                          var total = 0;
                          tableList.forEach((ele) => {
                            if (
                              state.find((e) => e.header === ele.header).value
                            ) {
                              total += ele.value;
                            }
                          });
                          let width = `${(table.value / total) * 100}%`;
                          return (
                            <div
                              style={{
                                width: width,
                                textAlign: table.align,
                                whiteSpace: "nowrap",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              {e[table.header]?.replace(/<[^>]*>/g, "")}
                            </div>
                          );
                        }
                      })}
                    </div>
                  </Link>
                ))}
              {articles.map((e) => (
                <div
                  className="table-row-mobile"
                  key={e.id}
                  onClick={(event) => {
                    event.preventDefault();
                    history.push({
                      pathname: `/articles/${e.id}`,
                      state: {
                        data: e.state,
                        status: e.status,
                        id: e.id,
                      },
                    });
                  }}
                >
                  {tableList.map((table) => {
                    let width = `${table.value * 100}px`;
                    return (
                      <div
                        style={{
                          width: width,
                          textAlign: table.align,
                          whiteSpace: "nowrap",
                          display: "inline-block",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {e[table.header]?.replace(/<[^>]*>/g, "")}
                      </div>
                    );
                  })}
                </div>
              ))}
            </InfiniteScroll>
          </div>
        </div>
      </MobileView>
      <div
        className="add_letter"
        style={{ backgroundColor: theme }}
        onClick={(event) => {
          event.preventDefault();
          history.push({
            pathname: `/articles/new`,
            state: {
              data: data,
            },
          });
        }}
      >
        <FaPlus className="add_icon" />
      </div>
    </>
  );
}

export default ArticleTable;
//
