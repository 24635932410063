import { parseHTML } from 'jquery';
import React, { useContext, useEffect, useState } from 'react'
import { int, useLazyReadCypher, useLazyWriteCypher } from 'use-neo4j';
import { v4 } from 'uuid';
import { ColorContext } from '../../../AppContext';
import { uploadSingleFileToStorage } from '../../../services/firebaseServices';
import HexToRGB from '../../../utils/HexToRGB';
import DailyInsightsImageUploader from '../../daily_insights/DailyInsightsImageUploader';
import LinkTagFolderPopup from '../../tags/popups/LinkTagFolderPopup';
import './popup.css'

const LinkTopic = ({ onHide, onSave, order, data }) => {
    const [color] = useContext(ColorContext);
    const [state, setState] = useState(data ?? {
        name: '',
        linked: '',
        column: '',
        type: 'topic',
        order: '',
        imageUrl: '',
    })

    const [show, setShow] = useState(false)
    const [disabled, setDisabled] = useState(false)

    const [file, setFile] = useState(null);

    const [searchQuery, setSearchQuery] = useState(data?.linked ?? '');


    const [createTrending] = useLazyWriteCypher(
        `MATCH (n) WHERE ID(n) = $id CREATE (trending: ETrending) SET trending = $data CREATE (trending)-[:source]->(n) RETURN trending`,
    );

    const [deleteRelations] = useLazyWriteCypher(
        `MATCH (n:ETrending) WHERE ID(n) = $id MATCH (n)-[src:source]->(source) delete src`,
    );

    const [updateTrending] = useLazyWriteCypher(
        `MATCH (n) WHERE ID(n) = $articleId MATCH (trending:ETrending) WHERE ID(trending) = $id  SET trending = $data CREATE (trending)-[:source]->(n) RETURN trending`,
    )


    const save = async () => {
        if (file)
            var imageUrl = await uploadSingleFileToStorage('trending/' + v4() + '.png', file);
        const res = await createTrending({ id: parseInt(state.linked), data: { ...state, imageUrl: imageUrl } }).catch(err => console.log(err));
        console.log(res)
        onSave({ ...res.records[0].get('trending').properties, id: res.records[0].get('trending').identity.toNumber() })
        onHide()
    }

    const update = async () => {
        let imageUrl;
        if (file)
            imageUrl = await uploadSingleFileToStorage(data.imageUrl
                .split("english-letters.appspot.com/o/")[1]
                .replaceAll("%2F", "/")
                .split("?")[0], file);
        let trending = {
            name: state.name,
            linked: state.linked,
            column: state.column,
            type: state.type,
            order: state.order,
            imageUrl: state.imageUrl,
        };
        if (imageUrl)
            trending[imageUrl] = imageUrl
        await deleteRelations({ id: state.id })
        await updateTrending({ id: state.id, articleId: parseInt(state.linked), data: trending }).then(res => {
            console.log(res)
            onSave({ ...res.records[0].get('trending').properties, id: res.records[0].get('trending').identity.toNumber() })
            onHide()
        })

    }

    return (
        <div className='container-bg'>
            <div className='main-container'>
                <div className='header-section' style={{ backgroundColor: HexToRGB(color, 0.05), color: color }}>Trending Topic - Tag</div>
                <div className='body-section'>
                    <input
                        type="text"
                        value={state.name}
                        placeholder="Name"
                        onChange={(event) =>
                            setState({ ...state, name: event.currentTarget.value })
                        }
                        style={{
                            color: "#000",
                            marginTop: "20px",
                            marginRight: "10px",
                            fontSize: "15px",
                            padding: "10px 15px",
                            width: "100%",
                        }}
                    />
                    <div style={{ display: 'flex', marginTop: '25px', marginBottom: '25px', gap: '0 25px ' }}>
                        <select value={state.column} onChange={(event) => setState(val => { return { ...val, column: event.target.value, order: event.target.value === '1' ? order[0] : order[1] } })}>
                            <option value="" disabled>Column</option>
                            <option value={1}>Left</option>
                            <option value={2}>Right</option>
                        </select>
                        <div
                            style={{
                                display: "flex",
                                flex: 1,
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <input
                                type="text"
                                value={searchQuery}
                                onClick={() => {
                                    setShow(true)
                                }}

                                placeholder="Select Topic"
                                list="articles"
                                style={{
                                    color: "#000",
                                    fontSize: "15px",
                                    padding: "10px 15px",
                                    width: "100%",
                                }}
                            />
                        </div>
                    </div>
                    <DailyInsightsImageUploader
                        onFileLoad={(file) => { setFile(file) }}
                        value={state.imageUrl}
                    />
                </div>
                <div className='footer-section' style={{ backgroundColor: HexToRGB(color, 0.05) }}>
                    <button className={`footer-button`} style={{ background: "transparent", borderColor: "#95989A", color: "#959595" }} onClick={onHide}>Cancel</button>
                    <button className={`footer-button`} disabled={disabled} style={{ background: disabled ? '#707070' : `${color}`, color: "#fff" }} onClick={() => {
                        setDisabled(true)
                        if (data) update()
                        else
                            save()
                    }}>Save</button>
                </div>
            </div>
            {show && <LinkTagFolderPopup isTrending={true} onCancel={() => { setShow(false) }} onSave={(tags) => {
                setState(val => { return { ...val, linked: tags[tags.length - 1] } });
                setSearchQuery(tags[tags.length - 1])
                setShow(false);

            }} isFromLetter={false} value={[data?.linked]} />}
        </div >
    )
}

export default LinkTopic