import React, { useContext, useEffect, useState } from "react";
import UserCard from "./cards/UserCard";
import { ColorContext } from "../../AppContext";
import { FaPlus } from "react-icons/fa";
import "./Users.css";
import NewUserCard from "./cards/NewUserCard";
import { useLazyReadCypher, useLazyWriteCypher } from "use-neo4j";
import Loader from "../Loader";
import { Item, Menu, useContextMenu } from "react-contexify";

function Users() {
  const [theme] = useContext(ColorContext);
  const [newUserShow, setNewUserShow] = useState(false)
  const [getUsers] = useLazyReadCypher("MATCH (n:EUser) return n ")
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(true)

  const [deleteUser, { cypher, result }] = useLazyWriteCypher(`MATCH (n:EUser {uid: $uid}) detach delete n`)
  const { show } = useContextMenu({
    id: 'user-menu',
  });


  const onDeleted = (id) => {
    setUsers(users.filter(user => user.uid !== id))
  }



  useEffect(() => {
    getUsers().then(val => {
      setUsers(val.records.map(e => e.get("n").properties))
      setLoading(false)
    })
  }, [])

  const onDelete = async ({ uid }) => {
    var requestOptions = {
      method: 'POST',
      redirect: 'follow'
    };
    fetch(`https://us-central1-english-letters.cloudfunctions.net/deleteUser?uid=${uid}`, requestOptions)
      .then(response => {
        if (response.status === 200) {
          response.json().then(async data => {
            await deleteUser(
              {
                uid: uid,
              }
            ).then(() => {
              console.log(cypher, result)
            })
            onDeleted(uid);
          })
        }
        else {
          response.json().then(data => {
            alert("Error: ", data.message)
          })
        }
      })
  }

  const handleItemClick = async ({ event, props, triggerEvent, data }) => {
    if (data === "delete") {
      const confirmation = window.confirm("Are You Sure You want to Delete?");
      if (confirmation) {
        console.log(props.uid)
        await onDelete({
          uid: props.uid,
        });
      }
    }
  };
  return loading ? <Loader /> : (
    <div>
      <div className="grid-container">
        {users.map((value, i) => {
          return <UserCard key={i} user={value} onContextMenu={(e) =>
            show(e, {
              props: {
                ...value,
              },
            })
          } />;
        })}
      </div>
      <div
        className="add_letter"
        style={{ backgroundColor: theme }}
        onClick={(event) => {
          event.preventDefault();
          setNewUserShow(true)
        }}
      >
        <FaPlus className="add_icon" />
      </div>
      <NewUserCard show={newUserShow} onHide={() => setNewUserShow(false)} onClick={() => { }} />
      <Menu id={"user-menu"}>
        <Item onClick={handleItemClick} data={"delete"}>
          Delete
        </Item>
      </Menu>
    </div>
  );
}

export default Users;
