import React, { useContext, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { useLazyWriteCypher } from "use-neo4j";
import { ColorContext } from "../../../AppContext";
import {
  Menu,
  Item,
  useContextMenu,
  Separator,
  Submenu,
} from "react-contexify";
import "./UserCard.css";
import "react-contexify/dist/ReactContexify.css";
import context from "react-bootstrap/esm/AccordionContext";
import DeleteConfirm from "../../dialogs/DeleteConfirm";

function UserCard({ user, onContextMenu }) {
  const [theme] = useContext(ColorContext);
  const history = useHistory();
  const [checked, setChecked] = useState(user.enabled);
  const [disabled, setDisabled] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [userPermission] = useLazyWriteCypher(
    `MATCH (n:EUser {uid: "${user.uid}"}) set n.enabled = $checked `
  );

  return (
    <>
      <div
        onContextMenu={onContextMenu}
        className="user-card"
        onClick={() => {
          if (!disabled) {
            history.push(`/users/${user.uid}`);
          }
        }}
      >
        <img
          src={
            user.imageUrl ?? "https://www.w3schools.com/howto/img_avatar.png"
          }
          style={{
            borderRadius: "50%",
            height: "8.6vh",
            width: "8.6vh",
            float: "left",
          }}
          alt="Avatar"
        ></img>
        <div
          style={{
            display: "inline-flex",
            flexDirection: "column",
            justifyContent: "space-around",
            flex: 1,
            overflow: "hidden",
          }}
        >
          <span className="title">{user.name}</span>
          <h1 className="subtitle">{user.email}</h1>
        </div>
        <label className="switch">
          <input
            type="checkbox"
            onChange={(e) => {
              setChecked(e.currentTarget.checked);
              userPermission({ checked: e.currentTarget.checked });
            }}
            checked={checked}
          />
          <span
            style={{
              backgroundColor: checked ? theme : "#ccc",
            }}
            onMouseEnter={(e) => {
              setDisabled(true);
            }}
            onMouseLeave={(e) => {
              setDisabled(false);
            }}
            onMouseOver={(e) => {
              setDisabled(true);
            }}
            className="slider round"
          ></span>
        </label>
      </div>
    </>
  );
}

export default UserCard;
