import React, { useContext, useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import CustomDropDown from "./CustomDropDown";
import ToggleSwitch from "./ToggleSwitch";
import { useLazyWriteCypher, useLazyReadCypher } from "use-neo4j";
import "./UserDetails.css";
import { useParams } from "react-router-dom";
import Loader from "../Loader";
import { auth } from "../../services/firebaseServices";
import { UserContext } from "../../AppContext";
import EditUserDetails from "./cards/EditUserDetails";

const UserDetails = () => {
  const { userId } = useParams();
  const [getUser] = useLazyReadCypher(
    `MATCH (n:EUser {uid:"${userId}"}) RETURN n`
  );

  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [years, setYears] = useState("");
  const [category, setCategory] = useState("");
  const [editUser, setEditUser] = useState(false);
  const [currentSettings, setCurrentSettings] = useContext(UserContext)

  const [userSettings] = useLazyWriteCypher(
    `MATCH (n:EUser {uid:"${userId}"}) set n.settings = $settings`
  );

  const [settings, setSettings] = useState({
    letterPermission: {
      view: true,
      edit: false,
      delete: false,
      create: false,
      createTag: false,
      from: "All Letters",
      years: [],
      categories: [],
    },
    attachmentsPermission: {
      view: true,
      upload: false,
      delete: false,
      from: "All Attachments",
    },
  });

  const onEditedUser = (name, email, imageUrl) => {
    setUser(val => {
      return { ...val, name: name, email: email, imageUrl: imageUrl };
    });
  }

  useEffect(() => {
    if (user) userSettings({ settings: JSON.stringify(settings) });
  }, [settings]);

  useEffect(() => {
    getUser().then((data) => {
      if (data.records[0].get("n").properties.settings !== "") {
        setSettings(JSON.parse(data.records[0].get("n").properties.settings));
        setCategory(
          JSON.parse(
            data.records[0].get("n").properties.settings
          ).letterPermission.categories.join(", ")
        );
        var years = JSON.parse(data.records[0].get("n").properties.settings)
          .letterPermission.years;
        setYears(parseYears(years));
      }
      setUser(data.records[0].get("n").properties);
      setLoading(false);
    });
  }, []);

  function parseYears(years) {
    let length = 1;
    let list = [];

    if (years.length == 0) {
      return list.join(", ");
    }

    for (let i = 1; i <= years.length; i++) {
      if (i == years.length || years[i] - years[i - 1] != 1) {
        if (length == 1) {
          list.push(years[i - length].toString());
        } else {
          list.push(years[i - length] + " - " + years[i - 1]);
        }
        length = 1;
      } else {
        length++;
      }
    }
    return list.join(", ");
  }

  const onEdit = () => {
    setEditUser(true);
  }

  useEffect(() => {
    if (userId === auth.currentUser.uid) {
      setCurrentSettings(() => { return { ...settings } })
    }
  }, [settings])

  function onPermissionChange(permission, property, value) {
    setSettings((val) => {
      return {
        ...val,
        ...{ [permission]: { ...val[permission], [property]: value } },
      };
    });
  }
  return loading ? (
    <Loader />
  ) : (
    <div style={{ padding: "7vh 5.8vw" }}>
      <div style={{ display: "flex", height: "9.3vh", cursor: "pointer" }} onClick={onEdit}>
        <img
          src={
            user.imageUrl ?? "https://www.w3schools.com/howto/img_avatar.png"
          }
          className="user-image"
          alt="Avatar"
        />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            padding: "1.2vh 0px",
          }}
        >
          <div style={{ fontSize: "2.5vh", fontWeight: "bold" }}>
            {user.name}
          </div>
          <div style={{ fontSize: "2.1vh", color: "rgba(0,0,0,0.5)" }}>
            {user.email}
          </div>
        </div>
      </div>

      <Row style={{ marginTop: "2.5vh" }}>
        <Col xs="6">
          <div className="container">
            <p style={{ marginBottom: "2.5vh" }}>Letters</p>
            <ToggleSwitch
              title="View Details"
              onChange={(val) => {
                onPermissionChange("letterPermission", "view", val);
              }}
              value={settings.letterPermission.view}
            />
            <ToggleSwitch
              title="Edit Text"
              onChange={(val) => {
                onPermissionChange("letterPermission", "edit", val);
              }}
              value={settings.letterPermission.edit}
            />
            <ToggleSwitch
              title="Delete Letters"
              onChange={(val) => {
                onPermissionChange("letterPermission", "delete", val);
              }}
              value={settings.letterPermission.delete}
            />
            <ToggleSwitch
              title="Create New Letters"
              onChange={(val) => {
                onPermissionChange("letterPermission", "create", val);
              }}
              value={settings.letterPermission.create}
            />
            <ToggleSwitch
              title="Create Tags"
              onChange={(val) => {
                onPermissionChange("letterPermission", "createTag", val);
              }}
              value={settings.letterPermission.createTag}
            />
            <CustomDropDown
              items={["All Letters", "From Years", "From Category"]}
              key="letter"
              value={settings.letterPermission.from}
              onClick={(val) => {
                onPermissionChange("letterPermission", "from", val);
              }}
            />
            <input
              placeholder="From Years (seperate with commas)"
              className="dropdown-content-data"
              type="text"
              value={years}
              onChange={(e) => {
                var years = [];
                setYears(e.currentTarget.value);
                e.currentTarget.value.split(",").map((e) => {
                  if (e === "") return;
                  if (e.includes("-")) {
                    var [start, end] = e.split("-").map((e) => parseInt(e));
                    for (var i = start; i <= end; i++) {
                      years.push(i);
                    }
                  } else {
                    years.push(parseInt(e));
                  }
                });
                years.sort((a, b) => a - b);
                years = [...new Set(years)];
                setSettings((val) => {
                  return {
                    ...val,
                    ...{
                      letterPermission: {
                        ...val.letterPermission,
                        years: years,
                      },
                    },
                  };
                });
              }}
            />
            <input
              placeholder="From category"
              className="dropdown-content-data"
              type="text"
              value={category}
              onChange={(e) => {
                var categories = [];
                setCategory(e.currentTarget.value);
                e.currentTarget.value.split(",").map((e) => {
                  if (e === "") return;
                  categories.push(e.trim());
                });
                setSettings((val) => {
                  return {
                    ...val,
                    ...{
                      letterPermission: {
                        ...val.letterPermission,
                        categories: categories,
                      },
                    },
                  };
                });
              }}
            />
          </div>
        </Col>
        <Col xs="6">
          <div className="container">
            <p style={{ marginBottom: "2.5vh" }}>Attachments</p>
            <ToggleSwitch
              title="View Attachments"
              onChange={(val) => {
                onPermissionChange("attachmentsPermission", "view", val);
              }}
              value={settings.attachmentsPermission.view}
            />
            <ToggleSwitch
              title="Upload New Attachments"
              onChange={(val) => {
                onPermissionChange("attachmentsPermission", "upload", val);
              }}
              value={settings.attachmentsPermission.upload}
            />
            <ToggleSwitch
              title="Delete Attachements"
              onChange={(val) => {
                onPermissionChange("attachmentsPermission", "delete", val);
              }}
              value={settings.attachmentsPermission.delete}
            />
            <CustomDropDown
              items={["All Attachments", "Only public ones"]}
              key="attachments"
              value={settings.attachmentsPermission.from}
              onClick={(val) => {
                onPermissionChange("attachmentsPermission", "from", val);
              }}
            />
          </div>
        </Col>
      </Row>
      <EditUserDetails show={editUser} onHide={() => setEditUser(false)} onCompleted={onEditedUser} user={user} />
    </div>
  );
};

export default UserDetails;
