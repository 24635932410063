import React from "react";
import "./DailyInsightsEditor.css";
import { useLazyWriteCypher } from "use-neo4j";
import { v4 } from "uuid";
import { ColorContext } from "../../AppContext";
import { uploadSingleFileToStorage } from "../../services/firebaseServices";
import hexToRGB from "../../utils/HexToRGB";
import _ from "lodash";
import DailyInsightsImageUploader from "./DailyInsightsImageUploader";
// import UploadComponent from '../dragdrop/UploadComponent';
import {
  PopupCancelButton,
  PopupSaveButton,
} from "../../components/tags/popups/PopupButtons";
import { hexToRGBAfterOpactiy } from "../../utils/HexToRGB";
import Compressor from "compressorjs";
import Vibrant from "node-vibrant";

const DailyInsightsEditor = ({ dailyInsight, onHide, onCompleted }) => {
  const [color] = React.useContext(ColorContext);
  const [file, setFile] = React.useState(null);
  const [state, setState] = React.useState(dailyInsight);
  const [oldState, setOldState] = React.useState(state);
  const [saveDailyInsight, { cypher, error }] = useLazyWriteCypher(
    `Optional Match (e:ELetter) where ID(e) = $letterId Create (n:DailyInsights $params), (e)<-[:linked]-(n)  return n`
  );

  const [updateLinkedLetter] = useLazyWriteCypher(
    `Match (n:DailyInsights) where ID(n) = $id
        match (old:ELetter) where ID(old) = $oldLetterId
        match (new:ELetter) where ID(new) = $newLetterId 
       Match (old)-[link:linked]-(n) 
       detach delete link
       create (new)<-[:linked]-(n)
       return n
       `
  );
  const [updateDailyInsight] = useLazyWriteCypher(
    `Optional Match (n:DailyInsights) where ID(n) = $id
        set n.title = $title, n.link = $link, n.color = $color
        return n`
  );

  const onChange = (header, value) => {
    setState((val) => {
      return {
        ...val,
        [header]: value,
      };
    });
  };

  const onUpdate = async () => {
    if (file) {
      const fileName = state.imageUrl
        .split(
          "https://firebasestorage.googleapis.com/v0/b/english-letters.appspot.com/o/daily-insights%2F"
        )[1]
        .split("?alt=media")[0];
      await uploadSingleFileToStorage("daily-insights/" + fileName, file);
      new Compressor(file, {
        height: 300,
        width: 300,
        resize: "cover",
        quality: 1,
        success: async function (result) {
          await uploadSingleFileToStorage(
            "daily-insights/" + fileName + "_thumbnail",
            result
          );
        },
      });
    }
    if (state.link != oldState.link) {
      await updateLinkedLetter({
        id: state.id,
        oldLetterId: oldState.link,
        newLetterId: state.link,
      });
    }
    const color = await Vibrant.from(state.imageUrl).getPalette();
    const hex = color.DarkVibrant.hex;
    await updateDailyInsight({
      id: state.id,
      title: state.title,
      link: state.link,
      color: hex,
    }).then((res) => {
      onCompleted(res.records[0].get("n"), false);
      onHide();
    });
  };

  const save = async () => {
    var uid = v4();
    console.log("test1", uid);
    var imageUrl = await uploadSingleFileToStorage(
      "daily-insights/" + uid,
      file
    );

    new Compressor(file, {
      height: 300,
      width: 300,
      resize: "cover",
      quality: 1,
      success: async function (result) {
        var thumbnail = await uploadSingleFileToStorage(
          "daily-insights/" + uid + "_thumbnail",
          result
        );

        console.log("test2", imageUrl);
        setState((val) => {
          return {
            ...val,
            imageUrl: imageUrl,
            thumbnail: thumbnail,
          };
        });
        const color = await Vibrant.from(imageUrl).getPalette();
        const hex = color.DarkVibrant.hex;
        var params = {
          ...state,
          imageUrl: imageUrl,
          thumbnail: thumbnail,
          color: hex,
        };

        console.log(params);
        await saveDailyInsight({
          params: params,
          letterId: parseInt(state.link),
        })
          .then((res) => {
            console.log(res.records[0].get("n"));
            onCompleted(res.records[0].get("n"), true);
          })
          .catch((err) => {
            console.log(err);
          });
        console.log("test4");
        onHide();
      },
    });
  };

  return (
    <div className="popup-background">
      <div className="popup-container">
        <div
          style={{
            backgroundColor: hexToRGBAfterOpactiy(color, 0.05),
          }}
          className="popup-header"
        >
          <span
            style={{
              fontWeight: "bold",
              fontSize: "13px",
              textAlign: "left",
              color: color,
            }}
          >
            Daily Insights
          </span>
        </div>
        <div
          style={{
            position: "absolute",
            overflow: "auto",
            width: "670px",
            height: "380px",
            padding: "0 30px",
            paddingTop: "20px",
            top: "50px",
          }}
        >
          <input
            disabled={false}
            placeholder="Title"
            className="input-style"
            value={state.title}
            onChange={(e) => onChange("title", e.target.value)}
          />
          <input
            placeholder="Full - Letter ID"
            className="input-style"
            style={{ marginTop: "19px", marginBottom: "29px" }}
            value={state.link}
            onChange={(e) => onChange("link", e.target.value)}
          />
          <DailyInsightsImageUploader
            onFileLoad={(file) => setFile(file)}
            value={dailyInsight.imageUrl}
          />
        </div>
        <div
          style={{
            backgroundColor: hexToRGBAfterOpactiy(color, 0.05),
          }}
          className="popup-footer"
        >
          <PopupCancelButton onClick={onHide} />
          <PopupSaveButton
            onClick={() => {
              if (dailyInsight.id) {
                onUpdate();
              } else {
                save();
              }
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default DailyInsightsEditor;
