import React from "react";
import { IoMdMore } from "react-icons/io";
import { Menu, MenuItem } from "@material-ui/core";
function MoreOptions({ onDelete, onMoveUp, onMoveDown }) {
  const [anchorEl, open] = React.useState(null);
  const handleClick = (event) => {
    open(event.currentTarget);
  };

  const handleClose = (type) => {
    open(null);
    switch (type) {
      case "Delete":
        onDelete();
        break;
      case "Move Up":
        onMoveUp();
        break;
      case "Move Down":
        onMoveDown();
        break;
      default:
        break;
    }
  };
  return (
    <div>
      <IoMdMore
        color="#000"
        size={22}
        className="more-icon"
        onClick={handleClick}
      />
      <Menu
        id="Menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleClose("Delete")}>
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "bold",
              fontSize: "15px",
              textAlign: "center",
              color: "#000",
            }}
          >
            Delete
          </span>
        </MenuItem>
        <MenuItem onClick={() => handleClose("Move Up")}>
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "bold",
              fontSize: "15px",
              textAlign: "center",
              color: "#000",
            }}
          >
            Move Up
          </span>
        </MenuItem>
        <MenuItem onClick={() => handleClose("Move Down")}>
          <span
            style={{
              fontFamily: "Roboto",
              fontWeight: "bold",
              fontSize: "15px",
              textAlign: "center",
              color: "#000",
            }}
          >
            Move Down
          </span>
        </MenuItem>
      </Menu>
    </div>
  );
}

export default MoreOptions;
