import React from "react";

function TagAdd() {
  return (
    <div>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20.171"
        height="19.995"
        viewBox="0 0 20.171 19.995"
      >
        <g
          id="Icon_feather-tag"
          data-name="Icon feather-tag"
          transform="translate(-2 -2)"
        >
          <path
            id="Path_87"
            data-name="Path 87"
            d="M20.454,13.713l-6.732,6.732a1.878,1.878,0,0,1-2.657,0L3,12.389V3h9.389l8.065,8.065A1.878,1.878,0,0,1,20.454,13.713Z"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_89"
            data-name="Path 89"
            d="M18,16.5v6.121"
            transform="translate(-6.882 -8.395)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            id="Path_90"
            data-name="Path 90"
            d="M13.5,21h6.121"
            transform="translate(-5.392 -9.885)"
            fill="none"
            stroke="#000"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
        </g>
      </svg>
    </div>
  );
}

export default TagAdd;
