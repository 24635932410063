import React from "react";
import SimpleEditor from "../../SimpleEditor";
import "./Card.css";
import MoreOptions from "./dropdowns/MoreOptions";

function TitleCard({ onDelete, onMoveUp, onMoveDown, value, onChanged, uid }) {
  return (
    <div
      className="article-card"
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div
          style={{
            fontWeight: "bold",
            fontSize: "20px",
            textAlign: "left",
            padding: "24px",
            lineHeight: 1,
          }}
        >
          Title
        </div>
        <MoreOptions
          onDelete={onDelete}
          onMoveDown={onMoveDown}
          onMoveUp={onMoveUp}
        />
      </div>
      <div
        style={{
          padding: "0 24px 4px 24px",
        }}
      >
        <SimpleEditor
          onFocus={() => {}}
          onBlur={() => {}}
          disabled={false}
          label="Title"
          width="100%"
          height="62px"
          index={1}
          uid={uid}
          text={value}
          onChange={(html) => onChanged(html)}
        />
      </div>
    </div>
  );
}

export default TitleCard;
